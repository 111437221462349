import React from "react";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingSmallCircle } from "../../LoadingCircle";
import { DeviceUserLine } from "./DeviceUserLine";
import { PaginationBar } from "../PaginationBar";
import { removeArrayElement } from "../../utility";
import { QueryLine } from "../../tools/QueryLine";
import { UserCreateOverlay } from "../User/UserCreateOverlay";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { DeviceAssignmentType } from "../../ApiContract";

const terms = defineMessages({
  device_name_label: {
    id: "adminPanel.devices.edit.inputLabel.device-name",
    defaultMessage: "Name",
  },
  show_more: {
    id: "adminPanel.devices.edit.show-more",
    defaultMessage: "Alle anzeigen",
    label: "Alle anzeigen",
  },
  show_less: {
    id: "adminPanel.devices.edit.show-less",
    defaultMessage: "Weniger anzeigen",
    label: "Weniger anzeigen",
  },
});

const filterRole = ["Rolle 01", "Rolle 02", "Rolle 03", "Rolle 04"];
const userListDisplayModeUnassigned = "unassigned";
const userListDisplayModeAssigned = "assigned";

class DeviceAssignUserOverlayClass extends React.Component {
  handleChange;
  submit;

  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      actionUsersShow: true,
      actionUsersEdit: false,
      selectedUserIdList: [],
      query: "",
      userList: [],
      userListAssigned: [],
      userListUnassigned: [],
      userListDisplayed: [],
      userListDisplayMode: userListDisplayModeUnassigned,
      selectedDefaultAssignmentType: DeviceAssignmentType.Read,
      fahrtenbuchUserName: "",
      roleList: [],
      filterKey: "",
      filterType: "user_data",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.updateUserList();

    this.props.getRoleList().then(roleList => {
      this.setState({
        roleList: roleList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  switchSelectedDefaultAssignmentType(selectedDefaultAssignmentType) {
    this.setState({ selectedDefaultAssignmentType });
  }

  updateUserList() {
    this.props
      .getAssignedUsersForDevice(
        this.props.device.imei,
        0,
        -1,
        this.state.filterKey,
        this.state.filterType,
      )
      .then(userList => {
        let userListUnassigned = userList.user_data.filter(
          user =>
            user.user.assignment_type === DeviceAssignmentType.NotAssigned,
        );
        let userListAssigned = userList.user_data.filter(
          user =>
            user.user.assignment_type !== DeviceAssignmentType.NotAssigned,
        );

        let fahrtenbuchUser = userListAssigned.filter(
          user =>
            user.user.assignment_type === DeviceAssignmentType.Fahrtenbuch,
        );

        this.setState({
          loading: false,
          userList: userList.user_data,
          userListAssigned: userListAssigned,
          userListUnassigned: userListUnassigned,
          userListDisplayed:
            this.state.userListDisplayMode === userListDisplayModeUnassigned
              ? userListUnassigned
              : userListAssigned,
          selectedUserIdList: userListAssigned.map(user => {
            return {
              user_id: user.user.id,
              assignment_type: user.user.assignment_type,
            };
          }),
          fahrtenbuchUserEmail:
            fahrtenbuchUser.length > 0 ? fahrtenbuchUser[0].user.email : false,
        });
      });
  }

  setAssignmentTypeForAllSelected = assignmentType => {
    // only update selected user assignments for currently displayed list
    let updatedUserList = this.state.selectedUserIdList.map(selectedUser => {
      // iterate over all selected users and only change them if they are currently displayed
      // Users with type "not_assigned" are not selected and should not be changed
      // This is relevant regarding the unassigning of users.
      // Only allow permitted new assignment types
      let userIsValid = this.state.userListDisplayed.some(
        displayedUser =>
          displayedUser.user.id === selectedUser.user_id &&
          selectedUser.assignment_type !== DeviceAssignmentType.NotAssigned &&
          displayedUser.user.possible_assignments.includes(assignmentType),
      );

      if (userIsValid) {
        return {
          user_id: selectedUser.user_id,
          assignment_type: assignmentType,
        };
      } else {
        return selectedUser;
      }
    });
    this.setState({
      selectedUserIdList: updatedUserList,
    });
  };

  setSearchFilter = query => {
    this.setState({ query });
  };

  submit() {
    if (this.validate()) {
      this.props
        .assignUsersToDevice(
          this.props.device.imei,
          this.state.selectedUserIdList,
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return true;
  }

  selectAllUsers = () => {
    let currentSelection = this.state.selectedUserIdList;

    let newSelection = this.state.userListDisplayed.map(user => {
      currentSelection = currentSelection.filter(
        element => element.user_id !== user.user.id,
      );

      let newAssignmentType = this.state.selectedDefaultAssignmentType;

      // Only change assignment if new type is in permitted types list
      if (!user.user.possible_assignments.includes(newAssignmentType)) {
        return {
          user_id: user.user.id,
          assignment_type: user.user.assignment_type,
        };
      }

      return {
        user_id: user.user.id,
        assignment_type: newAssignmentType,
      };
    });

    this.setState({
      selectedUserIdList: [...currentSelection, ...newSelection],
    });
  };

  updateSelectedUserIdList = updateInfo => {
    let currentSelection = this.state.selectedUserIdList;

    // Only one user can have the assignment Fahrtenbuch.
    if (updateInfo.assignment_type === DeviceAssignmentType.Fahrtenbuch) {
      // If the device does not have an assigned (and saved) Fahrtenbuch user yet
      // only allow one Fahrtenbuch user to be selected.
      currentSelection = currentSelection.map(user => {
        // downgrade pre-existing fahrtenbuch user to the next highest assignment type
        if (user.assignment_type === DeviceAssignmentType.Fahrtenbuch) {
          // fetch allowed assignment types from user list
          let userData = this.state.userList.find(
            dataUser => dataUser.user.id === user.user_id,
          );
          if (userData) {
            if (
              userData.user.possible_assignments.includes(
                DeviceAssignmentType.Manage,
              )
            ) {
              return {
                user_id: user.user_id,
                assignment_type: DeviceAssignmentType.Manage,
              };
            }

            if (
              userData.user.possible_assignments.includes(
                DeviceAssignmentType.Write,
              )
            ) {
              return {
                user_id: user.user_id,
                assignment_type: DeviceAssignmentType.Write,
              };
            }

            if (
              userData.user.possible_assignments.includes(
                DeviceAssignmentType.Read,
              )
            ) {
              return {
                user_id: user.user_id,
                assignment_type: DeviceAssignmentType.Read,
              };
            }

            return {
              user_id: user.user_id,
              assignment_type: DeviceAssignmentType.NotAssigned,
            };
          }
        }
        return user;
      });
    }

    const index = currentSelection.findIndex(
      element => element.user_id === updateInfo.user_id,
    );

    if (index === -1 && updateInfo) {
      // add user to list of selected users
      this.setState({
        selectedUserIdList: [...currentSelection, updateInfo],
      });
    } else {
      // update user info in list of selected users (to change assignment type)
      currentSelection[index] = updateInfo;
      this.setState({ selectedUserIdList: currentSelection });
    }
  };

  switchUserListDisplayMode = newDisplayMode => {
    if (newDisplayMode === userListDisplayModeAssigned) {
      this.setState({
        userListDisplayMode: userListDisplayModeAssigned,
        userListDisplayed: this.state.userListAssigned,
      });
    }

    if (newDisplayMode === userListDisplayModeUnassigned) {
      this.setState({
        userListDisplayMode: userListDisplayModeUnassigned,
        userListDisplayed: this.state.userListUnassigned,
      });
    }
  };

  render() {
    return (
      <div className={"request-component"}>
        <div
          className={"box text-left"}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {this.renderConfirmation()}
        </div>
      </div>
    );
  }

  renderConfirmation = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    return (
      <div className="header device-assignment-overlay">
        <h3 className="flex-just-between align-middle">
          <div>
            <T
              id="adminPanel.devices.user-assignment.headline.overlay"
              defaultMessage="Benutzerzuweisung"
            />{" "}
            ->
            <span className="highlighted">{this.props.device.name}</span>
          </div>
          {this.state.fahrtenbuchUserEmail && (
            <div className="user-name">
              <i className="icon icon-book" />{" "}
              <T
                id="adminPanel.devices.user-assignment.headline.fahrtenbuchUser"
                defaultMessage="Fahrtenbuch-Eigentümer"
              />
              : <strong>{this.state.fahrtenbuchUserEmail}</strong>
            </div>
          )}
          {!this.state.fahrtenbuchUserEmail && (
            <div className="user-name">
              <i className="icon icon-book" />
              <strong>
                <T
                  id="adminPanel.devices.user-assignment.headline.noFahrtenbuchUserAssigned"
                  defaultMessage="Kein Fahrtenbuch-Eigentümer zugewiesen"
                />
              </strong>
            </div>
          )}
        </h3>
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderBody = () => {
    return (
      <div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div>
          <div className="grid-x grid-margin-x align-middle">
            <div className="auto cell">
              <QueryLine
                changeCallback={searchKey =>
                  this.setState(
                    {
                      filterKey: searchKey,
                      filterType: "user_data",
                    },
                    this.updateUserList,
                  )
                }
                changeDelay={500}
              />
              {this.state.showErrorMessage && (
                <div className="error">
                  <T
                    id="adminPanel.devices.user-assignment.errorMessage.name-empty"
                    defaultMessage="Bitte geben Sie einen Namen an."
                  />
                </div>
              )}
            </div>
            <div className="medium-3 cell">
              {/* Role filter */}
              <select
                className="select"
                id="filterRole"
                value={
                  this.state.filterType == "role" ? this.state.filterKey : ""
                }
                onChange={event =>
                  this.setState(
                    { filterKey: event.target.value, filterType: "role" },
                    this.updateUserList,
                  )
                }
              >
                <option value="" key="filterRoleNone">
                  <T
                    id="adminPanel.devices.assignUser.roleFilter.all"
                    defaultMessage="Nutzerrolle"
                  />
                </option>
                {this.state.roleList.map(role => {
                  return (
                    <option value={role.role_name} key={role.role_id}>
                      {role.role_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="admin-list" style={{ marginTop: 0 }}>
          <React.Fragment>
            <div className="content-container">
              <div className="content">{this.renderUserTable()}</div>
            </div>
            {this.renderFooter()}
          </React.Fragment>
        </div>
      </div>
    );
  };

  renderUserTable = () => {
    return (
      <div className="outer-table-frame table-scroll">
        {this.props.inviteMessage && (
          <div className={this.props.inviteMessage.className}>
            {this.props.inviteMessage.text}
            <div className="small-spacer" />
          </div>
        )}
        <div className="table-tabs flex-container">
          <div
            className={`tab-element not-assigned-user ${
              this.state.userListDisplayMode === userListDisplayModeUnassigned
                ? " active"
                : ""
            }`}
            onClick={() =>
              this.switchUserListDisplayMode(userListDisplayModeUnassigned)
            }
          >
            <T
              id="adminPanel.devices.user-assignment.labels.unassignedUsers"
              defaultMessage="Nicht zugewiesene Nutzer"
            />{" "}
            (<span>{this.state.userListUnassigned.length}</span>)
          </div>
          <div
            className={`tab-element assigned-user ${
              this.state.userListDisplayMode === userListDisplayModeAssigned
                ? " active"
                : ""
            }`}
            onClick={() =>
              this.switchUserListDisplayMode(userListDisplayModeAssigned)
            }
          >
            <T
              id="adminPanel.devices.user-assignment.labels.assignedUsers"
              defaultMessage="Zugewiesene Nutzer"
            />{" "}
            (<span>{this.state.userListAssigned.length}</span>)
          </div>
        </div>
        <div className="table-frame">
          <div className="padding-1">
            <div className="action-list">
              <div
                className="button transparent"
                onClick={() => {
                  this.selectAllUsers();
                }}
              >
                <T
                  id="adminPanel.devices.user-assignment.labels.select-all"
                  defaultMessage="Alle Auswählen"
                />
              </div>
              <T
                id="adminPanel.users.list.headline.action"
                defaultMessage="Aktion ->"
              />{" "}
              <div className="checkbox-container">
                <div
                  className="checkbox action"
                  id="checbox_01"
                  checked={
                    this.state.selectedDefaultAssignmentType ===
                    DeviceAssignmentType.Read
                  }
                  onClick={() => {
                    this.switchSelectedDefaultAssignmentType(
                      DeviceAssignmentType.Read,
                    );
                    this.setAssignmentTypeForAllSelected(
                      DeviceAssignmentType.Read,
                    );
                  }}
                >
                  {this.state.selectedDefaultAssignmentType ===
                  DeviceAssignmentType.Read ? (
                    <i className="icon-check" />
                  ) : (
                    <i className="icon-check_empty" />
                  )}
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeRead"
                    defaultMessage="Anzeigen"
                  />
                </div>
              </div>
              <div className="checkbox-container">
                <div
                  className="checkbox action"
                  id="checbox_01"
                  checked={
                    this.state.selectedDefaultAssignmentType ===
                    DeviceAssignmentType.Write
                  }
                  onClick={() => {
                    this.switchSelectedDefaultAssignmentType(
                      DeviceAssignmentType.Write,
                    );
                    this.setAssignmentTypeForAllSelected(
                      DeviceAssignmentType.Write,
                    );
                  }}
                >
                  {this.state.selectedDefaultAssignmentType ===
                  DeviceAssignmentType.Write ? (
                    <i className="icon-check" />
                  ) : (
                    <i className="icon-check_empty" />
                  )}
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeWrite"
                    defaultMessage="Bearbeiten"
                  />
                </div>
              </div>
              {/* @Ben - Checkbox event anpasen - START */}
              <div className="checkbox-container">
                <div
                  className="checkbox action"
                  id="checbox_01"
                  checked={
                    this.state.selectedDefaultAssignmentType ===
                    DeviceAssignmentType.Manage
                  }
                  onClick={() => {
                    this.switchSelectedDefaultAssignmentType(
                      DeviceAssignmentType.Manage,
                    );
                    this.setAssignmentTypeForAllSelected(
                      DeviceAssignmentType.Manage,
                    );
                  }}
                >
                  {this.state.selectedDefaultAssignmentType ===
                  DeviceAssignmentType.Manage ? (
                    <i className="icon-check" />
                  ) : (
                    <i className="icon-check_empty" />
                  )}
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeManage"
                    defaultMessage="Zuordnen"
                  />
                </div>
              </div>
              <div className="counter">
                {
                  this.state.userListDisplayed.filter(displayedUser =>
                    this.state.selectedUserIdList.some(
                      selectedUser =>
                        displayedUser.user.id === selectedUser.user_id &&
                        selectedUser.assignment_type !==
                          DeviceAssignmentType.NotAssigned,
                    ),
                  ).length
                }
              </div>
            </div>
          </div>

          {this.state.userListDisplayed.map(user => {
            let currentlySelectedUser = this.state.selectedUserIdList.find(
              element => element.user_id === user.user.id,
            );

            return (
              <DeviceUserLine
                key={user.user.id}
                user={user.user}
                selected={this.state.selectedUserIdList.some(
                  element =>
                    element.user_id === user.user.id &&
                    element.assignment_type !==
                      DeviceAssignmentType.NotAssigned,
                )}
                currentAssignmentType={
                  currentlySelectedUser
                    ? currentlySelectedUser.assignment_type
                    : DeviceAssignmentType.NotAssigned
                }
                deviceFahrtenbuchUser={this.state.userListAssigned.find(
                  user =>
                    user.user.assignment_type ===
                    DeviceAssignmentType.Fahrtenbuch,
                )}
                updateSelectedUserIdList={this.updateSelectedUserIdList}
                defaultAssignmentType={this.state.selectedDefaultAssignmentType}
              />
            );
          })}
        </div>
        <div className="small-spacer" />
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className="footer text-right">
        <div className="button transparent" onClick={this.props.closeFunction}>
          <span>
            <T
              id="adminPanel.devices.user-assignment.labels.button.close"
              defaultMessage="Schließen"
            />
          </span>
        </div>

        <div
          className={`button green ${this.props.settingsSaving === "true" &&
            "saving"} ${this.props.settingsSaving === "done" && "saved"}`}
          onClick={() => this.submit()}
        >
          {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
          <span>
            {this.props.settingsSaving === "true" && (
              <T
                id="adminPanel.devices.user-assignment.labels.button.saving"
                defaultMessage="Nutzer werden aktualisiert..."
              />
            )}
            {this.props.settingsSaving === "false" && (
              <T
                id="adminPanel.devices.user-assignment.labels.button.save"
                defaultMessage="Nutzer aktualisieren"
              />
            )}
            {this.props.settingsSaving === "done" && (
              <T
                id="adminPanel.devices.user-assignment.labels.button.saved"
                defaultMessage="Nutzer aktualisiert"
              />
            )}
          </span>
        </div>
      </div>
    );
  };
}

export const DeviceAssignUserOverlay = injectIntl(DeviceAssignUserOverlayClass);
