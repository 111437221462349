import React from "react";
import { FormattedMessage as T } from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomDatePickerInput } from "../Map/Map";
import { defaultLocale } from "../Language";
import { Redirect } from "react-router";
import { Portal } from "../Portal";

const ReportType = {
  Usage: "usage",
  DistanceDriven: "distance_driven",
  WiredReports: "wired_reports",
};

const reportAvailabilityByPortal = {
  auto: [ReportType.DistanceDriven],
  vehicle: [
    ReportType.Usage,
    ReportType.DistanceDriven,
    ReportType.WiredReports,
  ],
  asset: [ReportType.Usage, ReportType.DistanceDriven, ReportType.WiredReports],
  person: [
    // ReportType.WiredReports
  ],
};

export class ReportsBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { endDate: moment(), startDate: moment() };
    this.portal = props.match.params.portal;
  }

  lastWeek = [
    moment()
      .subtract(1, "weeks")
      .startOf("isoWeek"),
    moment()
      .subtract(1, "weeks")
      .endOf("isoWeek"),
  ];
  lastMonth = [
    moment()
      .subtract(1, "months")
      .startOf("month"),
    moment()
      .subtract(1, "months")
      .endOf("month"),
  ];
  lastQuarter = [
    moment()
      .subtract(1, "quarters")
      .startOf("quarter"),
    moment()
      .subtract(1, "quarters")
      .endOf("quarter"),
  ];

  /**
   *
   * @param reportType ReportType
   * @returns {Function}
   */
  generateReport = reportType => () => {
    const bodyData = {
      category: this.portal,
      from: this.state.startDate
        .clone()
        //.utc()
        .format("YYYY-MM-DD\\THH:mm:ss"),
      to: this.state.endDate
        .clone()
        //.utc()
        .format("YYYY-MM-DD\\THH:mm:ss"),
    };
    this.props.fetchReports.generateReportCsv(reportType, bodyData);
  };

  handleChangeEnd = endDate => {
    if (endDate.isBefore(this.state.startDate)) {
      this.setState({ endDate, startDate: endDate.clone() });
      return;
    }
    this.setState({ endDate });
  };

  handleChangeStart = startDate => {
    if (startDate.isAfter(this.state.endDate)) {
      this.setState({ startDate, endDate: startDate.clone() });
      return;
    }
    this.setState({ startDate });
  };

  handleTimeSpan = (startDate, endDate) => () =>
    this.setState({ startDate, endDate });

  compareTimeSpans = (startDate1, endDate1, startDate2, endDate2) => {
    return startDate1.isSame(startDate2) && endDate1.isSame(endDate2);
  };

  render() {
    if (this.portal !== Portal.Auto && this.portal !== Portal.Vehicle) {
      return <Redirect to={`/${this.portal}/dashboard`} />;
    }

    return (
      <div className="outer-table-frame dashboard">
        <div className="button-frame" />
        <div className="table-frame">
          <div className="table-header">
            <i className="fa fa-bar-chart" />
            <T id="base.reports" defaultMessage="Reports" />
          </div>
          <div className="reports">
            <div className="element-row">
              <strong>
                <T id="base.timeSpan" defaultMessage="Zeitraum:" />
              </strong>
            </div>
            <div className="element-row">
              <div className="halves">
                <div style={{ width: "3rem" }}>
                  <T id="tracar.fahrtenbuch.from" defaultMessage="von:" />
                </div>
                <DatePicker
                  className="button framed "
                  customInput={<CustomDatePickerInput />}
                  endDate={this.state.endDate}
                  locale={moment().locale() || defaultLocale}
                  onChange={this.handleChangeStart}
                  popperPlacement="top-end"
                  selected={this.state.startDate}
                  selectsStart
                  showMonthDropdown
                  startDate={this.state.startDate}
                  withPortal
                />
              </div>
              <div className="halves">
                <div style={{ width: "3rem" }}>
                  <T id="tracar.fahrtenbuch.to" defaultMessage="bis:" />
                </div>
                <DatePicker
                  className=" button framed "
                  customInput={<CustomDatePickerInput />}
                  endDate={this.state.endDate}
                  locale={moment().locale() || defaultLocale}
                  onChange={this.handleChangeEnd}
                  selected={this.state.endDate}
                  selectsEnd
                  showMonthDropdown
                  startDate={this.state.startDate}
                  withPortal
                />
              </div>
            </div>
            <div className="element-row">
              <div
                className={
                  "button framed thirds " +
                  (this.compareTimeSpans(
                    ...this.lastWeek,
                    this.state.startDate,
                    this.state.endDate,
                  )
                    ? " active "
                    : "")
                }
                onClick={this.handleTimeSpan(...this.lastWeek)}
              >
                <T id="reports.lastWeek" defaultMessage="Letzte Woche" />
              </div>
              <div
                className={
                  "button framed  thirds " +
                  (this.compareTimeSpans(
                    ...this.lastMonth,
                    this.state.startDate,
                    this.state.endDate,
                  )
                    ? " active "
                    : "")
                }
                onClick={this.handleTimeSpan(...this.lastMonth)}
              >
                <T id="reports.lastMonth" defaultMessage="Letzter Monat" />
              </div>
              <div
                className={
                  "button framed thirds " +
                  (this.compareTimeSpans(
                    ...this.lastQuarter,
                    this.state.startDate,
                    this.state.endDate,
                  )
                    ? " active "
                    : "")
                }
                onClick={this.handleTimeSpan(...this.lastQuarter)}
              >
                <T id="reports.lastQuarter" defaultMessage="Letztes Quartal" />
              </div>
            </div>
            <div className="element-row">
              <strong>
                <T
                  id="base.reportType"
                  defaultMessage="Report-Typ (CSV-Download):"
                />
              </strong>
            </div>
            <div className="element-row">
              {reportAvailabilityByPortal[this.portal].map(reportType => (
                <div
                  className="button framed thirds "
                  key={reportType}
                  onClick={this.generateReport(reportType)}
                >
                  <T id={`reports.${reportType}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
