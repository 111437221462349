import React from "react";
import Link from "react-router-dom/es/Link";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../Request/RequestOverlay";

class DashboardServiceIntervalListClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: "",
      showConfirmCompletionOverlay: false,
    };
  }

  toggleShowConfirmCompletionOverlay = interval => {
    this.setState({
      interval,
      showConfirmCompletionOverlay: !this.state.showConfirmCompletionOverlay,
    });
  };

  updateCompletion = () => {
    this.props.setIntervalCompletion(this.state.interval).then(() => {
      this.toggleShowConfirmCompletionOverlay();
    });
  };

  render() {
    return (
      <div className="graph-frame relative">
        <div className="graph-headline">
          <h5 className="headline-for-icon">
            <i className="icon-wrench" />
            <T
              id="tracar.service.intervals.next"
              defaultMessage="Nächste Wartungen"
            />
          </h5>
        </div>
        <div className="tiny-spacer" />
        <div className="dashboard-message-list">
          {this.props.serviceIntervals.map(serviceInterval => {
            return (
              <div
                className="db-service-interval flex-just-between"
                key={serviceInterval.service_interval_id}
              >
                <div>
                  <div className="device">{serviceInterval.vehicle_model}</div>
                  <div className="sub-info flex-container">
                    <div className="sub-info-element">
                      {serviceInterval.service}
                    </div>
                    <div className="sub-info-element">
                      <span>|</span>
                      {serviceInterval.planned_service_date}
                      <span>|</span>
                    </div>
                    <div className="sub-info-element">
                      {serviceInterval.threshold}
                    </div>
                  </div>
                </div>
                <i
                  className={`fa fa-check-circle relative ${
                    serviceInterval.is_done ? "complete" : "uncomplete"
                  }`}
                  onClick={
                    () => {} //this.toggleShowConfirmCompletionOverlay(serviceInterval)
                  }
                />
              </div>
            );
          })}
          <Link
            to={`/${this.props.portal}/serviceIntervals`}
            className="db-show-all-intervals text-center"
          >
            <T
              id="tracar.service.intervals.all"
              defaultMessage="Alle Termine anschauen"
            />
          </Link>
        </div>
        {this.state.showConfirmCompletionOverlay && (
          <RequestOverlay
            closeFunction={this.toggleShowConfirmCompletionOverlay}
            className="import-customer-addresses"
            enableCloseOnOverlayClicked={true}
          >
            {this.renderHeader()}
            {this.renderButtons()}
          </RequestOverlay>
        )}
      </div>
    );
  }

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="tracar.service.intervals.completion.headline"
            defaultMessage="Möchten Sie das Intervall aktualisieren?"
          />
        </h5>
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />
        <div
          className="button"
          onClick={() => this.toggleShowConfirmCompletionOverlay()}
          style={{ marginRight: "15px" }}
        >
          <span>
            <T
              id="tracar.service.intervals.completion.cancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={() => this.updateCompletion()}
        >
          <span>
            <T
              id="tracar.service.intervals.completion.confirm"
              defaultMessage="Bestätigen"
            />
          </span>
        </div>
      </div>
    );
  };
}

export const DashboardServiceIntervalList = injectIntl(
  DashboardServiceIntervalListClass,
);
