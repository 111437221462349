
import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { TextInput } from "../TextInput";
import { validateEmail } from "../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";



const terms = defineMessages({
  email: {
    id: "tracar.users.email",
    defaultMessage: "E-Mail",
  },
});


class InviteOverlayClass extends React.Component {
  handleChange
  submit

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showErrorMessage: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      this.props.inviteUser(this.state.email);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return validateEmail(this.state.email);
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h3>
          <T
            id="tracar.users.inviteHeadline"
            defaultMessage="Neuen Nutzer einladen"
          />
        </h3>
        <div>
          <T
            id="tracar.users.enterEmail"
            defaultMessage="Bitte geben Sie die E-Mail-Adresse des neuen Nutzers ein."
          />
        </div>
        <TextInput
          id="email"
          name={t(terms.email)}
          className={this.state.showErrorMessage ? "error" : ""}
          onChange={this.handleChange}
          onEnter={this.submit}
          value={this.state.email}
        />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="tracar.users.error"
              defaultMessage="Bitte geben Sie eine gültige E-Mailadresse an."
            />
          </div>
        )}
        <div className="small-spacer" />
        <div className="button" onClick={this.submit}>
          <T
            id="tracar.users.inviteButton"
            defaultMessage="Einladung absenden"
          />
        </div>
      </RequestOverlay>
    );
  }
}

export const InviteOverlay = injectIntl(InviteOverlayClass);
