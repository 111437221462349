import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { UserGroupUserLine } from "./UserGroupUserLine";
import { isEmpty, removeArrayElement } from "../../utility";
import { TextInput } from "../../TextInput";
import { QueryLine } from "../../tools/QueryLine";

const terms = defineMessages({
  usergroups_name_label: {
    id: "adminPanel.usergroups.create.inputLabel.role_name",
    defaultMessage: "Name",
  },
  text_search_placeholder: {
    id: "adminPanel.usergroups.create.placeholder.searchField",
    defaultMessage: "Benutzerdaten",
  },
});

/**
 * Opens the dialog to create a new user group in an overlay.
 * For the actual form @see RoleCreateInline
 */
class UserGroupCreateOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      userGroupName: "",
      selectedUsers: [],
      query: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getUserList().then(recordList => {
      this.setState({
        loading: false,
        recordList,
      });
    });
  }

  submit = () => {
    if (this.validate()) {
      this.props
        .createUserGroup(
          this.state.userGroupName,
          this.state.selectedUsers.map(user => user.user.id),
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  };

  validate() {
    return !isEmpty(this.state.userGroupName);
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  getFilteredUsers = users => {
    return this.state.recordList.reduce((acc, userRecord) => {
      // show only functions matching the search query
      const stringFiltering =
        this.state.query.length === 0 ||
        userRecord.user.email.search(this.state.query) > -1 ||
        (userRecord.user.first_name &&
          userRecord.user.first_name.search(this.state.query) > -1) ||
        (userRecord.user.last_name &&
          userRecord.user.last_name.search(this.state.query) > -1) ||
        (userRecord.user.unique_employee_id &&
          userRecord.user.unique_employee_id.search(this.state.query) > -1);

      if (!stringFiltering) {
        return acc;
      }

      return [...acc, userRecord];
    }, []);
  };

  selectRecord = record => {
    if (
      !this.state.selectedUsers.some(
        selectedUser => record.user.id === selectedUser.user.id,
      )
    ) {
      let selectedUsers = [...this.state.selectedUsers, record];
      this.setState({ selectedUsers });
    }
  };

  deselectRecord = record => {
    this.setState({
      selectedUsers: this.state.selectedUsers.filter(
        currentRecord => currentRecord.user.id !== record.user.id,
      ),
    });
  };

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className={"header"}>
          <h3>
            <T
              id="adminPanel.usergroups.create.headline"
              defaultMessage="Neue Benutzergruppe erstellen"
            />
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />

        <TextInput
          id="userGroupName"
          name={t(terms.usergroups_name_label)}
          className={this.state.showErrorMessage ? "error" : ""}
          onChange={this.handleChange}
          value={this.state.userGroupName}
        />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="adminPanel.roles.create.errorMessage.name_empty"
              defaultMessage="Bitte geben Sie einen Namen an."
            />
          </div>
        )}
        <div className="small-spacer" />
        <QueryLine
          changeCallback={this.setSearchFilter}
          placeholder={t(terms.text_search_placeholder)}
        />
        <div className="small-spacer" />

        <div className="admin-list table-scroll outer-table-frame text-left grid-container">
          <div className="table-frame text-center">
            {this.state.recordList &&
              this.getFilteredUsers().map((record, index) => (
                <div
                  className="table-line grid-x grid-padding-x align-middle"
                  key={index}
                >
                  <div className={"cell shrink auto flex-container"}>
                    <i
                      className={
                        "fa checkbox " +
                        (this.state.selectedUsers.some(
                          selectedUser =>
                            record.user.id === selectedUser.user.id,
                        )
                          ? "fa-check-square-o"
                          : "fa-square-o")
                      }
                      onClick={
                        this.state.selectedUsers.some(
                          selectedUser =>
                            record.user.id === selectedUser.user.id,
                        )
                          ? () => this.deselectRecord(record)
                          : () => this.selectRecord(record)
                      }
                    />
                  </div>
                  <div className="cell auto bold text-left">
                    {record.user.email} - {record.user.unique_employee_id}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.usergroups.create.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.usergroups.create.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserGroupCreateOverlay = injectIntl(UserGroupCreateOverlayClass);
