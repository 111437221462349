
import React from "react";
import { Map } from "immutable";
import { FormattedMessage as T } from "react-intl";


/* TODO ponder: own component!*/
/* TODO TRANSLATE*/

export class MessageHeadline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      deleteMarkedEntries,
      markAll,
      markedMessages,
      markSelectedAsRead,
      messageList,
    } = this.props;
    const disabled = markedMessages.size === 0 ? " disabled" : "";
    return (
      <div className="table-header long-headline">
        <div className="">
          <i className="fa fa-envelope" />
          <T
            id="tracar.messages.newsFor"
            defaultMessage="Nachrichten für {device_name}"
            values={{
              device_name: messageList.device_name,
            }}
          />&nbsp;&nbsp;&nbsp;{" "}
          <small className="font-weight-normal">
            <T
              id="tracar.messages.numberOfMessages"
              defaultMessage="{total_amount} Nachrichten"
              values={{ total_amount: messageList.total_amount }}
            />
          </small>
        </div>
        <div className="headline-link-list">
          <div className="link" onClick={markAll}>
            <T id="tracar.messages.markAll" defaultMessage="alle markieren" />
          </div>
          |
          <div className={"link " + disabled} onClick={markSelectedAsRead}>
            <T
              id="tracar.messages.markedAsRead"
              defaultMessage="markierte als gelesen"
            />
          </div>
          |
          <div className={"link " + disabled} onClick={deleteMarkedEntries}>
            <T
              id="tracar.messages.deleteMarked"
              defaultMessage="markierte Löschen"
            />
          </div>
        </div>
      </div>
    );
  }
}
