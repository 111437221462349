import React from "react";
import { AccLogo } from "./ClientSpecificContent/acc-index";
import Link from "react-router-dom/es/Link";
import Route from "react-router-dom/es/Route";
import Redirect from "react-router-dom/es/Redirect";
import { Routes } from "./Routes";
import { FormattedMessage as T } from "react-intl";

import { UserConsumer } from "./Base";
import { DriversLicenseCheckWarningBase } from "./DriversLicenseCheck/DriversLicenseCheckWarningBase";
import { NewUpdatesCheckBase } from "./NewUpdatesCheck/NewUpdatesCheckBase";
import { PortalSelection } from "./PortalSelection";
import { Portal, PortalDefault } from "./Portal";
import { AccClientConfig } from "./ClientSpecificContent/acc-index";
import { AdminPortalSelection } from "./AdminPortalSelection";
import { hasPortalAccess } from "./Permission";
import { adminPortals } from "./Portal";

export class Main extends React.Component {
  render() {
    const portal = this.props.match && this.props.match.params.portal;

    // Check if the given portal exists, if it does not redirect to tracar
    // TODO Keep in mind that later on, users may not have a tracar portal!

    const portalPrefix = "/" + portal;
    const { fetchService } = this.props;

    const findDefaultAdminPortal = user => {
      // find the first admin portal the user has access to
      return adminPortals.find(portal => hasPortalAccess(user, portal));
    };

    return (
      <UserConsumer>
        {user => {
          if (portal === Portal.AdminPanel) {
            return (
              <div className="outer-frame">
                {/* Nav ***********************************************************************************************/}
                <nav className="admin">
                  <div className="admin-flag">ADMINPANEL</div>
                  <div className="navigation">
                    <Link to={`/${PortalDefault}/map`} className="logo">
                      <img src={AccLogo} alt="Logo" />
                    </Link>
                    <h1 className="title hide-for-small-only">
                      {/* Vodafone IoT Tracker */}
                      <T id="base.title" />
                    </h1>
                    <div className="hide-for-small-only">
                      <ul>
                        <li className="link">
                          <Link to={`/${PortalDefault}/map`}>Portal</Link>
                        </li>
                        <li className="link">
                          <Link to={`${portalPrefix}/user`}>
                            <i className="fa fa-user" />
                            {this.props.user.title} {this.props.user.first_name}{" "}
                            {this.props.user.last_name}
                          </Link>
                        </li>
                        {AccClientConfig.showsFaq && (
                          <li className="link">
                            <Link to={`${portalPrefix}/faq`}>
                              <i className="fa fa-question-circle-o" />
                              <T id="base.faq" defaultMessage="FAQ" /> &nbsp;
                            </Link>
                          </li>
                        )}
                        <li className="link">
                          <Link to="" onClick={this.props.logOut}>
                            <i className="fa fa-power-off" />
                            <T id="base.logout" defaultMessage="Logout" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="show-for-small-only">
                      <ul>
                        <li className="link">
                          <Link to={`${portalPrefix}/user`}>
                            <i className="fa fa-user" />
                          </Link>
                        </li>
                        <li className="link">
                          <Link to={`${portalPrefix}/faq`}>
                            <i className="fa fa-question-circle-o" />
                          </Link>
                        </li>
                        <li className="link">
                          <a href="" onClick={this.props.logOut}>
                            <i className="fa fa-power-off" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <AdminPortalSelection {...this.props} />
                {/* Displayed Type ************************************************************************************/}
                <div className="main admin">
                  {/* TODO differentiate the props */}
                  <Routes {...this.props} />
                  {/*<div className="dev-badge">Development Version</div>*/}
                </div>
                {this.props.user.showUpdatePopup && this.props.loggedIn && (
                  <NewUpdatesCheckBase
                    userLoginData={this.props.user}
                    setLoggedIn={this.props.setLoggedIn}
                  />
                )}
              </div>
            );
          }

          if (
            this.props.match == null ||
            !user.availablePortals.find(
              availablePortal => availablePortal === portal,
            )
          ) {
            portal != null &&
              console.warn(`Unknown portal: ${portal} redirecting to default.`);
            const dashboardOrMap =
              portal === Portal.Person ||
              portal === PortalDefault ||
              portal === null
                ? "map"
                : "dashboard";
            return (
              /// if the portal is not known redirect to the first portal available
              <Redirect
                to={{
                  pathname: `/${user.availablePortals[0]}/${dashboardOrMap}`,
                }}
              />
            );
          }

          const defaultAdminPortal = findDefaultAdminPortal(user);

          return (
            <div className="outer-frame">
              <Route
                exact
                path="/:portal"
                render={() => <Redirect to={`/${portal}/map`} />}
              />
              {/* Nav ***********************************************************************************************/}
              <nav>
                <div className="navigation">
                  {/* TODO test the case where user have only 1 available portal */}
                  <Link to={`/${PortalDefault}/map`} className="logo">
                    <img src={AccLogo} alt="Logo" />
                  </Link>
                  <h1 className="title hide-for-small-only">
                    {/* Vodafone IoT Tracker */}
                    <T id="base.title" />
                  </h1>
                  <div className="hide-for-small-only">
                    <ul>
                      {false && (
                        <li>
                          <a
                            to={"/tracar/fahrtenbuch/"}
                            className="button disabled"
                          >
                            <i className="fa fa-book" />
                            Mein Fahrtenbuch
                            {5 > 0 && <span className="indicator">{5}</span>}
                          </a>
                        </li>
                      )}
                      {/*Link to the admin panel*/}
                      {defaultAdminPortal !== undefined && (
                        <li className="link">
                          <Link to={`/admin-panel/${defaultAdminPortal}/list`}>
                            Admin
                          </Link>
                        </li>
                      )}
                      <li className="link">
                        <Link to={`${portalPrefix}/user`}>
                          <i className="fa fa-user" />
                          {this.props.user.title} {this.props.user.first_name}{" "}
                          {this.props.user.last_name}
                        </Link>
                      </li>
                      {AccClientConfig.showsFaq && (
                        <li className="link">
                          <Link to={`${portalPrefix}/faq`}>
                            <i className="fa fa-question-circle-o" />
                            <T id="base.faq" defaultMessage="FAQ" /> &nbsp;
                          </Link>
                        </li>
                      )}
                      <li className="link">
                        <Link to="" onClick={this.props.logOut}>
                          <i className="fa fa-power-off" />
                          <T id="base.logout" defaultMessage="Logout" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="show-for-small-only">
                    <ul>
                      <li>
                        {false && (
                          <a
                            to={"/tracar/fahrtenbuch/"}
                            className="disabled relative"
                          >
                            <i className="fa fa-book" />
                            {5 > 0 && (
                              <div className="badge-position">
                                <span className="badge">{5}</span>
                              </div>
                            )}
                          </a>
                        )}
                      </li>
                      <li className="link">
                        <Link to={`${portalPrefix}/user`}>
                          <i className="fa fa-user" />
                        </Link>
                      </li>
                      <li className="link">
                        <Link to={`${portalPrefix}/faq`}>
                          <i className="fa fa-question-circle-o" />
                        </Link>
                      </li>
                      <li className="link">
                        <a href="" onClick={this.props.logOut}>
                          <i className="fa fa-power-off" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <PortalSelection {...this.props} />
              {/* Displayed Type ************************************************************************************/}
              <div className="main">
                {/* TODO differentiate the props */}
                <Routes {...this.props} />
              </div>
              {/* TODO: re-enable with correct permission
              {this.props.user.normalizedFunctionalities.basic
                .drivers_license_check && (
                <DriversLicenseCheckWarningBase
                  userLoginData={this.props.user}
                  confirmWarning={fetchService.confirmWarning}
                />
              )}*/}
              {this.props.user.showUpdatePopup && this.props.loggedIn && (
                <NewUpdatesCheckBase
                  userLoginData={this.props.user}
                  setLoggedIn={this.props.setLoggedIn}
                />
              )}
            </div>
          );
        }}
      </UserConsumer>
    );
  }
}
