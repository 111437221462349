import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { Portal } from "../Portal";
import { Link } from "react-router-dom";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  deviceName: {
    id: "tracar.device.deviceName",
    defaultMessage: "Geräte-Name",
  },
  comment: {
    id: "tracar.device.comment",
    defaultMessage: "Anmerkung",
  },
  maxSpeed: {
    id: "tracar.device.maxSpeed",
    defaultMessage: "Geschwindigkeitsgrenze (km / h)",
  },
  email: {
    id: "tracar.device.email",
    defaultMessage: "E-Mail",
  },
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
  trackingInterval1: {
    id: "tracar.device.trackingInterval.one",
    defaultMessage: "1 mal pro Tag",
  },
  trackingInterval2: {
    id: "tracar.device.trackingInterval.two",
    defaultMessage: "3 mal pro Tag",
  },
  trackingInterval3: {
    id: "tracar.device.trackingInterval.three",
    defaultMessage: "1 mal pro Stunde",
  },
});

class TrackingIntervalTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.trackingIntervalSettings,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.trackingIntervalSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null)
        settings[setting[0]] = this.state[setting[0]];
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: {
        interval_setting_type: this.state.interval_setting_type,
      },
    });

    this.props.saveDeviceSettings(settingsContainer);
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  updateTrackingInterval = interval => {
    this.props.setSettingsSaving("false");
    this.setState({ interval_setting_type: interval });
  };

  render() {
    const t = this.props.intl.formatMessage;
    const trackingIntervalArray = [1, 2, 3];

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <div>
              {!this.props.readOnly && (
                <React.Fragment>
                  <div className="switch-car-description">
                    <T
                      id="tracar.device.trackingInterval.tip"
                      defaultMessage="Die Einstellungen werden an das Gerät gesendet sobald es online ist."
                    />
                  </div>
                  <div className="small-spacer" />
                  <div className="button-container">
                    <div className="block">
                      {trackingIntervalArray.map(intervalIndex => {
                        return (
                          <div
                            key={intervalIndex}
                            className={`button sorting ${this.state
                              .interval_setting_type === intervalIndex &&
                              "active"}`}
                            // style={
                            //   this.props.trackingInterval
                            //     .actual_interval_in_hardware === intervalIndex
                            //     ? { border: "solid 3px rgb(230, 0, 0)" }
                            //     : {}
                            // }
                            onClick={() =>
                              this.updateTrackingInterval(intervalIndex)
                            }
                          >
                            {t(terms["trackingInterval" + intervalIndex])}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              )}
              {this.props.trackingIntervalSettings
                .actual_interval_in_hardware && (
                <div>
                  <T
                    id="tracar.device.trackingInterval.current"
                    defaultMessage="Aktuelle Einstellung im Gerät: "
                  />{" "}
                  {t(
                    terms[
                      "trackingInterval" +
                        this.props.trackingIntervalSettings
                          .actual_interval_in_hardware
                    ],
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderHeadline = () => {
    if (this.props.portal === Portal.Person) {
      return (
        <T id="tracar.device.headlinePerson" defaultMessage="iNanny Daten" />
      );
    }

    return <T id="tracar.device.headline" defaultMessage="Trackerdaten" />;
  };

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.tracking-interval.saving"
                    defaultMessage="Abfragefrequenz wird aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.tracking-interval.save"
                    defaultMessage="Abfragefrequenz aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.tracking-interval.saved"
                    defaultMessage="Abfragefrequenz aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const TrackingIntervalTab = injectIntl(TrackingIntervalTabClass);
