import React from "react";
import FileUploader from "../../FileUploader";
import { LoadingCircle } from "../../LoadingCircle";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

// Translations
const terms = defineMessages({
  linkExampleFile: {
    id: "adminPanel.users.upload.linkExampleFile",
    defaultMessage: "/userCsvExample/csv_beispiel_DE.csv",
  },
  errorsUploadFailed: {
    id: "adminPanel.users.upload.uploadFailed",
    defaultMessage:
      "Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.",
  },
});

class UserUploadOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      uploading: false,
      selectedFile: null,
    };
  }

  /**
   * Save the selected file
   * @return void
   */
  handleSelectedFile = file => {
    this.setState({
      uploading: false,
      selectedFile: file,
    });
  };

  /**
   * Uploads the file
   */
  handleUpload = () => {
    this.setState(
      {
        uploading: true,
      },
      () => {
        this.props
          .uploadUsers(this.state.selectedFile)
          .then(() => {
            this.props.closeFunction(true);
          })
          .catch(() => {
            this.setState({
              uploading: false,
              error: "uploadFailed",
            });
          });
      },
    );
  };

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="import-customer-addresses"
        enableCloseOnOverlayClicked={true}
      >
        {this.state.uploading
          ? this.renderLoadingCircle()
          : this.renderImportInterface()}

        {this.renderError()}
      </RequestOverlay>
    );
  }

  /**
   * Renders the loading circle
   * @return LoadingCircle
   */
  renderLoadingCircle = () => {
    return <LoadingCircle />;
  };

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderImportInterface = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderFileupload()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="adminPanel.users.upload.headline"
            defaultMessage="Benutzer importieren"
          />
        </h5>
        <div className="description">
          <T
            id="adminPanel.users.upload.description"
            defaultMessage="Bitte laden Sie die Benutzerdaten im CSV-Format hoch. Die Datei muss folgende Header haben: E-Mail, Vorname, Nachname, Angestellten ID, Rolle. Das Trennzeichen ist ein Komma."
          />
          &nbsp;
          <a href={t(terms.linkExampleFile)} target="_blank">
            <strong>
              <T
                id="customer.addresses.downloadExampleFileLabel"
                defaultMessage="Beispieldatei herunterladen"
              />
            </strong>
          </a>
        </div>
      </div>
    );
  };

  /**
   * Render the file upload
   * @return JSX
   */
  renderFileupload = () => {
    const buttonClasses = ["button", "save-button"];

    if (!this.state.selectedFile) {
      buttonClasses.push("disabled");
    }

    return (
      <div className="upload-container">
        <div className="spacer" />
        <FileUploader
          handleSelectedFile={this.handleSelectedFile}
          validExtensions={[".csv"]}
        />
        <div className="spacer" />
        <div
          className={buttonClasses.join(" ")}
          onClick={this.state.selectedFile ? this.handleUpload : null}
        >
          <span>
            <T
              id="adminPanel.users.upload.uploadLabel"
              defaultMessage="Daten importieren"
            />
          </span>
        </div>
      </div>
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return null;
    }

    return (
      <div className="error">
        <T
          id="customer.addresses.errors.uploadFailed"
          defaultMessage="Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut."
        />
      </div>
    );
  };
}

export const UserUploadOverlay = injectIntl(UserUploadOverlayClass);
