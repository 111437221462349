import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { DeviceList } from "./DeviceList";
import { noTagsPlaceholder } from "../../TrackerList/TrackerListBase";
import { removeArrayElement } from "../../utility";
import { DeviceCreateOverlay } from "./DeviceCreateOverlay";
import { DeviceAssignUserOverlay } from "./DeviceAssignUserOverlay";
import { QueryLine } from "../../tools/QueryLine";
import { TagBar } from "../../Tags/TagBar";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";
import { DeviceFilterType } from "../../ApiContract";
import { RoleList } from "../Role/RoleList";
import { RoleAssignUserOverlay } from "../Role/RoleAssignUserOverlay";

const deviceListLimit = 10;

class AdminPanelDeviceBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recordList: [],
      selectedTags: [],
      showImportOverlay: false,
      showDeleteConfirmationOverlay: false,
      tagListLoadingState: "loading",
      showCreateOverlay: false,
      query: "",
      tagList: { tag_id_list: [], tag_objects: {} },
      userList: [],
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      deviceListOffset: 0,
      // search term to filter role list
      deviceListSearchKey: "",
      // filter devices by assignment type
      deviceListFilterType: DeviceFilterType.All,
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load(paginationInfo = {}) {
    // TODO: fetch paginated records @see CustomerAddresses
    this.props
      .getDeviceListFiltered(
        this.state.deviceListSearchKey,
        this.state.deviceListFilterType,
        this.state.deviceListOffset,
        deviceListLimit,
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.devices,
          numberOfDevices: recordListData.total_amount,
          numberOfPages: Math.ceil(
            recordListData.total_amount / deviceListLimit,
          ),
        }),
      );

    this.props.getUserList().then(userList =>
      this.setState({
        loading: false,
        userList: userList.user_data,
      }),
    );

    this.loadTags();
  }

  loadTags = () => {
    this.setState({ tagListLoadingState: "loading" });
    this.props.fetchTags.getTagList().then(tagList => {
      this.setState({
        tagList,
        tagListLoadingState: "ready",
        selectedTags: [
          noTagsPlaceholder,
          ...Object.entries(tagList.tag_objects).map(tag => {
            return { id: parseInt(tag[0]), name: tag[1].tag_name };
          }),
        ],
        selectedAllTags: true,
        initialTagLoad: false,
      });
    });
  };

  toggleSelectAllTags = () => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: this.state.lastSelectedTags,
        selectedAllTags: false,
        isFiltered: false,
        currentPage: 0,
      });
      return;
    }

    this.setState({
      selectedTags: [
        noTagsPlaceholder,
        ...Object.entries(this.state.tagList.tag_objects).map(tag => {
          return { id: parseInt(tag[0]), name: tag[1].tag_name };
        }),
      ],
      lastSelectedTags: this.state.selectedTags,
      selectedAllTags: true,
      initialTagLoad: false,
      currentPage: 0,
    });
  };

  toggleSelectedTags = tagObject => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: [tagObject],
        selectedAllTags: false,
        currentPage: 0,
      });
      return;
    }

    const index = this.state.selectedTags.findIndex(
      tag => tag.id === tagObject.id,
    );
    if (index === -1 && tagObject) {
      this.setState({
        selectedTags: [...this.state.selectedTags, tagObject],
        currentPage: 1,
      });
    } else {
      this.setState({
        selectedTags: removeArrayElement(this.state.selectedTags, index),
        currentPage: 1,
      });
    }
  };

  setTagsAsSaving = (asSaving = true) => {
    asSaving
      ? this.setState({ tagListLoadingState: "saving" })
      : this.setState({ tagListLoadingState: "ready" });
  };

  setSearchFilter = deviceListSearchKey => {
    this.setState({ deviceListSearchKey }, this.load);
  };

  updateCurrentPage = number => {
    this.setState({ currentPage: number });
  };

  getDeviceTypeList = async () => {
    return ["car", "utility", "asset", "person"];
  };

  getFilteredDevices = devices => {
    const selectedTags = this.state.selectedTags.map(tag => {
      return tag.id;
    });

    if (this.state.tagListLoadingState === "ready") {
      return devices.reduce((acc, device) => {
        const tagFiltering =
          // show non tagged devices
          (device.tags.length === 0 &&
            selectedTags.find(id => id === noTagsPlaceholder.id)) ||
          // show tagged devices
          selectedTags.find(shownId => {
            return device.tags.find(id => {
              return shownId === id;
            });
          });

        if (!tagFiltering) {
          return acc;
        }

        /*const stringFiltering =
          this.state.query.length === 0 ||
          device.name.toLowerCase().search(this.state.query.toLowerCase()) >
            -1 ||
          device.imei.search(this.state.query) > -1 ||
          device.description
            .toLowerCase()
            .search(this.state.query.toLowerCase()) > -1;

        if (!stringFiltering) {
          return acc;
        }*/

        /*const onlineFiltering =
          this.state.onlineFilter === "" ||
          device.is_online.toString() === this.state.onlineFilter;

        if (!onlineFiltering) {
          //return acc;
        }*/

        return [...acc, device];
      }, []);
    } else {
      return [];
    }
  };

  setDeviceFilter = event => {
    this.setState({ deviceListFilterType: event.target.value }, this.load);
  };

  render() {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelDevice) && (
            <div className="outer-table-frame">
              <div className="margin-bottom-1">
                {this.state.tagList && this.renderTagBar()}
              </div>
              <div className="grid-x grid-margin-x align-middle search-area">
                <div className="auto cell">
                  <QueryLine
                    changeCallback={this.setSearchFilter}
                    changeDelay={500}
                  />
                </div>

                <div className="medium-3 cell">
                  <select className="margin-bottom-1" onChange={this.setDeviceFilter}>
                    <option value={DeviceFilterType.All}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.all"
                        defaultMessage="Alle"
                      />
                    </option>
                    <option value={DeviceFilterType.Unassigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.unassigned"
                        defaultMessage="Nicht zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.Assigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assigned"
                        defaultMessage="Zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.AssignedFahrtenbuch}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assignedFahrtenbuch"
                        defaultMessage="Fahrtenbuch zugewiesen"
                      />
                    </option>
                  </select>
                </div>
              </div>
              {this.renderDeviceList()}
              {this.state.showDeviceAssignUserOverlay &&
                this.renderDeviceAssignUserOverlay(user)}
              {this.state.showCreateOverlay && this.renderDeviceCreateOverlay()}
            </div>
          )
        }
      </UserConsumer>
    );
  }

  /**
   * Returns the tag bar
   * @return JSX
   */
  renderTagBar = () => {
    return (
      this.state.tagList && (
        <TagBar
          fetchTags={this.props.fetchTags}
          loadTags={this.loadTags}
          setTagsAsSaving={this.setTagsAsSaving}
          selectedTags={this.state.selectedTags}
          selectedAllTags={this.state.selectedAllTags}
          tagList={this.state.tagList}
          toggleSelectAll={this.toggleSelectAllTags}
          toggleSelectedTags={this.toggleSelectedTags}
          tagListLoadingState={this.state.tagListLoadingState}
          setTagsAsSaving={this.setTagsAsSaving}
        />
      )
    );
  };

  /**
   * Returns the device-list-table
   * @return JSX
   */
  renderDeviceList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    return (
      <div>
        <DeviceList
          fetchTags={this.props.fetchTags}
          loadTags={this.loadTags}
          tagListLoadingState={this.state.tagListLoadingState}
          recordList={this.getFilteredDevices(this.state.recordList)}
          tagList={this.state.tagList}
          selectedTags={this.state.selectedTags}
          selectedAllTags={this.state.selectedAllTags}
          toggleSelectAllTags={this.toggleSelectAllTags}
          toggleSelectedTags={this.toggleSelectedTags}
          setTagsAsSaving={this.setTagsAsSaving}
          inviteMessage={this.state.inviteMessage}
          toggleDeviceEditOverlay={this.toggleDeviceEditOverlay}
          toggleDeviceAssignUserOverlay={this.toggleDeviceAssignUserOverlay}
          numberOfDevices={this.state.numberOfDevices}
          updateListOffset={this.updateListOffset}
          updateListSearchKey={this.updateListSearchKey}
          updatePagination={this.updatePagination}
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
        />
      </div>
    );
  };

  /**
   * Returns the user assingment overlay
   * @return JSX
   */
  renderDeviceAssignUserOverlay = user => {
    return (
      <DeviceAssignUserOverlay
        loggedInUser={user}
        device={this.state.selectedDevice}
        userList={this.state.userList}
        loadTags={this.loadTags}
        closeFunction={this.toggleDeviceAssignUserOverlay}
        settingsSaving={"false"}
        getRoleList={this.props.getRoleList}
        getUserListFiltered={this.props.getUserListFiltered}
        assignUsersToDevice={this.props.assignUsersToDevice}
        getAssignedUsersForDevice={this.props.getAssignedUsersForDevice}
      />
    );
  };

  /**
   * Returns th device creation overlay
   * @return JSX
   */
  renderDeviceCreateOverlay = () => {
    return (
      <DeviceCreateOverlay
        closeFunction={this.toggleCreateDeviceOverlay}
        getDeviceTypeList={this.getDeviceTypeList}
        tagList={this.state.tagList}
      />
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  toggleCreateDeviceOverlay = () => {
    this.setState({ showCreateOverlay: !this.state.showCreateOverlay });
  };

  toggleDeviceEditOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle edit overlay");
  };

  toggleDeviceAssignUserOverlay = device => {
    this.setState({
      showDeviceAssignUserOverlay: !this.state.showDeviceAssignUserOverlay,
      selectedDevice: device,
    });
  };

  toggleUserCreateOverlay = () => {
    //alert(`showUserCreateOverlay: ${!this.state.showDeviceUserCreateOverlay}`);
    this.setState({
      showDeviceUserCreateOverlay: !this.state.showDeviceUserCreateOverlay,
    });
  };

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ deviceListOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ deviceListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * deviceListLimit);
  };
}

export const AdminPanelDeviceBase = injectIntl(AdminPanelDeviceBaseClass);
