import React from "react";
import { UserGroupLine } from "./UserGroupLine";
import { defineMessages, FormattedMessage as T } from "react-intl";
import { PaginationBar } from "../PaginationBar";
import { UserConsumer } from "../../Base";
import { QueryLine } from "../../tools/QueryLine";
import { RoleLine } from "../Role/RoleLine";
import ReactTooltip from "react-tooltip";

export class UserGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredRecordList: this.props.recordList,
      fleetIdFilter: "all",
      fleetIdFunctionality: false,
    };
  }

  render() {
    return (
      <UserConsumer>
        {user => (
          <div className="outer-table-frame admin-list">
            <div className="grid-x grid-margin-x align-middle search-area">
              <div className="auto cell">
                <QueryLine
                  changeCallback={this.props.updateListSearchKey}
                  changeDelay={500}
                />
              </div>
              <div className="shrink cell">
                <div className="button-frame">
                  <div
                    className="button green primary"
                    onClick={this.props.toggleCreateOverlay}
                  >
                    <T
                      id="adminPanel.usergroups.list.labels.createbutton"
                      defaultMessage="Neue Gruppe anlegen"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="table-frame">
              <div className="table-header">
                <i className="fa icon-user-role" />
                <T
                  id="adminPanel.usergroups.list.headline"
                  defaultMessage="Nutzergruppen"
                />
                <span className="item-count">
                  {this.props.numberOfUserGroups}
                </span>
              </div>
              {this.props.recordList.map(currentUserGroup => (
                <UserGroupLine
                  key={currentUserGroup.group_id}
                  currentUserGroup={currentUserGroup}
                  buttonToggles={() => {
                    return {
                      toggleEditOverlay: this.props.toggleEditOverlay,
                      toggleDeleteOverlay: this.props.toggleDeleteOverlay,
                      toggleUserGroupAssignUserOverlay: this.props
                        .toggleUserGroupAssignUserOverlay,
                    };
                  }}
                />
              ))}
            </div>
            <PaginationBar
              currentPage={this.props.currentPage}
              numberOfPages={this.props.numberOfPages}
              updateCurrentPage={this.props.updatePagination}
            />
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`non-company-user-line`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }

  render_old() {
    return (
      <div className="admin-list">
        <div className="table-frame">
          <div className="table-header">
            <i className="fa fa-users" />
            <T
              id="adminPanel.usergroups.list.headline"
              defaultMessage="Nutzergruppen"
            />
            <span className="item-count">{this.props.numberOfUserGroups}</span>
          </div>
          {this.props.recordList.map(currentUserGroup => (
            <UserGroupLine
              key={currentUserGroup.group_id}
              currentUserGroup={currentUserGroup}
              buttonToggles={() => {
                return {
                  toggleEditOverlay: this.props.toggleEditOverlay,
                  toggleDeleteOverlay: this.props.toggleDeleteOverlay,
                  toggleUserGroupAssignUserOverlay: this.props
                    .toggleUserGroupAssignUserOverlay,
                };
              }}
            />
          ))}
        </div>
        <PaginationBar
          currentPage={this.props.currentPage}
          numberOfPages={this.props.numberOfPages}
          updateCurrentPage={this.props.updatePagination}
        />
      </div>
    );
  }
}
