import React from "react";
import { exchangeArrayElement, removeArrayElement } from "../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";
import { Link } from "react-router-dom";
import { Portal } from "../Portal";
import { isDev } from "../tools/Environment";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  maxSpeed: {
    id: "tracar.device.maxSpeed",
    defaultMessage: "Geschwindigkeitsgrenze (km / h)",
  },
  email: {
    id: "tracar.device.email",
    defaultMessage: "E-Mail",
  },
  emailNotification: {
    id: "tracar.device.notification.email",
    defaultMessage: "E-Mail-Benachrichtigung:",
  },
  notification: {
    id: "tracar.device.notification.set",
    defaultMessage: "Benachrichtigungen:",
  },
  value: {
    id: "tracar.device.notification.value",
    defaultMessage: "Wert",
  },
  overspeeding_alert: {
    id: "tracar.device.notification-type.overspeeding-alert",
    defaultMessage: "Geschwindigkeitsgrenze (km/h)",
  },
  fahrtenbuch_alert: {
    id: "tracar.device.notification-type.fahrtenbuch-alert",
    defaultMessage: "Fahrtenbuch",
  },
  geofence_alert: {
    id: "tracar.device.notification-type.geofence-alert",
    defaultMessage: "Sicherheitszone",
  },
  device_removal_alert: {
    id: "tracar.device.notification-type.device-removal-alert",
    defaultMessage: "Gerät wurde entfernt",
  },
  low_battery_alert: {
    id: "tracar.device.notification-type.low-battery-alert",
    defaultMessage: "Niedrige Batterieladung",
  },
  tow_alarm: {
    id: "tracar.device.notification-type.tow-alarm-alert",
    defaultMessage: "TOW",
  },
  wire_1_alert: {
    id: "tracar.device.notification-type.wire-1-alert",
    defaultMessage: "Kabel N.1",
  },
  wire_2_alert: {
    id: "tracar.device.notification-type.wire-2-alert",
    defaultMessage: "Kabel N.2",
  },
  wire_3_alert: {
    id: "tracar.device.notification-type.wire-3-alert",
    defaultMessage: "Kabel N.3",
  },
  wire_4_alert: {
    id: "tracar.device.notification-type.wire-4-alert",
    defaultMessage: "Kabel N.4",
  },
  temperature_alert: {
    id: "tracar.device.notification-type.temperature-alert",
    defaultMessage: "Temperatur (°C)",
  },
  pressure_alert: {
    id: "tracar.device.notification-type.pressure-alert",
    defaultMessage: "Druck (bar)",
  },
  humidity_alert: {
    id: "tracar.device.notification-type.humidity-alert",
    defaultMessage: "Feuchtigkeit (%)",
  },
  fuel_sensor_alert: {
    id: "tracar.device.notification-type.fuel-sensor-alert",
    defaultMessage: "Benzin (%)",
  },
  main_power_removal_alert: {
    id: "tracar.device.notification-type.main_power-removal-alert",
    defaultMessage: "Kein Strom",
  },
  emails_to_notify_title: {
    id: "tracar.device.notifications-tab.emails-to-notify-title",
    defaultMessage: "E-Mail-Adressen welche benachrichtigt werden",
  },
  settings_title: {
    id: "tracar.device.notifications-tab.settings-title",
    defaultMessage: "Einstellungen",
  },
  comparison_title: {
    id: "tracar.device.notifications-tab.comparison-title",
    defaultMessage: "Vergleich",
  },
});

class NotificationsTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingMailDelete: null,
      ...this.props.notificationSettings,
      ...this.props.notificationSettings.notification_type,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.notificationSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null)
        settings[setting[0]] = this.state[setting[0]];
    });

    settings.notification_type &&
      Object.entries(settings.notification_type).forEach(setting => {
        if (this.state[setting[0]] != null)
          settings.notification_type[setting[0]] = this.state[setting[0]];
      });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    if (this.state.resetSettings) {
      this.props.resetDeviceSettings(["notification_settings"]).then(res => {
        if (res) {
          this.setState({
            emails_to_notify: [],
            notification_type: res.notification_settings.notification_type,
            ...res.notification_settings.notification_type,
            resetSettings: false,
          });
        } else {
          console.log(
            "error: could not reset notification settings... reloading device settings...",
          );
          this.props.loadDeviceSettings();
        }
      });
    } else {
      this.rememberSettings();

      let settingsToUpdate = {};

      Object.assign(settingsToUpdate, {
        emails_to_notify: this.state.emails_to_notify,
      });

      if (this.state.notification_type) {
        Object.assign(settingsToUpdate, {
          notification_type: {},
        });
        Object.entries(this.state.notification_type).forEach(
          notification_type => {
            if (this.state[notification_type[0]]) {
              Object.assign(settingsToUpdate.notification_type, {
                [notification_type[0]]: this.state[notification_type[0]],
              });
            }
          },
        );
      }

      let settingsContainer = {};
      Object.assign(settingsContainer, {
        [this.props.tabType]: settingsToUpdate,
      });

      this.props.saveDeviceSettings(settingsContainer);
    }
  };

  addMailAddress = () => {
    this.props.setSettingsSaving("false");
    this.setState({ emails_to_notify: [...this.state.emails_to_notify, ""] });
  };

  cancelDelete = () => this.setState({ pendingMailDelete: null });

  deleteMailAddress = index => {
    this.props.setSettingsSaving("false");
    if (index === this.state.pendingMailDelete) {
      const emails_to_notify = removeArrayElement(
        this.state.emails_to_notify,
        index,
      );
      this.setState({ emails_to_notify, pendingMailDelete: null });
    } else {
      this.setState({ pendingMailDelete: index });
    }
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed") {
      if (value !== "") {
        value = parseInt(value, 10);
        value > 400 && (value = 400);
        value < 0 && (value = 0);
      }
      value === "" || value === 0
        ? this.setState({ set_speed_limit: false })
        : this.setState({ set_speed_limit: true });
    } else {
      let setting = this.state[name];
      setting["value"] = value;
      value = setting;
    }
    this.setState({ [name]: value });
  };

  handleComparisonChange = (id, type, setting, value) => {
    this.props.setSettingsSaving("false");

    switch (value) {
      case 0: {
        value = 1;
        break;
      }
      case 1: {
        value = 2;
        break;
      }
      case 2: {
        value = 1;
        break;
      }
      default: {
        value = 0;
        break;
      }
    }

    let notificationType = this.state[type];
    notificationType[setting] = value;

    this.setState({});
  };

  handleMailChange = event => {
    this.props.setSettingsSaving("false");
    const index = parseInt(event.target.id, 10);
    const emails_to_notify = exchangeArrayElement(
      this.state.emails_to_notify,
      event.target.value,
      index,
    );
    this.setState({ emails_to_notify });
  };

  updateSettingsToUpdate = name => {
    this.state.settingsToUpdate[name] = !this.state.settingsToUpdate[name];
    this.setState({});
  };

  isKnownType = type => {
    return terms[type] ? true : false;
  };

  updateCheckBox = (id, type, setting) => {
    this.props.setSettingsSaving("false");
    const checkBox = document.getElementById(id);
    let notificationType = this.state[type];
    notificationType[setting] = !checkBox.checked;
    // disabled send_email option when the whole type is disabled
    if (setting === "is_set" && checkBox.checked) {
      notificationType["send_email"] = false;
    }
    let state = {};
    state[type] = notificationType;
    this.setState(state);
  };

  toggleResetSettings = () => {
    this.setState({ resetSettings: !this.state.resetSettings });
    this.props.setSettingsSaving("false");
  };

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            {!this.props.readOnly && (
              <div className="settings-bulk-buttons-container">
                <div
                  className="button settings-bulk-button delete-button"
                  onClick={() => this.toggleResetSettings()}
                >
                  {this.state.resetSettings ? (
                    <T
                      id="tracar.settings-notifications.undo"
                      defaultMessage="Rückgängig machen"
                    />
                  ) : (
                    <T
                      id="tracar.settings-notifications.reset"
                      defaultMessage="Benachrichtigung-Einstellungen zurücksetzen"
                    />
                  )}
                  <i className="icon-uniF2D3" />
                </div>
              </div>
            )}
            {!this.state.resetSettings ? (
              <div className="grid-x grid-margin-x">
                <div className="medium-6 large-6 cell">
                  <div style={{ fontWeight: "bold" }}>
                    {t(terms.emails_to_notify_title)}
                  </div>

                  {this.state.emails_to_notify.map((email, index) => (
                    <div className="cell relative" key={index}>
                      <TextInput
                        className=""
                        id={index.toString()}
                        name={t(terms.email)}
                        onChange={this.handleMailChange}
                        type="text"
                        value={email}
                        disabled={this.props.readOnly}
                      />
                      {!this.props.readOnly && (
                        <div className="delete-input-button">
                          {this.state.pendingMailDelete === index ? (
                            <div>
                              <div
                                className="button"
                                onClick={this.cancelDelete}
                              >
                                <T
                                  id="tracar.device.cancel"
                                  defaultMessage="Abbrechen"
                                />
                              </div>
                              <div
                                className="button"
                                onClick={() => this.deleteMailAddress(index)}
                              >
                                <T
                                  id="tracar.device.delete"
                                  defaultMessage="Adresse löschen"
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="button"
                              onClick={() => this.deleteMailAddress(index)}
                            >
                              -
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {!this.props.readOnly && (
                    <div className="cell">
                      <div className="text-input-component">
                        <div className="delete-input-button">
                          <div className="button" onClick={this.addMailAddress}>
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="medium-6 large-6 cell notification-settings-container">
                  <div style={{ fontWeight: "bold" }}>
                    {t(terms.settings_title)}
                  </div>
                  {this.state.notification_type &&
                    Object.entries(this.state.notification_type)
                      .sort()
                      .map((type, index) => {
                        return (
                          <div key={index} className="notification-settings">
                            <div className="notification-setting-title">
                              {this.isKnownType(type[0])
                                ? t(terms[type[0]])
                                : "unknown type (" + type[0] + ")"}
                            </div>

                            <div className="notification-setting">
                              {t(terms.notification)}
                              <div
                                id={`${type[0]}_is_set`}
                                className="checkbox"
                                checked={this.state[type[0]].is_set}
                                onClick={() =>
                                  !this.state[type[0]].read_only &&
                                  this.updateCheckBox(
                                    `${type[0]}_is_set`,
                                    type[0],
                                    "is_set",
                                  )
                                }
                              >
                                <div
                                  className={`icon-container ${this.state[
                                    type[0]
                                  ].read_only && "disabled"}`}
                                >
                                  {type[1].is_set ? (
                                    <i className="icon-check icon" />
                                  ) : (
                                    <i className="icon-check_empty icon" />
                                  )}
                                </div>
                              </div>
                            </div>

                            {this.state[type[0]].is_set && (
                              <React.Fragment>
                                <div className="notification-setting">
                                  {t(terms.emailNotification)}
                                  <div
                                    id={`${type[0]}_send_email`}
                                    className="checkbox"
                                    checked={this.state[type[0]].send_email}
                                    onClick={() =>
                                      !this.state[type[0]].read_only &&
                                      this.updateCheckBox(
                                        `${type[0]}_send_email`,
                                        type[0],
                                        "send_email",
                                      )
                                    }
                                  >
                                    <div
                                      className={`icon-container ${this.state[
                                        type[0]
                                      ].read_only && "disabled"}`}
                                    >
                                      {type[1].send_email ? (
                                        <i className="icon-check icon" />
                                      ) : (
                                        <i className="icon-check_empty icon" />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {this.state[type[0]].comparision_type && (
                                  <div className="notification-setting">
                                    {t(terms.comparison_title)}
                                    <div
                                      id={`${type[0]}_comparision_type`}
                                      className="checkbox"
                                      value={type[1].comparision_type}
                                      onClick={() =>
                                        !this.state[type[0]].read_only &&
                                        this.handleComparisonChange(
                                          `${type[0]}_comparision_type`,
                                          type[0],
                                          "comparision_type",
                                          type[1].comparision_type,
                                        )
                                      }
                                    >
                                      <div
                                        className={`icon-container ${this.state[
                                          type[0]
                                        ].read_only && "disabled"}`}
                                      >
                                        {type[1].comparision_type === 0 ? (
                                          <i className="icon-ban_circle icon" />
                                        ) : type[1].comparision_type === 1 ? (
                                          <i className="icon-double_angle_left icon" />
                                        ) : type[1].comparision_type === 2 ? (
                                          <i className="icon-double_angle_right icon" />
                                        ) : (
                                          <i className="icon-check_empty icon" />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {this.state[type[0]].value != null && (
                                  <div className="notification-setting">
                                    <TextInput
                                      key={`value_${index}`}
                                      className={`value-input`}
                                      settingType="value_input"
                                      id={[type[0]]}
                                      name={`${t(terms.value)}${
                                        type[0] === "low_battery_alert"
                                          ? " (%)"
                                          : ""
                                      }`}
                                      value={
                                        this.state[type[0]].value === "None"
                                          ? ""
                                          : this.state[type[0]].value
                                      }
                                      onFocusValue={""}
                                      onBlurValue={
                                        this.state[type[0]].value === "None"
                                          ? ""
                                          : this.state[type[0]].value
                                      }
                                      onChange={this.handleChange}
                                      disabled={this.state[type[0]].read_only}
                                    />
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            ) : (
              <div className="table-line">
                <T
                  id="tracar.settings-notifications.reset-notification"
                  defaultMessage="Sie haben die Einstellungen zurückgesetzt. Bitte bestätigen Sie die Änderungen mittels 'Benachrichtigungen aktualisieren'"
                />
              </div>
            )}
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.notifications.saving"
                    defaultMessage="Benachrichtigungen werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.notifications.save"
                    defaultMessage="Benachrichtigungen aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.notifications.saved"
                    defaultMessage="Benachrichtigungen aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const NotificationsTab = injectIntl(NotificationsTabClass);
