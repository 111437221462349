export class ServiceIntervalNew {
  constructor(
    service_type_choices,
    threshold_type_choices,
    imei,
    threshold,
    service,
    planned_service_date,
    user,
    is_done,
  ) {
    this.service_type_choices = service_type_choices;
    this.threshold_type_choices = threshold_type_choices;
    this.imei = imei;
    this.threshold = threshold;
    this.service = service;
    this.planned_service_date = planned_service_date;
    this.user = user;
    this.is_done = is_done;
  }
}

export class ServiceIntervalEdit {
  constructor(
    service_interval_id,
    service_type_choices,
    threshold_type_choices,
    imei,
    threshold,
    service,
    planned_service_date,
    user,
    is_done,
  ) {
    this.service_interval_id = service_interval_id;
    this.service_type_choices = service_type_choices;
    this.threshold_type_choices = threshold_type_choices;
    this.imei = imei;
    this.threshold = threshold;
    this.service = service;
    this.planned_service_date = planned_service_date;
    this.user = user;
    this.is_done = is_done;
  }
}

export class MultipleServiceInterval {
  constructor(
    service_type_choices,
    threshold_type_choices,
    imei,
    interval_threshold,
    service,
    interval_date,
    user,
    is_done,
  ) {
    this.service_type_choices = service_type_choices;
    this.threshold_type_choices = threshold_type_choices;
    this.imei = imei;
    this.interval_threshold = interval_threshold;
    this.service = service;
    this.interval_date = interval_date;
    this.user = user;
    this.is_done = is_done;
  }
}

export const Data = {
  Service: "service_type_choices",
  Description: "service",
  Vehicle: "vehicle_model",
  PlanedDate: "planned_service_date",
  Threshold: "threshold",
  AvarageUsageDate: "expected_service_date",
  Done: "is_done",
};

export const StatusTypes = {
  Ok: "ok",
  Overdue: "überfällig",
  Notified: "überfällig und benachrichtigt",
  Due: "fällig",
  Done: "erledigt",
};
