
import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";

import moment from "moment";

export function DriversLicenseCheckWarningOverlay({
  closeFunction,
  disableWarnings,
  userLoginData,
}) {
  return (
    <RequestOverlay
      closeFunction={closeFunction}
      className="drivers-license-warning"
    >
      <h3>
        <i className="fa fa-exclamation-triangle error" />&nbsp;&nbsp;&nbsp;
        fleet iD-Warnung
      </h3>

      <div className="overlay-content">
        <div>
          Folgende Nutzer haben ihren Führerschein nicht rechtzeitig prüfen
          lassen:
        </div>
        <div className="small-spacer" />
        {userLoginData.dlc_warnings &&
          userLoginData.dlc_warnings.map(({ dlc, user }) => (
            <div key={user.email} className="fleet-id-warning-line">
              {user.email} <br />
              {user.first_name} {user.last_name}
              <br />
              fleet iD: {dlc.dlc_barcode}
              <br />
              Prüfung war fällig am: {moment(dlc.first).format("DD.MM.YYYY")}
            </div>
          ))}
      </div>
      <div className="spacer" />
      <div className="button save-button" onClick={disableWarnings}>
        Warnung für diese Nutzer nicht mehr anzeigen.
      </div>
    </RequestOverlay>
  );
}
