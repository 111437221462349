import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { RoleCreateOverlay } from "../Role/RoleCreateOverlay";
import { RoleCreateInline } from "../Role/RoleCreateInline";
import { UserAssignRoleInline } from "./UserAssignRoleInline";
import { AdminPanelRoleBase } from "../Role/RoleBase";

class UserAssignRoleOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showCreateOverlay: false,
      showInnerCreateOverlay: false,
      roleRecordList: [],
      requestOverlayClassName: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    // this.props
    //   .editUser(this.props.editRoleRecord.id, this.state.editRoleName, this.state.selectedFunctions)
    //   .then(this.props.closeFunction);
    this.props.closeFunction();
  }

  toggleInnerCreateOverlay = new_role_id => {
    this.setState(
      {
        showInnerCreateOverlay: !this.state.showInnerCreateOverlay,
        requestOverlayClassName:
          this.state.requestOverlayClassName === ""
            ? "overlay-secondary-color"
            : "",
      },
      () => {
        this.props.getRoleListAll().then(roleRecordList => {
          this.setState({
            loading: false,
            roleRecordList,
          });
        });
      },
    );
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.users.assignRole.headline"
              defaultMessage="Rolle zuweisen"
            />{" "}
            ->{" "}
            <span className="highlighted">
              {this.props.assignRoleUserRecord.user.email}
            </span>
          </h3>
        </div>
        <div className="small-spacer" />

        <UserAssignRoleInline
          closeFunction={this.props.closeFunction}
          assignRoleUserRecords={[this.props.assignRoleUserRecord]}
          assignUsersToRole={this.props.assignUsersToRole}
          getRoleListAll={this.props.getRoleListAll}
          createRole={this.props.createRole}
          getPermissionList={this.props.getPermissionList}
          toggleInnerCreateOverlay={this.toggleInnerCreateOverlay}
          showInnerCreateOverlay={this.state.showInnerCreateOverlay}
        />
      </RequestOverlay>
    );
  }
}

export const UserAssignRoleOverlay = injectIntl(UserAssignRoleOverlayClass);
