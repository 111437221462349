import React from "react";
import ReactDOM from "react-dom";

export class DropdownMenu extends React.Component {
  anchor;
  items;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  isNodeInRoot(node, root) {
    while (node) {
      if (node === root) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }

  handleClick = () => {
    this.setDropdownState(!this.state.open);
  };

  handleDocumentClick = e => {
    // Adds possibility for multilevel menu. Only closes menu if the click is not inside the menu
    if (this.isNodeInRoot(e.target, ReactDOM.findDOMNode(this))) {
      return;
    }
    this.setDropdownState(false);
  };

  setDropdownState(newState) {
    this.setState({
      open: newState,
    });
    if (newState) {
      document.addEventListener("click", this.handleDocumentClick);
    } else {
      document.removeEventListener("click", this.handleDocumentClick);
    }
  }

  setItems() {
    if (this.props.anchor) {
      this.anchor = this.props.anchor;
    } else {
      this.anchor = <i className="fa fa-cog" />;
    }
    this.items = React.Children.toArray(this.props.children).filter(child => {
      return (
        child.type.name === "DropdownItems" ||
        child.type === <DropdownItems />.type
      );
    });
    if (!this.items.length) {
      this.items = false;
    } else {
      this.items = this.items[0];
    }
  }

  render() {
    this.setItems();
    if (!this.items) {
      console.warn("Keine Items für das Dropdown angegeben");
      return null;
    }

    return (
      <div className="has-dropdown hover">
        <div className="button" onClick={this.handleClick}>
          {this.anchor}
        </div>
        {this.state.open ? this.items : null}
        {/*{this.items}*/}
      </div>
    );
  }
}

export class DropdownItems extends React.Component {
  render() {
    return <ul className="dropdown-items">{this.props.children}</ul>;
  }
}
