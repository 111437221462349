import React from "react";
import { FormattedMessage as T } from "react-intl";
import moment from "moment";
import numeral from "numeral";
import { hasPermission, Permission } from "../Permission";
import { UserConsumer } from "../Base";

export function DeviceInfo({
  battery,
  device_info: { device_geofences, device_type, name: device_name },
  latest_gps_info: { lat, lng, address, gps_datetime: time_last_seen },
}) {
  /// For a newly inserted car battery was explicitely `null`
  /// thus the {} as default
  const { voltage, status: battery_status } = battery || {};

  let batteryStatusMessage;
  switch (battery_status) {
    case "good":
      batteryStatusMessage = (
        <T id="tracar.battery.ideal" defaultMessage="ideal" />
      );
      break;
    case "ok":
      batteryStatusMessage = (
        <T id="tracar.battery.acceptable" defaultMessage="akzeptabel" />
      );
      break;
    case "bad":
    default:
      batteryStatusMessage = (
        <T id="tracar.battery.bad" defaultMessage="schlecht" />
      );
      break;
  }

  return (
    <UserConsumer>
      {user => (
        <div className="graph-frame">
          <div className="graph-headline">
            <h5 className="headline-for-icon">
              <i className="fa fa-info" />
              {device_name}
            </h5>
          </div>
          <div className="tiny-spacer" />
          <div className="dashboard-message-list">
            <div className="dashboard-message info">
              Gerätetyp: {device_type}
            </div>
            <div className="dashboard-message info">
              Batterie: {voltage} V ({batteryStatusMessage})
            </div>
            {hasPermission(user, Permission.Location) && (
              <React.Fragment>
                <div className="dashboard-message info">
                  letztes Signal:{" "}
                  {time_last_seen != null
                    ? moment(time_last_seen).format("HH:mm:ss [Uhr] DD.MM.YYYY")
                    : ""}
                </div>
                <div className="dashboard-message info">{address}</div>
                <div className="dashboard-message info">
                  lat/lng: {numeral(parseFloat(lat)).format("0,0.00000")} /{" "}
                  {numeral(parseFloat(lng)).format("0,0.00000")}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </UserConsumer>
  );
}
