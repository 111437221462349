import React from "react";

import { DriversLicenseCheckWarningOverlay } from "./DriversLicenseCheckWarningOverlay";


export class DriversLicenseCheckWarningBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningClosed: !(
        this.props.userLoginData.dlc_warnings &&
        this.props.userLoginData.dlc_warnings.length > 0
      ),
    };
  }

  disableWarnings = () => {
    const warning =
      this.props.userLoginData.dlc_warnings &&
      this.props.userLoginData.dlc_warnings.map(
        ({ dlc }) => dlc.dlc_barcode || ""
      );
    warning && this.props.confirmWarning(warning);
    this.toggleOverlay();
  };

  toggleOverlay = () => {
    this.setState({ warningClosed: !this.state.warningClosed });
  };

  render() {
    return (
      !this.state.warningClosed && (
        <DriversLicenseCheckWarningOverlay
          disableWarnings={this.disableWarnings}
          closeFunction={this.toggleOverlay}
          userLoginData={this.props.userLoginData}
        />
      )
    );
  }
}
