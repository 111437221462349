import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { DeviceAssignmentType } from "../../ApiContract";
import { UserConsumer } from "../../Base";

export class DeviceUserLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //needed for multiple assigning
  updateCheckBox = imei => {
    const checkBox = document.getElementById("checkBox_" + imei);
    this.props.updateSelectedDevicesImeiList(imei, !checkBox.checked);
  };

  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };

  render() {
    return (
      <div className="table-line grid-x grid-padding-x">
        <div className="cell small-12 large-auto first-cell">
          <div className="grid-x">
            <div className="shrink cell">
              {!this.props.user.possible_assignments.includes(
                DeviceAssignmentType.NotAssigned,
              ) ? (
                <span className="checkbox">
                  <i className="fa fa-lock" />
                </span>
              ) : (
                <span
                  className="checkbox"
                  id={"checkBox_" + this.props.user.id}
                  checked={this.props.selected}
                  onClick={() =>
                    this.props.updateSelectedUserIdList({
                      user_id: this.props.user.id,
                      assignment_type: this.props.selected
                        ? DeviceAssignmentType.NotAssigned
                        : this.props.defaultAssignmentType,
                    })
                  }
                >
                  {this.props.selected ? (
                    <i className="icon-check" />
                  ) : (
                    <i className="icon-check_empty" />
                  )}
                </span>
              )}
            </div>
            <div className="shrink cell">
              <span className="name">{this.props.user.email}</span>{" "}
              <span className="grey-text">
                {" "}
                {this.props.user.first_name} {this.props.user.last_name}
              </span>
            </div>
          </div>
          <div className="grey-text">
            <span className="grey-text flex-container">
              <div className="user-status border-right">Active User</div>
              <div className="registration-date border-right">
                <T
                  id="adminPanel.devices.user-assignment.line.labels.registrationDate"
                  defaultMessage="Registriert am"
                />
                {": "}
                {this.props.user.registered_on
                  ? this.props.user.registered_on
                  : "-"}{" "}
              </div>
              {/*| Employee ID: {this.props.user.id}{" "}*/}
              <div className="chips flex-container">
                <div
                  className={`chip show ${
                    this.props.currentAssignmentType ===
                    DeviceAssignmentType.Read
                      ? "green"
                      : !this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Read,
                        )
                      ? "inactive"
                      : ""
                  }`}
                  onClick={() => {
                    this.props.user.possible_assignments.includes(
                      DeviceAssignmentType.Read,
                    ) &&
                      this.props.updateSelectedUserIdList({
                        user_id: this.props.user.id,
                        assignment_type: DeviceAssignmentType.Read,
                      });
                  }}
                >
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeRead"
                    defaultMessage="Anzeigen"
                  />
                </div>
                <div
                  className={`chip edit ${
                    this.props.currentAssignmentType ===
                    DeviceAssignmentType.Write
                      ? "green"
                      : !this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Write,
                        )
                      ? "inactive"
                      : ""
                  }`}
                  onClick={() => {
                    this.props.user.possible_assignments.includes(
                      DeviceAssignmentType.Write,
                    ) &&
                      this.props.updateSelectedUserIdList({
                        user_id: this.props.user.id,
                        assignment_type: DeviceAssignmentType.Write,
                      });
                  }}
                >
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeWrite"
                    defaultMessage="Bearbeiten"
                  />
                </div>
                <div
                  className={`chip assign ${
                    this.props.currentAssignmentType ===
                    DeviceAssignmentType.Manage
                      ? "green"
                      : !this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Manage,
                        )
                      ? "inactive"
                      : ""
                  }`}
                  onClick={() => {
                    this.props.user.possible_assignments.includes(
                      DeviceAssignmentType.Manage,
                    ) &&
                      this.props.updateSelectedUserIdList({
                        user_id: this.props.user.id,
                        assignment_type: DeviceAssignmentType.Manage,
                      });
                  }}
                >
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeManage"
                    defaultMessage="Zuordnen"
                  />
                </div>
                <div
                  className={`chip fahrtenbuch ${
                    this.props.currentAssignmentType ===
                    DeviceAssignmentType.Fahrtenbuch
                      ? "green"
                      : !this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Fahrtenbuch,
                        )
                      ? "inactive"
                      : ""
                  }`}
                  onClick={() => {
                    this.props.user.possible_assignments.includes(
                      DeviceAssignmentType.Fahrtenbuch,
                    ) &&
                      this.props.updateSelectedUserIdList({
                        user_id: this.props.user.id,
                        assignment_type: DeviceAssignmentType.Fahrtenbuch,
                      });
                  }}
                >
                  <T
                    id="adminPanel.devices.user-assignment.labels.assignmentTypeFahrtenbuch"
                    defaultMessage="Fahrtenbuch"
                  />
                </div>
              </div>
            </span>
          </div>
        </div>
        <div className="cell small-12 medium-12 large-2">
          <strong>{this.props.user.role.role_name}</strong>
          <br />
          <span className="grey-text line-pad">
            <T
              id="adminPanel.devices.user-assignment.labels.userRole"
              defaultMessage="Nutzerrolle"
            />
          </span>
        </div>
        {/*
        <div className="cell small-12 medium-12 large-auto">
          <strong>Entwickler</strong>
          <br />
          <span className="grey-text line-pad">Nutzergruppe</span>
        </div>
        */}
        <div className="cell small-12 medium-1 large-1 table-line-right fixed">
          <div className="button-container" />
        </div>
      </div>
    );
  }
}
