import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../tools/QueryLine";

class RfidChipAssignDevicesClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: true,
      hasApiError: false,
      selectedDevices: [],
      devicesWithStatus: this.getDevicesWithStatus(this.props.rfidableList),
      searchFilter: "",
    };
  }

  handleChange = (device, status) => {
    if (status) {
      if (
        this.props.rfidChipList.length <=
        device.assigned_chip_count +
          device.available_slots -
          device.assigned_chips.length
      ) {
        this.props.rfidChipList.forEach(chip => {
          this.updateDeviceAssignedChipsArray(
            device.assigned_chips,
            chip,
            status,
          );
          this.props.updateRfidChipsToAssign(
            this.updateChipAssignedToDeviceArray(
              chip.assigned_to_devices,
              device,
              status,
            ),
          );
        });
      }
    } else {
      this.props.rfidChipList.forEach(chip => {
        this.updateDeviceAssignedChipsArray(
          device.assigned_chips,
          chip,
          status,
        );
        this.props.updateRfidChipsToAssign(
          this.updateChipAssignedToDeviceArray(
            chip.assigned_to_devices,
            device,
            status,
          ),
        );
      });
    }
    this.setState({
      devicesWithStatus: this.getDevicesWithStatus(this.props.rfidableList),
    });
  };

  //uploadChipsAndDevicesAssignStatus
  save = async () => {
    this.setState({ waiting: true });

    const devicesToAssign = this.state.devicesWithStatus.flatMap(device =>
      device.status === "assigned" ? device.device.device_id : [],
    );

    const devicesToDeassign = this.state.devicesWithStatus.flatMap(device =>
      device.status === "deassigned" ? device.device.device_id : [],
    );

    const chipsToUpdate = this.props.rfidChipList.map(
      chip => chip.rfid_chip_id,
    );

    this.props.fetchRfid
      .assignChipsToDevices(
        chipsToUpdate,
        chipsToUpdate,
        devicesToAssign,
        devicesToDeassign,
      )
      .then(res => {
        this.props.closeFunction();
      })
      .catch(err => {
        console.log("error:", err);
        this.setState({ saving: false, hasApiError: true });
      });
  };

  getDevicesWithStatus = devices => {
    return devices.map(device => {
      let deviceWithStatus = { device: device, status: "" };
      this.props.rfidChipList.forEach(chip =>
        chip.assigned_to_devices.length > 0 &&
        chip.assigned_to_devices.find(adevice => device.imei === adevice.imei)
          ? deviceWithStatus.status === ""
            ? (deviceWithStatus.status = "assigned")
            : deviceWithStatus.status === "deassigned" &&
              (deviceWithStatus.status = "partial")
          : deviceWithStatus.status === ""
          ? (deviceWithStatus.status = "deassigned")
          : deviceWithStatus.status === "assigned" &&
            (deviceWithStatus.status = "partial"),
      );
      return deviceWithStatus;
    });
  };

  updateChipAssignedToDeviceArray = (array, deviceToUpdate, status) => {
    if (status) {
      if (!array.find(device => deviceToUpdate.device_id === device.device_id))
        array.push(deviceToUpdate);
    } else {
      for (var i = 0; i < array.length; i++) {
        array[i].device_id === deviceToUpdate.device_id && array.splice(i, 1);
      }
    }
    return array;
  };

  updateDeviceAssignedChipsArray = (array, chipToUpdate, status) => {
    if (status) {
      if (!array.find(chip => chipToUpdate.chip_code === chip.chip_code))
        array.push(chipToUpdate);
    } else {
      for (var i = 0; i < array.length; i++) {
        array[i].chip_code === chipToUpdate.chip_code && array.splice(i, 1);
      }
    }
    return array;
  };

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="create-new-chip"
      >
        <div>
          <h5>
            <T
              id="tracar.rfid-chip-assign-devices.headline"
              defaultMessage="Geräte-Zuordnung für Chip: "
            />
            {this.props.rfidChipList.map(chip => {
              return (
                <div key={chip.chip_code}>
                  {chip.chip_name} (Code: {chip.chip_code}),
                </div>
              );
            })}
          </h5>
          <p>
            <T
              id="tracar.rfid-chip-assign-devices.assign-alert"
              defaultMessage="Die Zuweisung kann erst aktualisiert werden, sobald das betroffene Gerät online ist."
            />
          </p>
          <p>
            <T
              id="tracar.rfid-chip-assign-devices.description"
              defaultMessage="Wählen Sie alle Geräte aus, die sie zuordnen möchten:"
            />
          </p>

          <QueryLine changeCallback={this.setSearchFilter} />

          <form id="assign-devices">
            {this.state.devicesWithStatus.reduce((acc, device) => {
              const stringFiltering =
                this.state.searchFilter.length === 0 ||
                device.device.device_name
                  .toLowerCase()
                  .search(this.state.searchFilter.toLowerCase()) > -1 ||
                device.device.imei
                  .toLowerCase()
                  .search(this.state.searchFilter.toLowerCase()) > -1;

              if (!stringFiltering) {
                return acc;
              }

              const assignedChips = device.device.assigned_chips.length;
              const chipsLimit =
                device.device.assigned_chip_count +
                device.device.available_slots;

              return [
                ...acc,
                <div className="device" key={device.device.device_id}>
                  <label
                    htmlFor={device.device.device_id}
                    className={`button ${
                      device.status === "assigned" ? " active" : ""
                    }`}
                    style={
                      device.status === "partial"
                        ? { border: "solid 3px rgb(230, 0, 0)" }
                        : {}
                    }
                    onClick={() =>
                      this.handleChange(
                        device.device,
                        device.status === "deassigned" ? true : false,
                      )
                    }
                  >
                    {device.device.device_name}
                    <br />
                    (IMEI: {device.device.imei})
                    <br />
                    <T
                      id="tracar.device-list.assigned"
                      defaultMessage="Zugewiesen"
                    />
                    : {assignedChips}/{chipsLimit}
                  </label>
                </div>,
              ];
            }, [])}
          </form>

          <div className="button save-button" onClick={this.save}>
            <T
              id="tracar.rrfid-chip-assign-devices.save"
              defaultMessage="Zuordnung übernehmen"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const RfidChipAssignDevices = injectIntl(RfidChipAssignDevicesClass);
