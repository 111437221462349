import React from "react";
import { FormattedMessage as T } from "react-intl";
import moment from "moment";
import Link from "react-router-dom/es/Link";
import { DropdownItems, DropdownMenu } from "../../tools/DropdownMenu";

export function UserGroupLine({ currentUserGroup, buttonToggles, user }) {
  const { group_name, users } = currentUserGroup;

  return (
    <div className="table-line grid-x grid-padding-x">
      <div className="cell small-12 medium-6 large-3 first-cell">
        <div className="name"> {group_name}</div>
      </div>
      <div className="cell auto table-line-right">
        <div className="button-container">
          <div
            className="button"
            onClick={() =>
              buttonToggles().toggleUserGroupAssignUserOverlay(currentUserGroup)
            }
          >
            <i className="fa fa-user" />
            {users && users.length != 0 && (
              <span className="badge red">{users.length}</span>
            )}
          </div>
        </div>
        <div className="button-container">
          <DropdownMenu>
            <DropdownItems>
              <li
                onClick={() =>
                  buttonToggles().toggleEditOverlay(currentUserGroup)
                }
              >
                <i className="fa fa-pencil" />
                <T
                  id="adminPanel.usergroups.submenu.label.edit"
                  defaultMessage="Bearbeiten"
                />
              </li>
              <li
                onClick={() =>
                  buttonToggles().toggleDeleteOverlay(currentUserGroup)
                }
              >
                <i className="fa fa-trash" />
                <T
                  id="adminPanel.usergroups.submenu.label.delete"
                  defaultMessage="Löschen"
                />
              </li>
            </DropdownItems>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}
