import React from "react";
import { TextInput } from "../TextInput";
import { RequestOverlay } from "../Request/RequestOverlay";
import { LoadingCircle } from "../LoadingCircle";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  oldPassword: {
    id: "tracar.user.oldPassword",
    defaultMessage: "Altes Passwort",
  },
  newPassword: {
    id: "tracar.user.newPassword",
    defaultMessage: "Neues Passwort",
  },
  repeatNewPassword: {
    id: "tracar.user.repeatNewPassword",
    defaultMessage: "Neues Passwort wiederholen",
  },
});

class ChangePasswordClass extends React.Component {
  changePassword;
  handleChange;

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      validationError: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  /// new password should have at least 8 characters
  /// repeated password should be the same
  validate() {
    if (this.state.newPassword.length < 8) {
      this.setState({
        validationError:
          "Das neue Passwort muss mindestens 8 Zeichen lang sein.",
      });
      return false;
    }
    if (this.state.newPassword.length > 128) {
      this.setState({
        validationError:
          "Das neue Passwort darf nicht länger als 128 Zeichen sein.",
      });
      return false;
    }
    if (/^[0-9]*$/.test(this.state.newPassword)) {
      this.setState({
        validationError:
          "Das neue Passwort darf nicht ausschließlich aus Zahlen bestehen.",
      });
      return false;
    }
    if (this.state.newPassword !== this.state.repeatNewPassword) {
      this.setState({
        validationError:
          "Das neue Passwort und die Wiederholung sind unterschiedlich.",
      });
      return false;
    }
    this.setState({ validationError: "" });
    return true;
  }

  changePassword() {
    if (!this.validate()) {
      return;
    }
    this.setState({ saving: true });
    this.props
      .changePassword({
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      })
      .then(() => this.props.closeFunction())
      .catch(err => {
        if (err.status === 401 || err.status === 403) {
          this.setState({
            saving: false,
            validationError: "Falsches Passwort.",
          });
        }
      });
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="change-password"
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : (
          <div>
            <h3>
              <T
                id="tracar.user.changePasswordHeadline"
                defaultMessage="Passwort ändern"
              />
            </h3>
            <TextInput
              type="password"
              id="oldPassword"
              name={t(terms.oldPassword)}
              value={this.state.oldPassword}
              onChange={this.handleChange}
            />
            <TextInput
              type="password"
              id="newPassword"
              name={t(terms.newPassword)}
              value={this.state.newPassword}
              onChange={this.handleChange}
            />
            <TextInput
              type="password"
              id="repeatNewPassword"
              name={t(terms.repeatNewPassword)}
              value={this.state.repeatNewPassword}
              onChange={this.handleChange}
            />
            {<div className="error">{this.state.validationError}</div>}
            <div className="spacer" />
            <div className="button save-button" onClick={this.changePassword}>
              <T
                id="tracar.user.saveChanges"
                defaultMessage="Änderungen speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const ChangePassword = injectIntl(ChangePasswordClass);
