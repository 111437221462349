import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class UserGroupDeleteOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showErrorMessage: false,
      deleteUserGroupName: this.props.deleteUserGroupRecord.group_name,
    };
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  submit() {
    this.props
      .deleteUserGroup(this.props.deleteUserGroupRecord.group_id)
      .then(this.props.closeFunction);
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className="header">
          <h3>
            <T
              id="adminPanel.usergroups.delete.headline"
              defaultMessage="Benutzergruppe löschen"
            />{" "}
            ->{" "}
            <span className="highlighted">
              {this.props.deleteUserGroupRecord.group_name}
            </span>
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div>
          <T
            id="adminPanel.usergroups.delete.confirmationText"
            defaultMessage="Sind Sie sicher, dass Sie diese Benutzergruppe löschen möchten?"
          />
        </div>
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.usergroups.delete.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.usergroups.delete.buttonLabel.submit"
              defaultMessage="Löschen"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserGroupDeleteOverlay = injectIntl(UserGroupDeleteOverlayClass);
