import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";

import { LoadingCircle } from "../LoadingCircle";

export class AssignFleetIdOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dlc_barcodes: [],
      selectedBarcode: "",
      loadingState: "loading",
      validationError: null,
    };
  }

  componentDidMount() {
    this.props.driversLicenseCheck().then(({ dlc_barcodes }) => {
      const selectedBarcode =
        (this.props.nonCompanyUser.dlc &&
          this.props.nonCompanyUser.dlc.dlc_barcode) ||
        "";
      const barcodes =
        selectedBarcode === ""
          ? dlc_barcodes.sort()
          : [selectedBarcode, ...dlc_barcodes].sort();
      this.setState({
        dlc_barcodes: barcodes,
        loadingState: "ready",
        selectedBarcode,
      });
    });
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  saveAssignment = () => {
    this.setState({ loadingState: "saving" });
    this.props
      .driversLicenseCheck(
        this.props.nonCompanyUser.email,
        this.state.selectedBarcode,
      )
      .then(() => this.props.closeFunction({ reload: true }))
      .catch(err =>
        this.setState({
          loadingState: "ready",
          validationError:
            "Es ist ein Fehler beim Speichern der Daten aufgetreten.",
        }),
      );
  };

  render() {
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h3>fleet iD zuweisen</h3>
        {(this.state.loadingState === "loading" ||
          this.state.loadingState === "saving") && <LoadingCircle />}
        {this.state.loadingState === "ready" && (
          <div>
            <div className="overlay-content">
              <div>Bitte wählen Sie die fleet iD für folgenden Nutzer aus:</div>
              <div>{this.props.nonCompanyUser.email}</div>
              <div>
                {this.props.nonCompanyUser.first_name}{" "}
                {this.props.nonCompanyUser.last_name}
              </div>
            </div>
            <div className="small-spacer" />
            {this.state.dlc_barcodes.length > 0 && (
              <select
                className="select button button-100"
                id="selectedBarcode"
                value={this.state.selectedBarcode}
                onChange={this.handleChange}
              >
                <option value="" key={""}>
                  keine fleet iD
                </option>
                {this.state.dlc_barcodes.map(barcode => (
                  <option value={barcode} key={barcode}>
                    {barcode}
                  </option>
                ))}
              </select>
            )}
            {this.state.dlc_barcodes.length === 0 && (
              <div>Es stehen keine weiteren fleet iDs zur Verfügung.</div>
            )}
            <div className="small-spacer" />

            {this.state.validationError && (
              <div className="error">{this.state.validationError}</div>
            )}
            <div className="flex-just-between">
              <div className="link" onClick={this.props.closeFunction}>
                abbrechen
              </div>
              <div className="button save-button" onClick={this.saveAssignment}>
                Zuordnung speichern
              </div>
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}
