import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";
import Link from "react-router-dom/es/Link";
import { Portal } from "../Portal";
import { NewCar } from "../TrackerDetail/NewCar";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  setOdometer: {
    id: "tracar.device.setOdometer",
    defaultMessage: "Kilometerzähler einstellen",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
});

class FahrtenbuchTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.fahrtenbuchSettings.fahrtenbuch_current_car_details,
      ...this.props.fahrtenbuchSettings,
      ...this.props.userSettings,
      showNewCarOverlay: false,
    };

    this.toggleNewCarOverlay = this.toggleNewCarOverlay.bind(this);
  }

  componentWillUnmount() {
    this.rememberSettings();
    this.rememberUserSettings();
  }

  rememberSettings = () => {
    let settings = this.props.fahrtenbuchSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    Object.entries(settings.fahrtenbuch_current_car_details).forEach(
      setting => {
        if (this.state[setting[0]] !== null) {
          settings.fahrtenbuch_current_car_details[setting[0]] = this.state[
            setting[0]
          ];
        }
      },
    );

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  rememberUserSettings = () => {
    let settings = this.props.userSettings;
    if (!settings) {
      return;
    }
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]])
        settings[setting[0]] = isNaN(parseInt(this.state[setting[0]]))
          ? this.state[setting[0]]
          : parseInt(this.state[setting[0]]);
    });

    this.props.updateDeviceSettings("user_settings", settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.rememberUserSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: this.props.fahrtenbuchSettings,
    });

    Object.assign(settingsContainer, {
      user_settings: this.props.userSettings,
    });
    this.props.saveDeviceSettings(settingsContainer);
  };

  toggleNewCarOverlay() {
    this.setState({ showNewCarOverlay: !this.state.showNewCarOverlay });
  }

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "set_speed_limit" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  setProfilePrivate = user_owns_car => {
    this.props.setSettingsSaving("false");
    user_owns_car && this.setState({ is_fahrtenbuch_public: false });
  };

  setProfilePublic = user_owns_car => {
    this.props.setSettingsSaving("false");
    user_owns_car && this.setState({ is_fahrtenbuch_public: true });
  };

  setClassifyRoutes = (classify_routes, user_owns_car) => {
    this.props.setSettingsSaving("false");
    user_owns_car &&
      this.setState({ classify_routes, classify_fahrtenbuch: classify_routes });
  };

  render() {
    const t = this.props.intl.formatMessage;

    const user_owns_car =
      parseInt(this.props.user.user_id, 10) ===
      parseInt(this.state.assigned_user || this.props.user.user_id, 10);

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <div className="grid-x grid-margin-x">
              <TextInput
                className="medium-6 large-6 cell"
                id="car_number"
                name={t(terms.licensePlate)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.car_number}
                disabled={this.props.readOnly}
              />
              <TextInput
                className="medium-6 large-6 cell"
                id="model"
                name={t(terms.model)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.model}
                disabled={this.props.readOnly}
              />
              <TextInput
                className="medium-6 large-6 cell"
                id="driver"
                name={t(terms.driver)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.driver}
                disabled={this.props.readOnly}
              />
              <div className="text-input-component medium-6 large-6 cell autoHeight">
                <TextInput
                  className="noMargin noPadding"
                  id="device_odom_curr"
                  name={t(terms.setOdometer)}
                  onChange={event => this.handleChange(event)}
                  type="text"
                  value={this.state.device_odom_curr}
                  disabled={this.props.readOnly}
                />
                <div>
                  <T
                    id="tracar.device.currentOdometer"
                    defaultMessage="Aktueller Kilometerstand nach Fahrtenbuch: {mileage} km"
                    values={{
                      mileage: parseInt(
                        this.props.fahrtenbuchSettings
                          .fahrtenbuch_current_car_details.device_odom_curr,
                        10,
                      ),
                    }}
                  />
                  <div className="small-spacer" />
                  <small>
                    <T
                      id="tracar.device.deviationsArePrivate"
                      defaultMessage="Abweichungen bis 10% müssen nicht korrigiert werden. Korrekturen werden als Privatfahrten verbucht."
                    />
                  </small>
                </div>
              </div>
            </div>

            <div className="grid-x grid-margin-x">
              <div className="medium-6 large-6 cell">
                <div className="weekday-input">
                  <div className="weekday-buttons">
                    <div
                      className={
                        "button" +
                        (this.state.is_fahrtenbuch_public
                          ? " border"
                          : " active") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setProfilePrivate(user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.privateInvisible"
                        defaultMessage="private Fahrten sind nicht sichtbar"
                      />
                    </div>
                    <div
                      className={
                        "button" +
                        (this.state.is_fahrtenbuch_public
                          ? " active"
                          : " border") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setProfilePublic(user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.allVisible"
                        defaultMessage="alle Fahrten sind sichtbar"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.public ? (
                    <T
                      id="tracar.device.allVisibleMessage"
                      defaultMessage="Alle Fahrten sind für den Fuhrparkleiter sichtbar."
                    />
                  ) : (
                    <T
                      id="tracar.device.privateInvisibleMessage"
                      defaultMessage="Nur Dienstfahrten sind für den Fuhrparkleiter sichtbar."
                    />
                  )}
                </div>
              </div>
              <div className="medium-6 large-6 cell">
                <div className="weekday-input">
                  <div className="weekday-buttons">
                    <div
                      className={
                        "button" +
                        (this.state.classify_routes ? " active" : " border") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setClassifyRoutes(true, user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.keepFahrtenbuch"
                        defaultMessage="Fahrtenbuch führen"
                      />
                    </div>
                    <div
                      className={
                        "button" +
                        (this.state.classify_routes ? " border" : " active") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setClassifyRoutes(false, user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.routList"
                        defaultMessage="Routenliste anzeigen"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.classify_routes ? (
                    <T
                      id="tracar.device.fahrtenbuchExplanation"
                      defaultMessage="Für das Fahrzeug sollen alle Fahrten im Fahrtenbuch klassifiziert werden."
                    />
                  ) : (
                    <T
                      id="tracar.device.routListExplanation"
                      defaultMessage="Sämtliche Fahrten werden unklassifiziert in der Routenliste angezeigt."
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="spacer" />
            {this.renderCarSwitch()}

            <div className="spacer" />
            {this.renderAdditionalCars(t)}

            {this.state.showNewCarOverlay && (
              <NewCar
                closeFunction={this.toggleNewCarOverlay}
                imei={this.props.imei}
                addNewCar={this.props.addNewCar}
                setCarFormData={(car_number, model, driver, odometer) => {
                  this.props.carsRequest(this.props.imei).then(cars => {
                    this.setState({
                      fahrtenbuch_old_car_details: cars,
                      car_number: car_number,
                      model: model,
                      driver: driver,
                      device_odom_curr: odometer,
                    });
                  });
                }}
              />
            )}
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  /**
   * Renders the section to switch a car
   * @return JSX
   */
  renderCarSwitch() {
    if (this.props.portal !== Portal.Auto) {
      return null;
    }

    return (
      <div>
        <div className="spacer" />

        <h5>
          <T
            id="tracar.device.switchCarHeadline"
            defaultMessage="Fahrzeugwechsel"
          />
        </h5>

        <div className="switch-car-description">
          <T
            id="tracar.device.switchCarDescription"
            defaultMessage="Wenn Sie den Tracker in einem neuen Fahrzeug einsetzen möchten, beachten Sie bitte folgende Hinweise: Sobald Sie die Eintragungen vorgenommen haben und speichern, wird das bisherige Fahrtenbuch geschlossen und archiviert.
"
          />
        </div>

        <div className="spacer" />

        <div
          className={"button border"}
          onClick={() => this.toggleNewCarOverlay()}
        >
          <T
            id="tracar.device.switchCarButton"
            defaultMessage="Fahrzeug wechseln"
          />
        </div>
      </div>
    );
  }

  renderAdditionalCars = t => {
    return this.props.fahrtenbuchSettings.fahrtenbuch_old_car_details.length >
      1 ? (
      <div>
        <h5>
          <T
            id="tracar.device.additionalCars"
            defaultMessage="Weitere Fahrzeuge"
          />
        </h5>
        <div className="table-frame">
          {this.props.fahrtenbuchSettings.fahrtenbuch_old_car_details
            .filter(car => !car.current_car)
            .map((car, index) => (
              <div key={index} className="table-line grid-x">
                <div className="cell small-12 medium-12 large-auto table-line-left flexible">
                  {car.model} - {car.car_number}
                </div>
                <div className="cell small-12 medium-1 large-1 table-line-right fixed">
                  <Link
                    to={{
                      pathname: `/${this.props.portal}/fahrtenbuch/${
                        this.props.imei
                      }`,
                      state: {
                        car_id: car.fahrtenbuch_cardetails_id,
                        car_number: car.car_number,
                        model: car.model,
                      },
                    }}
                    className="button-container"
                    data-for={`tracker-table-line-${this.props.imei}`}
                    data-tip={t(terms.fahrtenbuch)}
                  >
                    <i className="fa fa-book button" />
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    ) : (
      <div />
    );
  };

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          <div
            className={`button save-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"}`}
            onClick={() =>
              this.props.settingsSaving === "false" && this.saveDeviceSettings()
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.fahrtenbuch.saving"
                  defaultMessage="Fahrtenbuch wird aktualisiert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.fahrtenbuch.save"
                  defaultMessage="Fahrtenbuch aktualisieren"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.fahrtenbuch.saved"
                  defaultMessage="Fahrtenbuch aktualisiert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const FahrtenbuchTab = injectIntl(FahrtenbuchTabClass);
