import React from "react";
import { Redirect } from "react-router";
import { TextInput } from "../TextInput";
import { exchangeArrayElement, removeArrayElement } from "../utility";
import {
  defineMessages,
  FormattedMessage as T,
  injectIntl,
  IntlShape,
} from "react-intl";

const terms = defineMessages({
  customerName: {
    id: "customer.addresses.edit.customerName",
    defaultMessage: "Ansprechpartner",
  },
  customerCompanyName: {
    id: "customer.addresses.edit.customerCompanyName",
    defaultMessage: "Firmenname *",
  },
  street: {
    id: "customer.addresses.edit.street",
    defaultMessage: "Straße und Hausnummer *",
  },
  postalAddress: {
    id: "customer.addresses.edit.postalAddress",
    defaultMessage: "PLZ *",
  },
  city: {
    id: "customer.addresses.edit.city",
    defaultMessage: "Stadt *",
  },
  state: {
    id: "customer.addresses.edit.state",
    defaultMessage: "Bundesland",
  },
  country: {
    id: "customer.addresses.edit.country",
    defaultMessage: "Land",
  },
  reasons: {
    id: "customer.addresses.edit.reasons",
    defaultMessage: "Mögliche Anlässe für Betriebsfahren",
  },
});

class CustomerAddressesEditAddClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      saved: false,
      reason: [],
    };

    // check if edit mode. If so, load data.
    if (this.props.match.params.customerAddressId !== "new") {
      this.props
        .loadAddress(this.props.match.params.customerAddressId)
        .then(address => {
          this.setState({ ...this.state, ...address });
        });
    }
  }

  /**
   * Save text inputs
   * @param  event: NamedInputEvent
   * @return void
   */
  handleChange = event => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  /**
   * Save changed reasons
   * @param  event: NamedInputEvent
   * @return void
   */
  handleReasonChange = event => {
    const index = parseInt(event.target.id, 10);
    const reasons = exchangeArrayElement(
      this.state.reason,
      event.target.value,
      index,
    );
    this.setState({ reason: reasons });
  };

  /**
   * Add another empty reason
   * @return void
   */
  addReason = () => {
    this.setState({ reason: [...this.state.reason, ""] });
  };

  /**
   * Deletes a reason by index
   * @param  index: number
   * @return void
   */
  deleteReason = index => {
    const reasons = removeArrayElement(this.state.reason, index);
    this.setState({ reason: reasons });
  };

  /**
   * Saves the current data present in the state
   * @return void
   */
  saveData = () => {
    const { saved, customer_address, errors, ...customerAddress } = this.state;
    const valid = this.validateData(customerAddress);

    if (!valid) {
      return;
    }

    // edit
    if (this.props.match.params.customerAddressId !== "new") {
      return this.props.editCustomerAddress(customerAddress).then(() => {
        this.setState({ saved: true });
      });
    }

    return this.props.addNewCustomerAddress([customerAddress]).then(() => {
      this.setState({ saved: true });
    });
  };

  /**
   * Validates data. customer_company_name, street, postal_address and city are required
   * @param  Object customerAddress
   * @return  boolean
   */
  validateData = customerAddress => {
    const valid =
      customerAddress.city &&
      customerAddress.street &&
      customerAddress.postal_address &&
      customerAddress.customer_company_name;

    if (valid) {
      return valid;
    }

    this.setState({
      errors: {
        city: !customerAddress.city,
        street: !customerAddress.street,
        postalAddress: !customerAddress.postal_address,
        companyName: !customerAddress.customer_company_name,
        ...this.state.errors,
      },
    });

    return valid;
  };

  render() {
    if (this.state.saved) {
      const portal = this.props.match.params.portal || "tracar";
      return <Redirect to={`/${portal}/customerAddresses/list`} />;
    }

    const t = this.props.intl.formatMessage;

    return (
      <div>
        <div className="form">
          <div className="tracker-data-head">
            <h2>
              <T
                id="customer.addresses.edit.headline"
                defaultMessage="Kundenadresse"
              />
            </h2>
          </div>
          <div className="grid-x grid-margin-x">
            <TextInput
              className="medium-6 large-6 cell"
              id="customer_name"
              name={t(terms.customerName)}
              onChange={this.handleChange}
              type="text"
              value={this.state.customer_name}
            />
            <TextInput
              className={`medium-6 large-6 cell ${
                this.state.errors.companyName ? "error" : ""
              }`}
              id="customer_company_name"
              name={t(terms.customerCompanyName)}
              onChange={this.handleChange}
              type="text"
              value={this.state.customer_company_name}
            />
          </div>
          <div className="grid-x grid-margin-x">
            <TextInput
              className={`medium-6 large-6 cell ${
                this.state.errors.street ? "error" : ""
              }`}
              id="street"
              name={t(terms.street)}
              onChange={this.handleChange}
              type="text"
              value={this.state.street}
            />
            <TextInput
              className={`medium-6 large-6 cell ${
                this.state.errors.postalAddress ? "error" : ""
              }`}
              id="postal_address"
              name={t(terms.postalAddress)}
              onChange={this.handleChange}
              type="text"
              value={this.state.postal_address}
            />
            <TextInput
              className={`medium-6 large-6 cell ${
                this.state.errors.city ? "error" : ""
              }`}
              id="city"
              name={t(terms.city)}
              onChange={this.handleChange}
              type="text"
              value={this.state.city}
            />
            <TextInput
              className="medium-6 large-6 cell"
              id="state"
              name={t(terms.state)}
              onChange={this.handleChange}
              type="text"
              value={this.state.state}
            />
            <TextInput
              className="medium-6 large-6 cell"
              id="country"
              name={t(terms.country)}
              onChange={this.handleChange}
              type="text"
              value={this.state.country}
            />
          </div>

          <div className="tiny-spacer" />

          <div>
            <small>
              <T
                id="customer.addresses.edit.required"
                defaultMessage="*) Pflichtangaben"
              />
            </small>
          </div>

          <div className="spacer" />

          <h5>
            <T id="customer.addresses.edit.reason" defaultMessage="Anlass" />
          </h5>

          <div className="grid-x grid-margin-x">
            {this.state.reason.map((reason, index) => (
              <div className="medium-6 large-6 cell relative" key={index}>
                <TextInput
                  className=""
                  id={index.toString()}
                  name={t(terms.reasons)}
                  onChange={this.handleReasonChange}
                  type="text"
                  value={reason}
                />
                <div className="delete-input-button">
                  <div
                    className="button"
                    onClick={() => this.deleteReason(index)}
                  >
                    -
                  </div>
                </div>
              </div>
            ))}

            <div className="medium-6 large-6 cell">
              <div className="text-input-component">
                <div className="delete-input-button">
                  <div className="button" onClick={this.addReason}>
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="spacer" />

          <div className="button save-button" onClick={this.saveData}>
            <T id="tracar.device.save" defaultMessage="Daten speichern" />
          </div>
        </div>
      </div>
    );
  }
}

export const CustomerAddressesEditAdd = injectIntl(
  CustomerAddressesEditAddClass,
);
