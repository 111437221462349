import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import Plot from "react-plotly.js";
import { FormattedMessage as T } from "react-intl";
import { Link, withRouter } from "react-router-dom";

export class BatteryGraph extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const batteryValues = [
      ...this.props.battery_yesterday[0],
      ...this.props.battery_yesterday[1],
      ...this.props.battery_yesterday[2],
    ];

    const batteryBarColors = [
      ...this.props.battery_yesterday[0].map(() => Color.SilverChalice1),
      ...this.props.battery_yesterday[1].map(() => Color.DoveGrey),
      ...this.props.battery_yesterday[2].map(() => Color.Red),
    ];
    return (
      <div className="wide-graph-frame">
        <div className="graph-headline">
          <h5 className="headline-for-icon">
            {" "}
            <i className="fa fa-battery" />
            &nbsp;&nbsp;
            <T id="tracar.statistics.battery" defaultMessage="Batterie" />
          </h5>
        </div>
        <Plot
          config={this.props.config}
          data={[
            {
              x: batteryValues.map(({ device_name }) => device_name),
              y: batteryValues.map(({ voltage }) => voltage),
              type: "bar",
              marker: {
                color: batteryBarColors,
              },
              text: "Volt",
              textformat: "x+y+text",
              customdata: batteryValues.map(({ imei }) => imei),
            },
          ]}
          layout={{
            ...this.props.widePlotLayoutCommons,
            margin: this.props.widePlotLayoutCommons.margin,
            yaxis: { title: "Volt", tickformat: ",.1f," },
            xaxis: {
              ...this.props.noAxisValues,
              type: "category",
            },
          }}
          onClick={data => {
            this.props.history.push({
              pathname: `/${this.props.portal}/device-list/settings/${
                data.points[0].customdata
              }`,
              state: {
                selectedDeviceImei: data.points[0].customdata,
              },
            });
          }}
        />
        <ul style={{ marginLeft: "64px", marginTop: "-28px" }}>
          <li>
            <T id="tracar.battery.state" defaultMessage="Batteriezustand:" />
            &nbsp;&nbsp;&nbsp;
          </li>{" "}
          &nbsp;&nbsp;&nbsp;
          <li>
            <i
              className="fa fa-square"
              style={{ color: Color.SilverChalice1 }}
            />{" "}
            <T id="tracar.battery.bad" defaultMessage="schlecht" />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i className="fa fa-square" style={{ color: Color.DoveGrey }} />
            &nbsp;{" "}
            <T
              id="tracar.battery.acceptable"
              defaultMessage="akzeptabel"
            />{" "}
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i className="fa fa-square" style={{ color: Color.Red }} />
            &nbsp; <T id="tracar.battery.ideal" defaultMessage="ideal" />
          </li>
        </ul>
      </div>
    );
  }
}
