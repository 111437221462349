import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../tools/QueryLine";

class RfidableAssignChipsClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: true,
      hasApiError: false,
      chipsWithStatus: this.getChipsWithStatus(this.props.rfidChipList),
      searchFilter: "",
    };
  }

  handleChange = (chip, status) => {
    if (status) {
      this.props.devices.forEach(device => {
        device.assigned_chips.length <
          device.assigned_chip_count + device.available_slots &&
          this.props.updateDevicesToAssign(
            this.updateDeviceAssignedChipsArray(
              device.assigned_chips,
              chip,
              status,
            ),
          );
      });
    } else {
      this.props.devices.forEach(device => {
        this.props.updateDevicesToAssign(
          this.updateDeviceAssignedChipsArray(
            device.assigned_chips,
            chip,
            status,
          ),
        );
      });
    }
    this.setState({
      chipsWithStatus: this.getChipsWithStatus(this.props.rfidChipList),
    });
  };

  //uploadChipsAndDevicesAssignStatus
  save = async () => {
    this.setState({ waiting: true });

    const chipsToAssign = this.state.chipsWithStatus.flatMap(chip =>
      chip.status === "assigned" ? chip.chip.rfid_chip_id : [],
    );

    const chipsToDeassign = this.state.chipsWithStatus.flatMap(chip =>
      chip.status === "deassigned" ? chip.chip.rfid_chip_id : [],
    );

    const devicesToUpdate = this.props.devices.map(device => device.device_id);

    this.props.fetchRfid
      .assignChipsToDevices(
        chipsToAssign,
        chipsToDeassign,
        devicesToUpdate,
        devicesToUpdate,
      )
      .then(res => {
        this.props.loadRfidable();
        this.props.loadRfidChips();
        this.props.closeFunction();
      })
      .catch(err => {
        console.log("error:", err);
        this.setState({ saving: false, hasApiError: true });
      });
  };

  getChipsWithStatus = chips => {
    return chips.map(chip => {
      let chipWithStatus = { chip: chip, status: "" };
      this.props.devices.forEach(device =>
        device.assigned_chips.length > 0 &&
        device.assigned_chips.find(achip => chip.chip_code === achip.chip_code)
          ? chipWithStatus.status === ""
            ? (chipWithStatus.status = "assigned")
            : chipWithStatus.status === "deassigned" &&
              (chipWithStatus.status = "partial")
          : chipWithStatus.status === ""
          ? (chipWithStatus.status = "deassigned")
          : chipWithStatus.status === "assigned" &&
            (chipWithStatus.status = "partial"),
      );
      return chipWithStatus;
    });
  };

  updateDeviceAssignedChipsArray = (array, chipToUpdate, status) => {
    if (status) {
      if (!array.find(chip => chipToUpdate.chip_code === chip.chip_code))
        array.push(chipToUpdate);
    } else {
      for (var i = 0; i < array.length; i++) {
        array[i].chip_code === chipToUpdate.chip_code && array.splice(i, 1);
      }
    }
    return array;
  };

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="create-new-chip"
      >
        <div>
          <h5>
            <T
              id="tracar.rfid-chip-assign-devices.headline"
              defaultMessage="Chips-Zuordnung für Geräte: "
            />
            {this.props.devices.map(device => {
              const assignedChips = device.assigned_chips.length;
              const chipsLimit =
                device.assigned_chip_count + device.available_slots;
              return (
                <div key={device.device_id}>
                  {device.device_name} ({assignedChips}/{chipsLimit}),
                </div>
              );
            })}
          </h5>
          <p>
            <T
              id="tracar.rfid-chip-assign-devices.assign-alert"
              defaultMessage="Die Zuweisung kann erst aktualisiert werden, sobald das betroffene Gerät online ist."
            />
          </p>
          <p>
            <T
              id="tracar.rfid-chip-assign-devices.description"
              defaultMessage="Wählen Sie alle Chips aus, die sie zuordnen möchten:"
            />
          </p>

          <QueryLine changeCallback={this.setSearchFilter} />

          <form id="assign-devices">
            {this.state.chipsWithStatus.reduce((acc, chip) => {
              const stringFiltering =
                this.state.searchFilter.length === 0 ||
                chip.chip.chip_name
                  .toLowerCase()
                  .search(this.state.searchFilter.toLowerCase()) > -1 ||
                String(chip.chip.chip_code).search(this.state.searchFilter) >
                  -1 ||
                chip.chip.fname
                  .toLowerCase()
                  .search(this.state.searchFilter.toLowerCase()) > -1 ||
                chip.chip.lname
                  .toLowerCase()
                  .search(this.state.searchFilter.toLowerCase()) > -1;

              if (!stringFiltering) {
                return acc;
              }

              return [
                ...acc,

                <div className="chip" key={chip.chip.chip_code}>
                  <label
                    htmlFor={chip.chip.chip_id}
                    className={`button ${
                      chip.status === "assigned" ? " active" : ""
                    }`}
                    style={
                      chip.status === "partial"
                        ? { border: "solid 3px rgb(230, 0, 0)" }
                        : {}
                    }
                    onClick={() =>
                      this.handleChange(
                        chip.chip,
                        chip.status === "deassigned" ? true : false,
                      )
                    }
                  >
                    {chip.chip.chip_code} - {chip.chip.fname} {chip.chip.lname}
                    <br />
                    {chip.chip.chip_name}
                    <br />
                    (Code: {chip.chip.chip_code})
                  </label>
                </div>,
              ];
            }, [])}
          </form>

          <div className="button save-button" onClick={this.save}>
            <T
              id="tracar.rrfid-chip-assign-devices.save"
              defaultMessage="Zuordnung übernehmen"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const RfidableAssignChips = injectIntl(RfidableAssignChipsClass);
