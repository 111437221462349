import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { NonCompanyUserList } from "./NonCompanyUserList";
import { InviteOverlay } from "./InviteOverlay";
import { AssignFleetIdOverlay } from "../DriversLicenseCheck/AssignFleetIdOverlay";

export class NonCompanyUserListBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteMessage: null,
      nonCompanyUserList: null,
      showAssignFleetIdOverlay: false,
      showInviteOverlay: false,
      nonCompanyUserForFleetId: null,
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.props.getNonCompanyUserList().then(nonCompanyUserList =>
      this.setState({
        nonCompanyUserList,
      }),
    );
  }

  inviteUser = email => {
    this.setState({ nonCompanyUserList: null, showInviteOverlay: false });
    this.props
      .signUpNonCompanyUser(email)
      .then(() => {
        this.load();
        this.setState({
          inviteMessage: {
            text: `Der Nutzer mit der E-Mailadresse ${email} wurde eingeladen.`,
            className: "",
          },
        });
      })
      .catch(err => {
        if (err.status === 400 || err.status === 409) {
          this.load();
          this.setState({
            inviteMessage: {
              text: `Ein Nutzer mit der E-Mailadresse ${email} existiert bereits.`,
              className: "error",
            },
          });
        }
      });
  };

  toggleInviteOverlay = () => {
    this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
  };

  toggleAssignFleetIdOverlay = nonCompanyUserForFleetId => {
    this.setState({
      showAssignFleetIdOverlay: !this.state.showAssignFleetIdOverlay,
      nonCompanyUserForFleetId,
    });
  };

  assignFleetIdOverlayClosingFunction = options => {
    this.setState({
      showAssignFleetIdOverlay: false,
      nonCompanyUserForFleetId: null,
    });

    options && options.reload && this.load();
  };

  render() {
    if (this.state.nonCompanyUserList != null) {
      /// loaded
      return (
        <div>
          <NonCompanyUserList
            toggleInviteOverlay={this.toggleInviteOverlay}
            toggleAssignFleetIdOverlay={this.toggleAssignFleetIdOverlay}
            nonCompanyUserList={this.state.nonCompanyUserList}
            inviteMessage={this.state.inviteMessage}
            userLoginData={this.props.userLoginData}
          />
          {this.state.showInviteOverlay && (
            <InviteOverlay
              closeFunction={this.toggleInviteOverlay}
              inviteUser={this.inviteUser}
            />
          )}
          {this.state.showAssignFleetIdOverlay &&
            this.state.nonCompanyUserForFleetId && (
              <AssignFleetIdOverlay
                closeFunction={this.assignFleetIdOverlayClosingFunction}
                driversLicenseCheck={this.props.driversLicenseCheck}
                nonCompanyUser={this.state.nonCompanyUserForFleetId}
              />
            )}
        </div>
      );
    } else {
      /// loading
      return <LoadingCircle />;
    }
  }
}
