import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import moment from "moment";
import { FormattedMessage as T } from "react-intl";
import { sToh } from "../tools/functions";
import numeral from "numeral";

export function DeviceUsageGraph({
  config,
  noAxisValues,
  usage_data,
  widePlotLayoutCommons,
}) {
  /// same code is found in VehicleUsageLast30d
  const x = usage_data.map(({ date }) => moment(date).format("DD.MM.YYYY"));
  const yWire1 = usage_data.map(({ usage_wire1: { data } }) => {
    return sToh(parseFloat(data));
  });
  const yWire2 = usage_data.map(({ usage_wire2: { data } }) =>
    sToh(parseFloat(data))
  );
  const yWire3 = usage_data.map(({ usage_wire3: { data } }) =>
    sToh(parseFloat(data))
  );
  const yIgnition = usage_data.map(({ usage_ignition }) =>
    sToh(parseFloat(usage_ignition))
  );
  const yEngine = usage_data.map(({ usage_engine }) =>
    sToh(parseFloat(usage_engine))
  );

  const nf = (n: number) => numeral(n).format("0,0.0");

  const textLabel = x.map((device_name, index) => {
    return (
      `Zündung:  ${nf(yIgnition[index])} h<br />` +
      `Motor:  ${nf(yEngine[index])} h<br />` +
      `${usage_data[index].usage_wire1.name}:  ${nf(yWire1[index])} h<br />` +
      `${usage_data[index].usage_wire2.name}:  ${nf(yWire2[index])} h<br />` +
      `${usage_data[index].usage_wire3.name}:  ${nf(yWire3[index])} h<br />`
    );
  });

  const groupedBarChart = [
    {
      x,
      y: yIgnition,
      marker: { color: Color.Red },
      type: "bar",
      hoverinfo: "x+text",

      text: textLabel,
    },
    {
      x,
      y: yEngine,
      marker: { color: Color.DoveGrey },
      type: "bar",
      hoverinfo: "none",
    },
    {
      x,
      y: yWire1,
      type: "bar",
      marker: { color: Color.SilverChalice1 },
      hoverinfo: "none",
    },
    {
      x,
      y: yWire2,
      marker: { color: Color.SilverChalice },
      type: "bar",
      hoverinfo: "none",
    },
    {
      x,
      y: yWire3,
      marker: { color: Color.Silver },
      type: "bar",
      hoverinfo: "none",
    },
  ];

  return (
    <div className="wide-graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          {" "}
          <i className="fa fa-truck" />
          <T
            id="tracar.vehicleUsage.usage30d"
            defaultMessage="Gerätenutzung, letzte 30 Tage"
          />
        </h5>
      </div>
      <Plot
        config={config}
        data={groupedBarChart}
        layout={{
          ...widePlotLayoutCommons,
          margin: widePlotLayoutCommons.margin,
          yaxis: { title: "h", tickformat: ",.0f," },
          xaxis: {
            ...noAxisValues,
            type: "category",
          },
        }}
      />
      <ul style={{ marginLeft: "16px", marginTop: "-28px" }}>
        <li>
          <i className="fa fa-square" style={{ color: Color.Red }} />
          &nbsp;&nbsp;
          <T id="tracar.vehicleUsage.ignition" defaultMessage="Zündung" />
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.DoveGrey }} />
          &nbsp;&nbsp;
          <T id="tracar.vehicleUsage.engine" defaultMessage="Motor" />{" "}
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.SilverChalice1 }} />
          &nbsp;&nbsp;
          <T
            id="tracar.wires.brownCable"
            defaultMessage="Braunes Kabel; Input 1"
          />
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.SilverChalice }} />
          &nbsp;&nbsp;
          <T
            id="tracar.wires.purpleCable"
            defaultMessage="Lila Kabel; Input 2"
          />
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.Silver }} />{" "}
          <T
            id="tracar.wires.greenCable"
            defaultMessage="Grünes Kabel; Input 3"
          />
        </li>
      </ul>
    </div>
  );
}
