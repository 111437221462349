import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { removeArrayElement } from "../utility";
import { Link } from "react-router-dom";
import { LoadingSmallCircle } from "../LoadingCircle";

class TagTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.tagSettings,
      resetSettings: false,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.tagSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null)
        settings[setting[0]] = this.state[setting[0]];
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    if (this.state.resetSettings) {
      this.setState({
        resetSettings: false,
        tag_ids_assigned_to_device: [],
        resetSettings: false,
      });
      this.props.resetDeviceSettings(["tags_settings"]);
    } else {
      this.rememberSettings();

      let settingsContainer = {};
      Object.assign(settingsContainer, {
        [this.props.tabType]: this.state,
      });

      this.props.saveDeviceSettings(settingsContainer);
    }
  };

  changeAssignedTags = tagId => {
    this.props.setSettingsSaving("false");
    const index = this.state.tag_ids_assigned_to_device.indexOf(tagId);
    if (index === -1) {
      this.setState({
        tag_ids_assigned_to_device: [
          ...this.state.tag_ids_assigned_to_device,
          tagId,
        ],
      });
    } else {
      this.setState({
        tag_ids_assigned_to_device: removeArrayElement(
          this.state.tag_ids_assigned_to_device,
          index,
        ),
      });
    }
  };

  toggleResetSettings = () => {
    this.setState({ resetSettings: !this.state.resetSettings });
    this.props.setSettingsSaving("false");
  };

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            {!this.props.readOnly && (
              <div className="settings-bulk-buttons-container">
                <div
                  className="button settings-bulk-button delete-button"
                  onClick={() => this.toggleResetSettings()}
                >
                  {this.state.resetSettings ? (
                    <T
                      id="tracar.settings-tags.undo"
                      defaultMessage="Rückgängig machen"
                    />
                  ) : (
                    <T
                      id="tracar.settings-tags.reset"
                      defaultMessage="Tag-Einstellungen zurücksetzen"
                    />
                  )}
                  <i className="icon-uniF2D3" />
                </div>
              </div>
            )}
            {!this.state.resetSettings ? (
              <ul className="tag-buttons assign-list">
                {this.state.all_tags_of_the_user.tag_id_list.map(tagId => {
                  const tagObject = this.state.all_tags_of_the_user.tag_objects[
                    tagId.toString()
                  ];
                  return (
                    <li
                      className={
                        "button button-100 " +
                        (this.state.tag_ids_assigned_to_device &&
                        this.state.tag_ids_assigned_to_device.find(
                          id => id === tagId,
                        )
                          ? ""
                          : " inactive ")
                      }
                      onClick={() =>
                        !this.props.readOnly && this.changeAssignedTags(tagId)
                      }
                      key={tagId}
                    >
                      <i
                        className="fa fa-tag"
                        style={{ color: tagObject.tag_color }}
                      />
                      &nbsp;&nbsp;&nbsp;{tagObject.tag_name}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="table-line">
                <T
                  id="tracar.settings-tags.reset-notification"
                  defaultMessage="Sie haben die Einstellungen zurückgesetzt. Bitte bestätigen Sie die Änderungen mittels 'Tags aktualisieren'"
                />
              </div>
            )}
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.tags.saving"
                    defaultMessage="Tags werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.tags.save"
                    defaultMessage="Tags aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.tags.saved"
                    defaultMessage="Tags aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const TagTab = injectIntl(TagTabClass);
