import React from "react";
import Link from "react-router-dom/es/Link";
import { defineMessages, injectIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Portal, PortalDefault } from "./Portal";
import LinkButton from "./LinkButton";
import { UserConsumer } from "./Base.jsx";
import FormattedMessageFixed from "./tools/FormattedMessageFixed";
import { isDev } from "./tools/Environment";
import { hasPermission, hasPortalAccess, Permission } from "./Permission";

const terms = defineMessages({
  map: {
    id: "base.map",
    defaultMessage: "Kartenansicht",
  },
  deviceList: {
    id: "base.deviceList",
    defaultMessage: "Geräteliste",
  },
  serviceIntervals: {
    id: "base.serviceIntervals",
    defaultMessage: "Wartungsintervalle",
  },
  geofences: {
    id: "base.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  userList: {
    id: "base.userList",
    defaultMessage: "Nutzerliste",
  },
  dashboard: {
    id: "base.dashboard",
    defaultMessage: "Dashboard",
  },
  customerAddresses: {
    id: "base.customerAddresses",
    defaultMessage: "Kundenadressen",
  },
  rfid: {
    id: "base.rfid",
    defaultMessage: "RFID",
  },
});

class PortalSelectionClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const t = this.props.intl.formatMessage;
    const portal = (this.props.match && this.props.match.params.portal) || "";
    var destination = "map";
    return (
      <UserConsumer>
        {user => {
          return (
            <div className="tracker-type-tabs">
              <div className="tabs-inner-wrapper">
                <div style={{ display: "inline-block" }}>
                  {user.availablePortals.map(portalShown => {
                    {
                      portalShown === Portal.All
                        ? (destination = "map")
                        : (destination = hasPermission(
                            user,
                            Permission.Dashboard,
                          )
                            ? "dashboard"
                            : "device-list");
                    }

                    // do not show "All" portal if user does not have the location permission, since the portal is all map
                    if (
                      portalShown === Portal.All &&
                      !hasPortalAccess(user, Portal.All)
                    ) {
                      return null;
                    }

                    if (portal === PortalDefault) {
                      return null;
                    }
                    // do not show admin panel in portal selection list
                    // users navigate to the admin panel via a dedicated link
                    if (portalShown === Portal.AdminPanel) {
                      return null;
                    }
                    return (
                      <div
                        className={`tab ${
                          portal === portalShown ? "active" : ""
                        }`}
                        key={portalShown}
                      >
                        <Link
                          to={`/${portalShown}/${destination}`}
                          className="tab-label"
                        >
                          <FormattedMessageFixed
                            id={`tracar.portal.${portalShown}`}
                            defaultMessage={`Portal ${portalShown}`}
                          />
                        </Link>
                        {portalShown !== Portal.All ? (
                          <div className="button-group">
                            <ReactTooltip
                              delayShow={500}
                              class="tooltip"
                              border={true}
                              id="nav"
                            />
                            {hasPermission(user, Permission.Dashboard) && (
                              <LinkButton
                                iconClass="fa fa-tachometer"
                                tip={t(terms.dashboard)}
                                to={`/${portalShown}/dashboard`}
                              />
                            )}
                            {hasPermission(user, Permission.Location) && (
                              <LinkButton
                                iconClass="fa fa-map-marker"
                                tip={t(terms.map)}
                                to={`/${portalShown}/map`}
                              />
                            )}
                            {hasPermission(user, Permission.DeviceList) && (
                              <LinkButton
                                iconClass="fa fa-list"
                                tip={t(terms.deviceList)}
                                to={`/${portalShown}/device-list`}
                              />
                            )}
                            <LinkButton
                              iconClass="fa fa-wrench"
                              tip={t(terms.serviceIntervals)}
                              to={`/${portalShown}/serviceIntervals`}
                            />
                          </div>
                        ) : (
                          <React.Fragment />
                        )}
                      </div>
                    );
                  })}
                </div>

                <div>
                  <div className="tab active">
                    <div className="button-group" style={{ maxWidth: "200px" }}>
                      <LinkButton
                        iconClass="fa fa-globe"
                        tip={t(terms.geofences)}
                        to={`/${portal}/geofencing`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </UserConsumer>
    );
  }
}

export const PortalSelection = injectIntl(PortalSelectionClass);
