import { postJson } from "./fetchService";

const deviceRoute = "/user/v3/devicesetting/";
const bulkRoute = "/user/get_bulk_device_settings";
const updateRoute = "/user/set_bulk_device_settings";
const resetRoute = "/user/bulk_delete";

export const fetchDeviceSettings = jsonFetch => ({
  device(imei) {
    return jsonFetch(deviceRoute + imei);
  },

  bulk(imeiList) {
    return jsonFetch(bulkRoute, postJson({ selected_devices: imeiList }));
  },

  update(imeiList, settings) {
    return jsonFetch(
      updateRoute,
      postJson({
        selected_devices: imeiList,
        settings: settings,
      }),
    );
  },

  reset(imeiList, settings) {
    return jsonFetch(
      resetRoute,
      postJson({
        selected_devices: imeiList,
        settings: settings,
      }),
    );
  },
});
