import React from "react";
import { FormattedMessage as T } from "react-intl";
import moment from "moment";

export function NonCompanyUserLine({
  nonCompanyUser,
  toggleAssignFleetIdOverlay,
  user,
}) {
  /*eslint-disable */
  const {
    activation_expires,
    city,
    dlc,
    date_created,
    email,
    first_name,
    gender,
    is_active,
    is_company,
    language,
    last_name,
    phone,
    postal_code,
    state,
    street,
  } = nonCompanyUser;
  /*eslint-enable */
  const invitationDate = moment(date_created).format("DD.MM.YYYY");
  const expirationTime =
    activation_expires.days > 0 ? (
      <T
        id="tracar.users.xDay"
        defaultMessage="{days} Tagen"
        values={{ days: activation_expires.days }}
      />
    ) : (
      <T
        id="tracar.users.xHours"
        defaultMessage="{hours} Stunden"
        values={{ hours: activation_expires.hours }}
      />
    );
  return (
    <div className="table-line grid-x ">
      <div
        className={
          "cell small-12 medium-6 large-4" + (is_active ? "" : " inactive")
        }
      >
        <i className={"fa " + (is_active ? "fa-user" : "fa-user-o")} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style={{ display: "inline-block" }}>
          <strong>{email}</strong>{" "}
        </div>
      </div>
      <div className="cell small-12 medium-12 large-auto table-line-middle flexible">
        <small className={"note" + (is_active ? "" : " inactive")}>
          {!is_active && (
            <span>
              <T
                id="tracar.users.notActivated"
                defaultMessage="noch nicht aktiviert "
              />{" "}
              &nbsp;&nbsp;&nbsp;{" "}
              <T
                id="tracar.users.inviteValidUntil"
                defaultMessage=" Einladung erlischt in {expirationTime}"
                values={{ expirationTime }}
              />{" "}
              &nbsp;&nbsp;&nbsp;{" "}
            </span>
          )}
          {first_name} {last_name} {(first_name || last_name) && "-"}{" "}
          {is_company ? (
            <T
              id="tracar.users.registeredAt"
              defaultMessage=" Fuhrparkleiter, registriert am {invitationDate}"
              values={{ invitationDate }}
            />
          ) : (
            <T
              id="tracar.users.invitedAt"
              defaultMessage=" eingeladen am {invitationDate}"
              values={{ invitationDate }}
            />
          )}
        </small>
        <small style={{ display: "block" }}>
          {dlc && dlc.dlc_barcode && (
            <span>
              {dlc.warned && (
                <span>
                  <i className="fa fa-exclamation-triangle" />
                  &nbsp;&nbsp;&nbsp;
                </span>
              )}
              fleet iD: {dlc.dlc_barcode}&nbsp;&nbsp;&nbsp;
              {dlc.warned &&
                ` verpasste Prüfung: ${moment(dlc.first).format("DD.MM.YYYY")}`}
            </span>
          )}
        </small>
      </div>
      <div className="cell small-12 medium-1 large-1 table-line-right fixed">
        {/* TODO: re-enable with correct permission
        {user.normalizedFunctionalities.basic.drivers_license_check && (
          <div
            className="button"
            data-for={`non-company-user-line`}
            data-tip={`fleet iD`}
            onClick={() => toggleAssignFleetIdOverlay(nonCompanyUser)}
          >
            <i className="fa fa-address-card-o" />
          </div>
        )}*/}
      </div>
    </div>
  );
}
