/**
 * Device filter type name constants. These are the values as expected by the API
 * @type {{All: string, Assigned: string, AssignedFahrtenbuch: string, Unassigned: string}}
 */
export const DeviceFilterType = {
  All: "ALL",
  Assigned: "ASSIGNED",
  Unassigned: "UNASSIGNED",
  AssignedFahrtenbuch: "ASSIGNED_FAHRTENBUCH",
};

export const DeviceAssignmentType = {
  NotAssigned: "NOT_ASSIGNED",
  Read: "READ",
  Write: "WRITE",
  Manage: "MANAGE",
  Fahrtenbuch: "FAHRTENBUCH",
};
