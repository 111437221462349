import { deleteJson, postCSV, postJson, putJson } from "./fetchService";
import { apiBaseUrl } from "./middleware";

const serverUrlBase = apiBaseUrl;
const serverUrlAdminUser = "/admin_user";

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchUsers(wrappedFetch) {
  // TODO: ask for a paginated endpoint
  const getUserList = () =>
    wrappedFetch(
      `${serverUrlAdminUser}/listusers`,
      postJson({
        offset: 0,
        limit: -1,
        search_key: "",
      }),
    );
  const getUserListAll = getUserList;
  const getUserListFiltered = (
    offset,
    limit,
    search_key,
    search_type,
    filter,
    is_autogenerated,
    is_active,
  ) =>
    wrappedFetch(
      `${serverUrlAdminUser}/listusers`,
      postJson({
        offset,
        limit,
        search_key,
        search_type,
        filter,
        is_autogenerated,
        is_active,
      }),
    );
  const getUserListByRole = role_name =>
    getUserListFiltered(0, 20, role_name, "role", false, null, null);
  // wrappedFetch("/user/listusers", postJson({ role_id: role_id }));
  const signUpUser = email => wrappedFetch("/user/signup", postJson({ email }));
  const generateEmailAddress = (first_name, last_name, unique_employee_id) =>
    wrappedFetch(
      `${serverUrlAdminUser}/generate_email`,
      postJson({
        fname: first_name ? first_name : "",
        lname: last_name ? last_name : "",
        unique_employee_id: unique_employee_id ? unique_employee_id : "",
      }),
    );
  const updateUser = userRecord => {
    // unwrap user object
    userRecord = userRecord.user;
    return wrappedFetch(
      `${serverUrlAdminUser}/user/${userRecord.id}`,
      putJson({
        first_name: userRecord.first_name,
        last_name: userRecord.last_name,
        timezone: userRecord.timezone,
        unique_employee_id: userRecord.unique_employee_id,
      }),
    );
  };

  const inviteUser = userRecord => {
    // unwrap user object
    return wrappedFetch(
      "/admin_user/invite_user",
      postJson({
        email: userRecord.email,
        unique_employee_id: userRecord.unique_employee_id,
        first_name: userRecord.first_name,
        last_name: userRecord.last_name,
        role_id: userRecord.role_id,
        is_email_auto_generated: userRecord.is_autogenerated_email,
      }),
    );
  };

  const assignUserToDevice = (imei, user_id) =>
    wrappedFetch("/user/assigndevice", postJson({ imei, user_id }));
  const activateUser = hash =>
    fetch(`${serverUrlBase}/activate/${hash}/`).then(res => {
      if (!res.ok) {
        throw new Error("http error " + res.status + ": " + res.statusText);
      }
      return res.json();
    });

  // Delete user
  const deleteUserLocal = () => new Promise((resolve, reject) => resolve({}));
  const deleteUserApi = userRecords => {
    // unwrap user object
    let userRecord = userRecords[0].user;
    return wrappedFetch("/admin_user/user/" + userRecord.id, deleteJson({}));
  };
  const deleteUser = deleteUserApi;

  const uploadUsers = file => {
    return wrappedFetch("/admin_user/upload_user_list", postCSV(file));
  };
  return {
    inviteUser,
    activateUser,
    deleteUser,
    assignUserToDevice,
    getUserList,
    getUserListFiltered,
    getUserListAll,
    getUserListByRole,
    signUpUser,
    updateUser,
    uploadUsers,
    generateEmailAddress,
  };
}
