import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { RoleCreateInline } from "./RoleCreateInline";
import { isEmpty } from "../../utility";
import { TextInput } from "../../TextInput";
import { QueryLine } from "../../tools/QueryLine";

const terms = defineMessages({
  role_name_label: {
    id: "adminPanel.roles.create.inputLabel.role_name",
    defaultMessage: "Name",
  },
  text_search_placeholder: {
    id: "adminPanel.roles.create.placeholder.searchField",
    defaultMessage: "Funktionsname",
  },
});

/**
 * Opens the dialog to create a new role in an overlay.
 */
class RoleCreateOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      selectedPermissions: [],
      role: {},
      query: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getPermissionList().then(recordList => {
      this.setState({
        loading: false,
        recordList,
      });
    });
  }

  submit() {
    if (this.validate()) {
      this.props
        .createRole(
          this.state.createRoleName,
          this.state.selectedPermissions.map(
            permission => permission.permission_code,
          ),
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return !isEmpty(this.state.createRoleName);
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  getFilteredFunctions = functions => {
    return this.state.recordList.reduce((acc, permissionRecord) => {
      // show only functions matching the search query
      const stringFiltering =
        this.state.query.length === 0 ||
        permissionRecord.permission_desc
          .toLowerCase()
          .search(this.state.query.toLowerCase()) > -1 ||
        permissionRecord.printable_name
          .toLowerCase()
          .search(this.state.query.toLowerCase()) > -1;

      if (!stringFiltering) {
        return acc;
      }

      return [...acc, permissionRecord];
    }, []);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  /**
   * Select a functionality from the list
   * @param record
   */
  selectRecord = record => {
    // only select functionalities that are not selected yet
    if (
      !this.state.selectedPermissions.some(
        selectedPermission =>
          record.permission_code === selectedPermission.permission_code,
      )
    ) {
      let selectedPermissions = [...this.state.selectedPermissions, record];
      this.setState({ selectedPermissions });
    }
  };

  /**
   * Deselect a functionality
   * @param record
   */
  deselectRecord = record => {
    this.setState({
      selectedPermissions: this.state.selectedPermissions.filter(
        currentRecord =>
          currentRecord.permission_code !== record.permission_code,
      ),
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className={"header"}>
          <h3>
            <T
              id="adminPanel.roles.create.headline"
              defaultMessage="Neue Rolle erstellen"
            />
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div>
          <div className="small-spacer" />
          <TextInput
            id="createRoleName"
            name={t(terms.role_name_label)}
            className={this.state.showErrorMessage ? "error" : ""}
            onChange={this.handleChange}
            value={this.state.role.role_name}
          />
          {this.state.showErrorMessage && (
            <div className="error">
              <T
                id="adminPanel.roles.create.errorMessage.name_empty"
                defaultMessage="Bitte geben Sie einen Namen an."
              />
            </div>
          )}
          <div className="small-spacer" />
          <QueryLine
            changeCallback={this.setSearchFilter}
            placeholder={t(terms.text_search_placeholder)}
          />
          <div className="small-spacer" />

          <div className="admin-list table-scroll outer-table-frame text-left grid-container">
            <div className="table-frame text-center">
              {this.state.recordList &&
                this.getFilteredFunctions().map((record, index) => (
                  <div
                    className="table-line grid-x grid-padding-x align-middle"
                    key={index}
                  >
                    <div className={"cell shrink auto flex-container"}>
                      <i
                        className={
                          "fa checkbox " +
                          (this.state.selectedPermissions.some(
                            selectedPermission =>
                              record.permission_code ===
                              selectedPermission.permission_code,
                          )
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        onClick={
                          this.state.selectedPermissions.some(
                            selectedPermission =>
                              record.permission_code ===
                              selectedPermission.permission_code,
                          )
                            ? () => this.deselectRecord(record)
                            : () => this.selectRecord(record)
                        }
                      />
                    </div>
                    <div className="cell auto bold text-left">
                      {record.printable_name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="small-spacer" />
        </div>
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.roles.edit.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.roles.edit.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const RoleCreateOverlay = injectIntl(RoleCreateOverlayClass);
