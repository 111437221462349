import { Portal } from "./Portal";

/**
 * Permission name constants. These are the values as received by the API
 * @type {{UserDeviceManagement: string, RoleManagement: string, DeviceSettings: string, Fahrtenbuch: string, Dashboard: string, DeviceList: string, SafetyZone: string, Location: string}}
 */
export const Permission = {
  UserDeviceManagement: "user_device_management",
  RoleManagement: "role_management",
  Dashboard: "dashboard",
  Location: "location",
  DeviceList: "device_list",
  SafetyZone: "safety_zone",
  RouteList: "route_list",
  RfidManagement: "rfid_management",
  CustomerAddress: "customer_address",
  ServiceInterval: "device_service_interval",
};

/**
 * Checks if a user has a specfic permission
 *
 * @param user Object as received from the API during login process
 * @param permission string as defined in the Permission constant
 * @returns {*}
 */
export const hasPermission = (user, permission) => {
  return user.permissions.includes(permission);
};

/**
 * Checks if a user has access to a certain portal (e.g. "Car & Van")
 *
 * @param user Object as received from the API during login process
 * @param portal string as defined in Portal constant
 * @returns {boolean|*}
 */
export const hasPortalAccess = (user, portal) => {
  switch (portal) {
    case Portal.All:
      return hasPermission(user, Permission.Location);
    case Portal.Auto:
      return user.availablePortals.includes(Portal.Auto);
    case Portal.Vehicle:
      return user.availablePortals.includes(Portal.Vehicle);
    case Portal.Asset:
      return user.availablePortals.includes(Portal.Asset);
    case Portal.Person:
      return user.availablePortals.includes(Portal.Person);
    case Portal.AdminPanelRfidChipList:
      return hasPermission(user, Permission.RfidManagement);
    case Portal.AdminPanelCustomerAddress:
      return hasPermission(user, Permission.CustomerAddress);
    case Portal.AdminPanelServiceInterval:
      return hasPermission(user, Permission.ServiceInterval);
    case Portal.AdminPanelDevice:
    case Portal.AdminPanelUserGroup:
    case Portal.AdminPanelUser:
      return hasPermission(user, Permission.UserDeviceManagement);
    case Portal.AdminPanelRole:
      return hasPermission(user, Permission.RoleManagement);
    default:
      return false;
  }
};
