import React from "react";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
import DatePicker from "react-datepicker";
import moment from "moment";
import { dateFormat } from "../dateFormat";
import {
  getAWholeDaysPeriod,
  formatMomentPeriodToStringPeriod,
} from "../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { defaultLocale } from "../Language";
import { TimePeriodOverlay } from "../TimePeriodOverlay.jsx";
import { Portal } from "../Portal";
import Link from "react-router-dom/es/Link";

const terms = defineMessages({
  hours: {
    id: "tracar.map.route.time-hours",
    defaultMessage: "Uhr",
    label: "Uhr",
  },
  time_to: {
    id: "tracar.map.route.time_to",
    defaultMessage: " bis ",
    label: "bis",
  },
});

export class CustomDatePickerInput extends React.Component {
  componentWillUnmount() {}
  render() {
    return (
      <div
        className={
          "button single-day-picker " +
          (this.props.active ? " active " : "") +
          this.props.className
        }
        onClick={this.props.onClick}
      >
        {this.props.value}
      </div>
    );
  }
}

class MapClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /// selectedDate is the single day selected by the date picker
      selectedDate: moment(),
      /// timePeriod is the time period selected via overlay,
      /// {from: Moment, to: Moment}?
      timePeriod: null,
      timeShown: "live",
      date: {},
      showPeriodSelectionOverlay: false,
      fitToBounds: true,
    };
  }

  setTimeShown = (time, date = {}) => {
    this.setState({ timeShown: time, date });
  };

  setHoursShown = hours => event => this.setTimeShown(hours);

  setTimePeriodCallback = timePeriod => {
    this.setState({ timePeriod });
    this.setTimeShown("period", formatMomentPeriodToStringPeriod(timePeriod));
  };

  togglePeriodSelectionOverlay = () =>
    this.setState({
      showPeriodSelectionOverlay: !this.state.showPeriodSelectionOverlay,
    });

  /// TODO ponder set as component
  handleDatePickerChange = date => {
    this.setState({ selectedDate: date });
    this.setTimeShown("whole_day", getAWholeDaysPeriod(date));
  };

  loadCustomerAddressList = () => {
    if (this.state.showCustomerAddresses) {
      this.setState({
        showCustomerAddresses: false,
      });
    } else {
      this.setState({ customerAddressListLoading: true });
      this.setState({
        showCustomerAddresses: true,
      });
      this.props.getPaginatedCustomerAddresses(0, 1000).then(res => {
        this.setState({
          customerAddressList: res.cust_addr,
          customerAddressListLoading: false,
          fitToBounds: true,
        });
      });
    }
  };

  updateFitToBounds = status => {
    this.setState({ fitToBounds: status });
  };

  render() {
    const t = this.props.intl.formatMessage;
    const portal = this.props.match.params.portal || "";
    return (
      <div>
        {this.props.isEmpty && (
          <div className="on-map-box">
            <T
              id="tracar.map.pleaseAskForDeviceAssignment"
              defaultMessage="Ihnen wurde noch kein Gerät zugewiesen. Bitte kontaktieren Sie Ihren Systemadministrator."
            />
          </div>
        )}
        {portal !== "" && !this.props.isEmpty && (
          <div className="displayed-time">
            <div
              className={`button ${this.state.timeShown === "live" &&
                "active"}`}
              onClick={this.setHoursShown("live")}
            >
              <T id="tracar.map.live" defaultMessage="Live" />
            </div>
            {portal === Portal.All ? (
              <React.Fragment />
            ) : (
              <React.Fragment>
                <div
                  className={`button ${this.state.timeShown === "6" &&
                    "active"}`}
                  onClick={this.setHoursShown("6")}
                >
                  <T id="tracar.map.6h" defaultMessage="6 Stunden" />
                </div>
                <div
                  className={`button ${this.state.timeShown === "12" &&
                    "active"}`}
                  onClick={this.setHoursShown("12")}
                >
                  <T id="tracar.map.12h" defaultMessage="12 Stunden" />
                </div>
                <div
                  className={`button ${this.state.timeShown === "24" &&
                    "active"}`}
                  onClick={this.setHoursShown("24")}
                >
                  <T id="tracar.map.24h" defaultMessage="24 Stunden" />
                </div>
                <DatePicker
                  allowSameDay={true}
                  customInput={
                    <CustomDatePickerInput
                      active={this.state.timeShown === "whole_day"}
                    />
                  }
                  dateFormat={dateFormat}
                  locale={moment().locale() || defaultLocale}
                  maxDate={moment()}
                  onChange={this.handleDatePickerChange}
                  popperPlacement="top"
                  selected={this.state.selectedDate}
                />
                <div
                  className={`button ${this.state.timeShown === "period" &&
                    "active"}`}
                  onClick={this.togglePeriodSelectionOverlay}
                >
                  {this.state.timePeriod == null ? (
                    <T id="tracar.map.route" defaultMessage="Route" />
                  ) : (
                    <span>
                      {/// Check if from and to are the same day, if so
                      /// show the date only once in the last
                      this.state.timePeriod.from.isSame(
                        this.state.timePeriod.to,
                        "day",
                      )
                        ? this.state.timePeriod.from.format(
                            `HH:mm:ss [${t(terms.hours)}]`,
                          )
                        : this.state.timePeriod.from.format(
                            `HH:mm:ss [${t(terms.hours)}] DD.MM.YYYY`,
                          )}
                      {t(terms.time_to)}
                      {this.state.timePeriod.to.format(
                        `HH:mm:ss [${t(terms.hours)}] DD.MM.YYYY`,
                      )}
                    </span>
                  )}
                </div>
              </React.Fragment>
            )}
            <span className="map-buttons-right-container">
              {/* TODO: tooltip needed */}
              {this.props.imei === "multiple" && (
                <Link
                  to={{
                    pathname: "/" + this.props.portal + "/device-list",
                    state: {
                      selectedTags: this.props.selectedTags,
                      selectedGeofences: this.props.selectedGeofences,
                    },
                  }}
                  className="button"
                >
                  <i className="icon-undo" />
                </Link>
              )}
              <span
                className={`button ${this.state.showCustomerAddresses &&
                  "active"}`}
                onClick={this.loadCustomerAddressList}
              >
                <i className="icon-home" aria-hidden="true" />
              </span>
            </span>
          </div>
        )}
        <GoogleMapWrapper
          isEmpty={this.props.isEmpty}
          containerElement={<div className="map-container" />}
          date={this.state.date}
          devices={this.props.devices}
          mapElement={<div style={{ height: `70vh` }} />}
          portal={this.props.portal}
          timeShown={this.state.timeShown}
          customerAddressList={{
            addresses: this.state.customerAddressList,
            show: this.state.showCustomerAddresses,
            isLoading: this.state.customerAddressListLoading,
            fitToBounds: this.state.fitToBounds,
          }}
          updateFitToBounds={this.updateFitToBounds}
          fetchAddress={this.props.fetchAddress}
          mapType={"map"}
          getTrackerList={this.props.getTrackerList}
          getLocationForSingleTracker={this.props.getLocationForSingleTracker}
          getCompleteLocationHistory={this.props.getCompleteLocationHistory}
          getSingleTrackerLocationHistory={
            this.props.getSingleTrackerLocationHistory
          }
          imei={this.props.imei}
          location={this.props.location}
        />
        {portal === Portal.All ? (
          <div style={{ marginTop: "15px" }}>
            {this.props.availablePortals.map(
              portal =>
                portal !== Portal.All && (
                  <span className="legend" key={portal}>
                    <img src={`/pins/${portal}/pins-red/Element 1.png`} />
                    <span>{portal}</span>
                  </span>
                ),
            )}
          </div>
        ) : (
          <React.Fragment />
        )}
        {this.state.showPeriodSelectionOverlay && (
          <TimePeriodOverlay
            closeFunction={this.togglePeriodSelectionOverlay}
            setTimePeriodCallback={this.setTimePeriodCallback}
            period={this.state.timePeriod}
            /// one day in ms
            maxTimeInMs={24 * 60 * 60 * 1000}
          >
            <h3>
              <i className="fa fa-clock-o" />
              &nbsp;&nbsp;&nbsp; Zeitraum festlegen
            </h3>
            <div>(maximal 24 Stunden)</div>
            <div className="small-spacer" />
          </TimePeriodOverlay>
        )}
      </div>
    );
  }
}

export const Map = injectIntl(MapClass);
