import React from "react";
import { UserLine } from "./UserLine";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import ReactTooltip from "react-tooltip";

import { UserConsumer } from "../../Base";
import { QueryLine } from "../../tools/QueryLine";
import { PaginationBar } from "../PaginationBar";
import { DropdownItems, DropdownMenu } from "../../tools/DropdownMenu";
import {
  searchTypeUserGroup,
  searchTypeUserData,
  searchTypeRole,
} from "./UserBase";
const selectAllModeSelect = "select_all";
const selectAllModeDeselect = "deselect_all";

const terms = defineMessages({
  searchTypeUserData: {
    id: "adminPanel.users.list.searchTypeUserData",
    defaultMessage: "Benutzerdaten",
  },
  searchTypeUserGroup: {
    id: "adminPanel.users.list.searchTypeUserGroup",
    defaultMessage: "Benutzergruppe",
  },
  searchTypeRole: {
    id: "adminPanel.users.list.searchTypeRole",
    defaultMessage: "Rolle",
  },
});

export class UserListClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // keeps track of the current state and function of the "select all" button
      selectAllMode: selectAllModeSelect,
      // current list of user records
      filteredUserList: props.recordList,
      // current search term used to filter the user list
      query: "",
      warnings: !!props.recordList.find(({ dlc }) => dlc && dlc.warned),
    };
  }

  /**
   * Handles a click on the "select all" button
   * Switches the button between "select all" and "deselect all" mode
   */
  handleSelectAllClick = () => {
    if (this.state.selectAllMode === selectAllModeSelect) {
      this.props.selectAllRecords();
      this.setState({ selectAllMode: selectAllModeDeselect });
    } else {
      this.props.deselectAllRecords();
      this.setState({ selectAllMode: selectAllModeSelect });
    }
  };

  /**
   * Handles change of the dropdown filter control.
   * Updates list via filter function in class props
   * @param event
   */
  handleFilterChange = event => {
    switch (event.target.value) {
      case "all": {
        this.props.updateListFilterAll();
        break;
      }
      case "is_active": {
        this.props.updateListFilterIsActive(true);
        break;
      }
      case "is_inactive": {
        this.props.updateListFilterIsActive(false);
        break;
      }
      case "is_autogenerated": {
        this.props.updateListFilterIsAutogenerated(true);
        break;
      }
      case "is_not_autogenerated": {
        this.props.updateListFilterIsAutogenerated(false);
        break;
      }
    }
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <UserConsumer>
        {user => (
          <div className="admin-list outer-table-frame">
            <div className="grid-x grid-margin-x align-middle search-area">
              <div className="auto cell relative">
                <div className="grid-x query-filter-line">
                  <QueryLine
                    changeCallback={this.props.updateListSearchKey}
                    changeDelay={500}
                    placeholder="Suchbegriff"
                    class="left-icon auto cell"
                  />
                  <div className="query-filter shrink cell">
                    {(() => {
                      switch (this.props.currentListSearchType) {
                        case searchTypeUserData:
                          return t(terms.searchTypeUserData);
                        case searchTypeRole:
                          return t(terms.searchTypeRole);
                        case searchTypeUserGroup:
                          return t(terms.searchTypeUserGroup);
                        default:
                          return null;
                      }
                    })()}
                    <DropdownMenu anchor={<div className="arrow-down" />}>
                      <DropdownItems>
                        {
                          <li
                            onClick={() =>
                              this.props.updateListSearchType(
                                searchTypeUserData,
                              )
                            }
                          >
                            <T
                              id="adminPanel.users.list.searchTypeUserData"
                              defaultMessage="Benutzerdaten"
                            />
                          </li>
                        }
                        {
                          <li
                            onClick={() =>
                              this.props.updateListSearchType(searchTypeRole)
                            }
                          >
                            <T
                              id="adminPanel.users.list.searchTypeRole"
                              defaultMessage="Rolle"
                            />
                          </li>
                        }
                        {
                          // temporarily disabled
                          /*<li
                            onClick={() =>
                              this.props.updateListSearchType(
                                searchTypeUserGroup,
                              )
                            }
                          >
                            <T
                              id="adminPanel.users.list.searchTypeUserGroup"
                              defaultMessage="Benutzergruppe"
                            />
                          </li>*/
                        }
                      </DropdownItems>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="shrink cell">
                <div className="button-frame">
                  <div
                    className="button green primary"
                    onClick={this.props.toggleCsvUploadOverlay}
                  >
                    <T
                      id="adminPanel.users.list.labels.csvUploadButton"
                      defaultMessage="CSV Upload"
                    />
                  </div>
                </div>
              </div>
              <div className="shrink cell">
                <div className="button-frame">
                  <div
                    className="button green primary"
                    onClick={this.props.toggleCreateOverlay}
                  >
                    <T
                      id="adminPanel.users.list.labels.createUserButton"
                      defaultMessage="Neuen Nutzer anlegen"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-x grid-margin-x align-middle margin-bottom-1">
              <div className="large-shrink cell">
                <div className="action-list">
                  <div
                    className="button transparent"
                    onClick={this.handleSelectAllClick}
                  >
                    {this.state.selectAllMode == selectAllModeSelect ? (
                      <T
                        id="adminPanel.roles.assignUser.buttonLabel.selectAllUsersButton"
                        defaultMessage="Alle auswählen"
                      />
                    ) : (
                      <T
                        id="adminPanel.roles.assignUser.buttonLabel.deselectAllUsersButton"
                        defaultMessage="Alle abwählen"
                      />
                    )}
                  </div>
                  <div className="bold">
                    <T
                      id="adminPanel.users.list.headline.action"
                      defaultMessage="Aktion"
                    />{" "}
                    ->
                  </div>
                  <div
                    className={
                      this.props.selectedRecordList.length > 0
                        ? "button transparent action"
                        : "button transparent action disabled"
                    }
                    onClick={this.props.toggleAssignRoleMultipleOverlay}
                  >
                    <T
                      id="adminPanel.users.list.buttonLabel.assignRoleMultiple"
                      defaultMessage="Rolle zuweisen"
                    />
                  </div>
                  <div className="counter">
                    {this.props.selectedRecordList.length}
                  </div>
                </div>
              </div>
              <div className="shrink cell">
                <p className="bold margin-0">Liste filtern</p>
              </div>
              <div className="auto cell">
                <select className="margin-0" onChange={this.handleFilterChange}>
                  <option value="all">
                    <T
                      id="adminPanel.users.list.optionLabel.showAllUsers"
                      defaultMessage="Alle Benutzer"
                    />
                  </option>
                  <option value="is_active">
                    <T
                      id="adminPanel.users.list.optionLabel.showActiveUsers"
                      defaultMessage="Aktive Benutzer"
                    />
                  </option>
                  <option value="is_inactive">
                    <T
                      id="adminPanel.users.list.optionLabel.showInactiveUsers"
                      defaultMessage="Inaktive Benutzer"
                    />
                  </option>
                  <option value="is_autogenerated">
                    <T
                      id="adminPanel.users.list.optionLabel.showAutogeneratedUsers"
                      defaultMessage="Benutzer mit automatisch generierter E-Mail Adresse"
                    />
                  </option>
                  <option value="is_not_autogenerated">
                    <T
                      id="adminPanel.users.list.optionLabel.showNotAutogeneratedUsers"
                      defaultMessage="Benutzer ohne automatisch generierter E-Mail Adresse"
                    />
                  </option>
                </select>
              </div>
            </div>

            {this.props.inviteMessage && (
              <div className={this.props.inviteMessage.className}>
                {this.props.inviteMessage.text}
                <div className="small-spacer" />
              </div>
            )}
            <div className="table-frame">
              <div className="table-header">
                <i className="fa fa-user" />
                <T
                  id="tracar.users.users"
                  defaultMessage="Angemeldete Benutzer"
                />
                <span className="item-count">{this.props.numberOfUsers}</span>
              </div>
              {this.props.recordList.map(currentUser => {
                return (
                  <UserLine
                    key={currentUser.user.id}
                    currentUser={currentUser}
                    toggleEditOverlay={this.props.toggleEditOverlay}
                    toggleDeleteOverlay={this.props.toggleDeleteOverlay}
                    toggleAssignDeviceOverlay={
                      this.props.toggleAssignDeviceOverlay
                    }
                    toggleAssignRoleOverlay={this.props.toggleAssignRoleOverlay}
                    toggleAssignUserGroupOverlay={
                      this.props.toggleAssignUserGroupOverlay
                    }
                    is_selected={this.props.selectedRecordList.some(
                      user => currentUser.user.id == user.user.id,
                    )}
                    selectRecord={this.props.selectRecord}
                    deselectRecord={this.props.deselectRecord}
                    showButtonInvite={true}
                    showButtonRoleAssignment={true}
                    showButtonDelete={true}
                    showButtonEdit={true}
                    showButtonGroupsAssignment={false}
                    showButtonDeviceAssignment={false}
                  />
                );
              })}
            </div>
            <PaginationBar
              currentPage={this.props.currentPage}
              numberOfPages={this.props.numberOfPages}
              updateCurrentPage={this.props.updatePagination}
            />
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`non-company-user-line`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }
}

export const UserList = injectIntl(UserListClass);
