import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../../TextInput";
import DatePicker from "react-datepicker";
import moment from "moment";
import { defaultLocale } from "../../Language";
import { CustomDatePickerInput } from "../../Map/Map";
import {
  ServiceIntervalNew,
  ServiceIntervalEdit,
  MultipleServiceInterval,
} from "../../ServiceInterval";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";

// Translations
const terms = defineMessages({
  header_new_title: {
    id: "service.interval.new.header",
    defaultMessage: "Serviceintervall anlegen",
  },
  header_edit_title: {
    id: "service.interval.edit.header",
    defaultMessage: "Serviceintervall bearbeiten",
  },
  device_label: {
    id: "service.interval.new.device.label",
    defaultMessage: "Gerät",
  },
  type_name_label: {
    id: "service.interval.new.type.label",
    defaultMessage: "Service Typ angeben",
  },
  type_description_label: {
    id: "service.interval.new.description.label",
    defaultMessage: "Service Beschreibung",
  },
  interval_date: {
    id: "service.interval.new.date.label",
    defaultMessage: "Nach Datum",
  },
  interval_threshold: {
    id: "service.interval.new.distance.label",
    defaultMessage: " ",
  },
  errors_upload_failed: {
    id: "service.interval.new",
    defaultMessage:
      "Fehler beim Erstellen des Intervalls. Bitte versuchen Sie es erneut.",
  },
  turnus_type_label: {
    id: "service.interval.turnus",
    defaultMessage: "Periode",
  },
  set_service_interval: {
    id: "service.interval.label.set",
    defaultMessage: "Service Intervall festlegen",
  },
  cancel: {
    id: "service.interval.label.cancel",
    defaultMessage: "Abbrechen",
  },
  save: {
    id: "service.interval.label.save",
    defaultMessage: "Speichern",
  },
});

class NewIntervalOverlayClass extends React.Component {
  handleChange;

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      devices: [],
      deviceImei: this.props.serviceInterval
        ? this.props.serviceInterval.imei
        : "",
      enumTypes: [],
      userEmail: "",
      serviceIntervalId: this.props.serviceInterval
        ? this.props.serviceInterval.service_interval_id
          ? this.props.serviceInterval.service_interval_id
          : ""
        : "",
      serviceType: this.props.serviceInterval
        ? this.props.serviceInterval.service_type_choices
          ? this.props.serviceInterval.service_type_choices
          : ""
        : "",
      typeDescription: this.props.serviceInterval
        ? this.props.serviceInterval.service
          ? this.props.serviceInterval.service
          : ""
        : "",
      thresholdType: this.props.serviceInterval
        ? this.props.serviceInterval.threshold_type_choices
          ? this.props.serviceInterval.threshold_type_choices
          : ""
        : "",
      turnusType: "once",
      turnusTypeValue: 1,
      showDateOption: true,
      showThresholdOption: this.props.serviceInterval
        ? this.props.serviceInterval.threshold
          ? true
          : false
        : "",
      plannedDates: [
        this.props.serviceInterval
          ? this.props.serviceInterval.planned_service_date
            ? moment(this.props.serviceInterval.planned_service_date)
            : moment()
          : moment(),
      ],
      thresholds: [
        this.props.serviceInterval
          ? this.props.serviceInterval.threshold
            ? this.props.serviceInterval.threshold
            : 1000
          : 1000,
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    Promise.all([this.props.fetchDevices(), this.props.getEnumTypes()]).then(
      ([devices, enumTypes]) => {
        this.setState({
          devices,
          deviceImei:
            this.state.deviceImei === ""
              ? devices.devices[0].imei
              : this.state.deviceImei,
          enumTypes,
          userEmail: this.props.userEmail,
          serviceType:
            this.state.serviceType === ""
              ? enumTypes.service_types[0]
              : this.state.serviceType,
          thresholdType:
            this.state.thresholdType === ""
              ? enumTypes.threshold_types[0]
              : this.state.thresholdType,
          loading: false,
        });
      },
    );
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  handleChangePlannedDate = (index, date) => {
    let plannedDates = this.state.plannedDates;
    plannedDates[index] = date;

    this.setState({ plannedDates });
  };

  addPlannedDate = (count, andThreshold) => {
    const newPlannedDates = [];
    for (let i = 0; i < count; i++) {
      newPlannedDates.push(moment());
    }

    this.setState({
      plannedDates: [...this.state.plannedDates, ...newPlannedDates],
    });

    if (this.state.showThresholdOption && andThreshold) {
      this.addThreshold(1, false);
    }
  };

  removePlannedDate = (index, andThreshold) => {
    const plannedDates = this.state.plannedDates;
    plannedDates.splice(index, 1);
    this.setState({
      plannedDates,
    });

    if (this.state.showThresholdOption && andThreshold) {
      this.removeThreshold(index, false);
    }
  };

  handleChangeThreshold = (index, event) => {
    let thresholds = this.state.thresholds;
    thresholds[index] = parseInt(event.target.value) || thresholds[index];

    this.setState({ thresholds });
  };

  addThreshold = (count, andPlannedDate) => {
    const newThresholds = [];
    for (let i = 0; i < count; i++) {
      newThresholds.push(1000);
    }

    this.setState({
      thresholds: [...this.state.thresholds, ...newThresholds],
    });

    if (this.state.showDateOption && andPlannedDate) {
      this.addPlannedDate(1, false);
    }
  };

  removeThreshold = (index, andPlannedDate) => {
    const thresholds = this.state.thresholds;
    thresholds.splice(index, 1);
    this.setState({
      thresholds,
    });

    if (this.state.showDateOption && andPlannedDate) {
      this.removePlannedDate(index, false);
    }
  };

  toggleShowDateOption = () => {
    this.setState({ showDateOption: !this.state.showDateOption });

    if (!this.state.showDateOption && this.state.showThresholdOption) {
      if (this.state.plannedDates.length < this.state.thresholds.length) {
        this.addPlannedDate(
          this.state.thresholds.length - this.state.plannedDates.length,
          false,
        );
      } else {
        this.setState({
          plannedDates: this.state.plannedDates.slice(
            0,
            this.state.thresholds.length,
          ),
        });
      }
    }
  };

  toggleShowThresholdOption = () => {
    this.setState({ showThresholdOption: !this.state.showThresholdOption });

    if (!this.state.showThresholdOption && this.state.showDateOption) {
      if (this.state.thresholds.length < this.state.plannedDates.length) {
        this.addThreshold(
          this.state.plannedDates.length - this.state.thresholds.length,
          false,
        );
      } else {
        this.setState({
          thresholds: this.state.thresholds.slice(
            0,
            this.state.plannedDates.length,
          ),
        });
      }
    }
  };

  createServiceInterval = user => {
    this.setState({ uploading: true });
    if (
      this.state.plannedDates.length > 1 ||
      this.state.thresholds.length > 1
    ) {
      this.props
        .createMultipleServiceIntervals(
          new MultipleServiceInterval(
            this.state.serviceType,
            this.state.thresholdType,
            this.state.deviceImei,
            this.state.showThresholdOption ? this.state.thresholds : null,
            this.state.typeDescription,
            this.state.showDateOption ? this.state.plannedDates : null,
            user,
            false,
          ),
        )
        .then(res => {
          this.setState({ uploading: false });
          this.props.updateServiceIntervals();
          this.props.closeFunction();
        })
        .catch(error => {
          this.setState({ error, uploading: false });
        });
    } else {
      this.props.type === "new"
        ? this.props
            .createServiceInterval(
              new ServiceIntervalNew(
                this.state.serviceType,
                this.state.thresholdType,
                this.state.deviceImei,
                this.state.showThresholdOption
                  ? this.state.thresholds[0]
                  : null,
                this.state.typeDescription,
                this.state.showDateOption ? this.state.plannedDates[0] : null,
                user,
                false,
              ),
            )
            .then(res => {
              this.setState({ uploading: false });
              this.props.updateServiceIntervals();
              this.props.closeFunction();
            })
            .catch(error => {
              this.setState({ error, uploading: false });
            })
        : this.props
            .createServiceInterval(
              new ServiceIntervalEdit(
                this.state.serviceIntervalId,
                this.state.serviceType,
                this.state.thresholdType,
                this.state.deviceImei,
                this.state.showThresholdOption
                  ? this.state.thresholds[0]
                  : null,
                this.state.typeDescription,
                this.state.showDateOption ? this.state.plannedDates[0] : null,
                user,
                false,
              ),
            )
            .then(res => {
              this.setState({ uploading: false });
              this.props.updateServiceIntervals();
              this.props.closeFunction();
            })
            .catch(error => {
              this.setState({ error, uploading: false });
            });
    }
  };

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <div className="new-interval-modal">
        <RequestOverlay
          closeFunction={this.props.closeFunction}
          className="import-customer-addresses"
          enableCloseOnOverlayClicked={true}
        >
          {this.state.uploading
            ? this.renderLoadingCircle()
            : this.renderInterface()}

          {this.renderError()}
        </RequestOverlay>
      </div>
    );
  }

  /**
   * Renders the loading circle
   * @return LoadingCircle
   */
  renderLoadingCircle = () => {
    return <LoadingCircle />;
  };

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderInterface = () => {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelServiceInterval) && (
            <div>
              {this.renderHeader()}
              {this.renderBody()}
              {this.renderFooter(user.email)}
            </div>
          )
        }
      </UserConsumer>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="headline">
        <i className="icon-wrench" />
        {this.props.type === "new" ? (
          <div>{t(terms.header_new_title)}</div>
        ) : (
          <div>
            {t(terms.header_edit_title)}
            <i className="icon-long_arrow_right" />
            <span className="device-name">
              {this.props.serviceInterval.vehicle_model}
            </span>
          </div>
        )}
      </div>
    );
  };

  /**
   * Render the body
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <div className="grid-x grid-margin-x grid-margin-y margin-bottom-1">
          <div className="select-frame cell large-6">
            <i className="fa fa-sort-down angle" />
            <select
              className="select"
              id="deviceImei"
              value={this.state.deviceImei}
              onChange={this.handleChange}
              selected={this.state.device && this.state.device.name}
            >
              {this.state.devices.devices &&
                this.state.devices.devices.map(device => {
                  return (
                    <option value={device.imei} key={device.imei}>
                      {device.name}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="device_name_label">{t(terms.device_label)}</label>
          </div>
          <div className="select-frame cell large-6">
            <i className="fa fa-sort-down angle" />
            <select
              className="select"
              id="serviceType"
              value={this.state.serviceType}
              onChange={this.handleChange}
            >
              {this.state.enumTypes.service_types &&
                this.state.enumTypes.service_types.map(type => {
                  return (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="type_name_label">{t(terms.type_name_label)}</label>
          </div>
        </div>

        <div className="grid-x grid-margin-x grid-margin-y margin-bottom-1">
          <TextInput
            id="typeDescription"
            className="cell large-12"
            name={t(terms.type_description_label)}
            onChange={this.handleChange}
            value={this.state.typeDescription}
          />

          {/*<div className="select-frame cell large-6">
            <i className="fa fa-sort-down angle" />
            <select
              className="select"
              id="userEmail"
              value={this.state.userEmail}
              onChange={this.handleChange}
            >
              {this.state.users.user_data &&
                this.state.users.user_data.map(user => {
                  return (
                    <option value={user.user.email} key={user.user.email}>
                      {user.user.email}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="type_name_label">Benutzer</label>
              </div>*/}
        </div>

        <div className="interval-type margin-bottom-2 relative">
          {t(terms.set_service_interval)}
        </div>

        <form className="flex-just-between">
          <div className="radio">
            <div className="checkbox-container align-self-top">
              <div
                className="checkbox action"
                id="checbox_01"
                checked={false}
                onClick={() => this.toggleShowDateOption()}
              >
                {this.state.showDateOption ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                {t(terms.interval_date)}
              </div>
            </div>
            <div
              className={`${this.state.showDateOption ? "active" : "disabled"}`}
            >
              {this.state.plannedDates.map((plannedDate, index) => {
                return (
                  <div
                    className="flex-container align-middle margin-bottom-1 datepicker-wrap interval relative"
                    key={"date_" + index}
                  >
                    <DatePicker
                      customInput={<CustomDatePickerInput />}
                      locale={moment().locale() || defaultLocale}
                      onChange={date =>
                        this.handleChangePlannedDate(index, date)
                      }
                      popperPlacement="top-end"
                      selected={plannedDate}
                      selectsStart
                      showMonthDropdown
                      startDate={plannedDate}
                      withPortal
                    />
                    {this.state.plannedDates.length > 1 && (
                      <i
                        className="icon-admin-remove delete date"
                        onClick={() => this.removePlannedDate(index, true)}
                      />
                    )}
                    {index === this.state.plannedDates.length - 1 && (
                      <i
                        className="icon-admin-remove date"
                        onClick={() => this.addPlannedDate(1, true)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/*
          {this.state.showDateOption && this.state.showThresholdOption && (
            <div className="connector-container">
              {this.state.plannedDates.map(date => {
                return <div key={date} className="connector" />;
              })}
            </div>
          )}
          <div>
            <div className="select-frame cell large-4">
              <i className="fa fa-sort-down angle" />
              <select
                className="select"
                id="turnusType"
                value={this.state.turnusType}
                onChange={this.handleChange}
              >
                <option value="once" key="once">
                  Einmalig
                </option>
                <option value="turnus" key="turnus">
                  Periode
                </option>
              </select>
              <label htmlFor="type_turnus_type_label">
                {t(terms.turnus_type_label)}
              </label>
            </div>
            {this.state.turnusType === "turnus" && (
              <TextInput
                id="turnusTypeValue"
                className="cell large-2"
                onChange={this.handleChange}
                value={this.state.turnusTypeValue}
              />
            )}
          </div>
            */}
          <div className="radio">
            <div className="checkbox-container flex-just-around">
              <div
                className="checkbox action select-checkbox"
                id="checbox_01"
                checked={false}
                onClick={() => this.toggleShowThresholdOption()}
              >
                {this.state.showThresholdOption ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                {t(terms.interval_threshold)}
              </div>
              <div className="select-frame cell large-2">
                <i className="fa fa-sort-down angle" />
                <select
                  className="select"
                  id="thresholdType"
                  value={this.state.thresholdType}
                  onChange={this.handleChange}
                >
                  {this.state.enumTypes.threshold_types &&
                    this.state.enumTypes.threshold_types.map(type => {
                      return (
                        <option value={type} key={type}>
                          {type}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div
              className={`${
                this.state.showThresholdOption ? "active" : "disabled"
              } km-range`}
            >
              {this.state.thresholds.map((threshold, index) => {
                return (
                  <div
                    className="flex-container align-middle margin-bottom-1 interval relative"
                    key={"threshold_" + index}
                  >
                    {index === this.state.thresholds.length - 1 && (
                      <i
                        className="icon-admin-remove range"
                        onClick={() => this.addThreshold(1, true)}
                      />
                    )}
                    {this.state.thresholds.length > 1 && (
                      <i
                        className="icon-admin-remove delete range"
                        onClick={() => this.removeThreshold(index, true)}
                      />
                    )}
                    <TextInput
                      id="threshold"
                      className="cell large-4"
                      onChange={event =>
                        this.handleChangeThreshold(index, event)
                      }
                      value={threshold}
                    />
                    {/*<span className="km-unit">
                      {this.state.thresholdType === "Kilometer"
                        ? "km"
                        : this.state.thresholdType === "Betriebsstunden"
                        ? "h"
                        : ""}
                    </span>*/}
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </div>
    );
  };

  /**
   * Render the footer
   * @return JSX
   */
  renderFooter = user => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="upload-container footer-container">
        <div className="button-container align-right align-middle">
          <div className="cancel" onClick={this.props.closeFunction}>
            {t(terms.cancel)}
          </div>
          <div
            className="save button primary margin-0"
            onClick={() => this.createServiceInterval(user)}
          >
            {t(terms.save)}
          </div>
        </div>
      </div>
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return null;
    }

    return (
      <div className="error">
        <T
          id="customer.addresses.errors.uploadFailed"
          defaultMessage="Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut."
        />
      </div>
    );
  };
}

export const NewIntervalOverlay = injectIntl(NewIntervalOverlayClass);
