import React from "react";
import { FormattedMessage as T } from "react-intl";
import { SosOverlay } from "./SosOverlay";

/// TODO SF "Person"

export class SosDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSosOverlay: false,
    };
  }

  toggleOverlay = reload => {
    this.setState({ showSosOverlay: !this.state.showSosOverlay });

    if (reload === true) {
      this.props.reloadDashboard();
    }
  };

  isAnyPhoneNumberSet() {
    return (
      this.props.sosObj.ph1 ||
      this.props.sosObj.ph2 ||
      this.props.sosObj.ph3 ||
      this.props.sosObj.ph4
    );
  }

  render() {
    return (
      <div>
        <h5>
          <T id="tracar.device.emails" defaultMessage="Notfallnummern" />
        </h5>
        <p>
          <T
            id="inanni.sos.description"
            defaultMessage="Hier können Sie bis zu vier Notfallnummern eingeben. Diese werden im Notfall nacheinander - beginnend von der Ersten - angerufen."
          />
        </p>

        {this.isAnyPhoneNumberSet() ? (
          <div>
            <div className="grid-x grid-margin-x">
              <div className="medium-6 large-6 cell">
                1:{" "}
                {this.props.sosObj.ph1 || (
                  <T
                    id="inanni.sos.nonumber"
                    defaultMessage="Keine Notfallnummer eingetragen"
                  />
                )}
              </div>
              <div className="medium-6 large-6 cell">
                2:{" "}
                {this.props.sosObj.ph2 || (
                  <T
                    id="inanni.sos.nonumber"
                    defaultMessage="Keine Notfallnummer eingetragen"
                  />
                )}
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="medium-6 large-6 cell">
                3:{" "}
                {this.props.sosObj.ph3 || (
                  <T
                    id="inanni.sos.nonumber"
                    defaultMessage="Keine Notfallnummer eingetragen"
                  />
                )}
              </div>
              <div className="medium-6 large-6 cell">
                4:{" "}
                {this.props.sosObj.ph4 || (
                  <T
                    id="inanni.sos.nonumber"
                    defaultMessage="Keine Notfallnummer eingetragen"
                  />
                )}
              </div>
            </div>
            <div className="spacer" />
            {!this.props.sosObj.status ? (
              <p class="error">
                <T
                  id="inanni.sos.statusFalse"
                  defaultMessage="Hinweis: Die angegebenen Telefonnummern wurden noch nicht auf das Gerät übertragen."
                />
              </p>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="grid-x grid-margin-x">
            <div className="small-12 cell">
              <strong>
                <T
                  id="inanni.sos.nonumbers"
                  defaultMessage="Sie haben noch keine Notfallnummern eingetragen."
                />
              </strong>
            </div>
          </div>
        )}

        <div className="button pull-right" onClick={this.toggleOverlay}>
          <T
            id="inanni.sos.editNumbers"
            defaultMessage="Telefonnummern bearbeiten"
          />
        </div>
        {this.state.showSosOverlay ? (
          <SosOverlay
            closeFunction={this.toggleOverlay}
            imei={this.props.imei}
            sosObj={this.props.sosObj}
            setSos={this.props.setSos}
          />
        ) : (
          ""
        )}
        <div className="spacer" />
      </div>
    );
  }
}
