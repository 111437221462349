import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { RoleCreateOverlay } from "../Role/RoleCreateOverlay";
import { RoleCreateInline } from "../Role/RoleCreateInline";
import { UserAssignRoleInline } from "./UserAssignRoleInline";
import { UserAssignRoleOverlay } from "./UserAssignRoleOverlay";

class UserAssignRoleMultipleOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showCreateOverlay: false,
      showInnerCreateOverlay: false,
      assignRoleUserRecords: this.props.assignRoleUserRecords,
      roleRecordList: [],
      requestOverlayClassName: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getRoleListAll().then(roleRecordList => {
      this.setState({
        loading: false,
        roleRecordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    // this.props
    //   .editUser(this.props.editRoleRecord.id, this.state.editRoleName, this.state.selectedFunctions)
    //   .then(this.props.closeFunction);
    this.props.closeFunction();
  }

  toggleCreateOverlay = new_role_id => {
    this.setState({
      showCreateOverlay: !this.state.showCreateOverlay,
      assignRoleUserRole:
        new_role_id !== undefined ? new_role_id : this.state.assignRoleUserRole,
    });
  };

  toggleInnerCreateOverlay = new_role_id => {
    this.setState(
      {
        showInnerCreateOverlay: !this.state.showInnerCreateOverlay,
        requestOverlayClassName:
          this.state.requestOverlayClassName === ""
            ? "overlay-secondary-color"
            : "",
      },
      () => {
        this.props.getRoleListAll().then(roleRecordList => {
          this.setState({
            loading: false,
            roleRecordList,
          });
        });
      },
    );
  };

  deselectRecord = record => {
    this.setState({
      assignRoleUserRecords: this.state.assignRoleUserRecords.filter(
        currentRecord => currentRecord.user.id !== record.user.id,
      ),
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    let show_all = false;
    let limited_class = "";
    if (this.state.assignRoleUserRecords.length > 10) {
      show_all = true;
      limited_class = "limited";
    }
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.users.assignRole.headline"
              defaultMessage="Rolle zuweisen"
            />{" "}
            ->{" "}
            <span className="highlighted">
              <T
                id="adminPanel.users.assignRole.headlineMultipleSelected"
                defaultMessage="Mehrere Ausgewählte"
              />
            </span>
          </h3>
        </div>
        <div className="small-spacer" />
        <h4 className="text-left">
          <T
            id="adminPanel.users.assignRole.labelSelectedUser"
            defaultMessage="Ausgewählte Nutzer"
          />{" "}
          <span className="item-count">
            {this.state.assignRoleUserRecords.length}
          </span>
        </h4>
        {/*@todo Entfernen und hinzufügen der Benutzer zuer Liste"*/}
        <div className="grid-container">
          <div
            className={
              "grid-x grid-padding-x grid-padding-y relative list-horizontal " +
              limited_class
            }
          >
            {show_all && <div className="radiant-overlay" />}
            {this.state.assignRoleUserRecords &&
              this.state.assignRoleUserRecords.map((record, index) => (
                <div className="cell shrink" key={index}>
                  <div className="list-item flex-container align-justify">
                    <span>{record.user.email}</span>
                    <i
                      className="icon-remove_circle close-icon align-self-middle"
                      onClick={() => this.deselectRecord(record)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="small-spacer" />
        <UserAssignRoleInline
          closeFunction={this.props.closeFunction}
          assignRoleUserRecords={this.state.assignRoleUserRecords}
          assignUsersToRole={this.props.assignUsersToRole}
          getRoleListAll={this.props.getRoleListAll}
          createRole={this.props.createRole}
          getPermissionList={this.props.getPermissionList}
          toggleInnerCreateOverlay={this.toggleInnerCreateOverlay}
          showInnerCreateOverlay={this.state.showInnerCreateOverlay}
        />
      </RequestOverlay>
    );
  }
}

export const UserAssignRoleMultipleOverlay = injectIntl(
  UserAssignRoleMultipleOverlayClass,
);
