import React from "react";
import { NonCompanyUserLine } from "./NonCompanyUserLine";
import { FormattedMessage as T } from "react-intl";
import ReactTooltip from "react-tooltip";

import { UserConsumer } from "../Base";
import { QueryLine } from "../tools/QueryLine";

export class NonCompanyUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredUserList: props.nonCompanyUserList,
      fleetIdFilter: "all",
      fleetIdFunctionality: false,
      //   !!props.userLoginData.functionalities.find(
      //   f => f === Functionality.F5_DriversLicenseCheck
      // ),
      query: "",
      warnings: !!props.nonCompanyUserList.find(({ dlc }) => dlc && dlc.warned),
      //  !!props.userLoginData.dlc_warnings &&
      //  props.userLoginData.dlc_warnings.length > 0,
    };
  }

  setFleetIdFilter = fleetIdFilter => {
    this.setState({ fleetIdFilter });
  };

  setSearchFilter = query => {
    this.setState({ query });
  };

  filterUsers = () => {
    return this.props.nonCompanyUserList.filter(user => {
      let fleetIdFilterResult;
      switch (this.state.fleetIdFilter) {
        case "all":
          fleetIdFilterResult = true;
          break;
        case "justFleetId":
          fleetIdFilterResult = user.dlc && user.dlc.dlc_barcode;
          break;
        case "notFleetId":
          fleetIdFilterResult = !(user.dlc && user.dlc.dlc_barcode);
          break;
        case "warnings":
          fleetIdFilterResult = user.dlc && user.dlc.warned;
          break;
        default:
          throw new TypeError(
            `Unknown fleet id filter type: ${this.state.fleetIdFilter}`,
          );
      }

      let stringQueryResult = true;
      if (this.state.query.length >= 1) {
        stringQueryResult =
          user.email.search(this.state.query) > -1 ||
          user.first_name.search(this.state.query) > -1 ||
          user.last_name.search(this.state.query) > -1 ||
          (user.dlc &&
            user.dlc.dlc_barcode &&
            user.dlc.dlc_barcode.search(this.state.query) > -1);
      }

      return fleetIdFilterResult && stringQueryResult;
    });
  };

  render() {
    return (
      <UserConsumer>
        {user => (
          <div className="outer-table-frame">
            <div className="button-frame">
              <div className="button" onClick={this.props.toggleInviteOverlay}>
                <T
                  id="tracar.users.invite"
                  defaultMessage="Neuen Nutzer einladen"
                />
              </div>
            </div>

            <div className="button-frame">
              {/* TODO: re-enable with correct permission
              {user.normalizedFunctionalities.basic.drivers_license_check && (
                <div>
                  <div
                    className={
                      "button " +
                      (this.state.fleetIdFilter === "all" ? " active" : "")
                    }
                    onClick={() => this.setFleetIdFilter("all")}
                  >
                    Alle Nutzer
                  </div>
                  <div
                    className={
                      "button " +
                      (this.state.fleetIdFilter === "justFleetId"
                        ? " active"
                        : "")
                    }
                    onClick={() => this.setFleetIdFilter("justFleetId")}
                  >
                    Nutzer <strong>mit</strong> fleet iD
                  </div>
                  <div
                    className={
                      "button " +
                      (this.state.fleetIdFilter === "notFleetId"
                        ? " active"
                        : "")
                    }
                    onClick={() => this.setFleetIdFilter("notFleetId")}
                  >
                    Nutzer <strong>ohne</strong> fleet iD
                  </div>
                  <div
                    className={
                      "button " +
                      (this.state.fleetIdFilter === "warnings"
                        ? " active "
                        : "") +
                      (this.state.warnings ? "" : " disabled ")
                    }
                    onClick={() =>
                      this.state.warnings && this.setFleetIdFilter("warnings")
                    }
                  >
                    <i className="fa fa-exclamation-triangle" />
                    &nbsp;&nbsp;&nbsp;Überfällige Führerscheinprüfungen
                  </div>
                </div>
              )}*/}
              <QueryLine changeCallback={this.setSearchFilter} />
            </div>

            {this.props.inviteMessage && (
              <div className={this.props.inviteMessage.className}>
                {this.props.inviteMessage.text}
                <div className="small-spacer" />
              </div>
            )}
            <div className="table-frame">
              <div className="table-header">
                <i className="fa fa-users" />
                <T id="tracar.users.users" defaultMessage="Nutzer" />
              </div>
              {this.filterUsers().map(nonCompanyUser => (
                <NonCompanyUserLine
                  key={nonCompanyUser.id}
                  nonCompanyUser={nonCompanyUser}
                  toggleAssignFleetIdOverlay={
                    this.props.toggleAssignFleetIdOverlay
                  }
                  user={user}
                />
              ))}
            </div>
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`non-company-user-line`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }
}
