import React from "react";
import Link from "react-router-dom/es/Link";
import { defineMessages, injectIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Portal, PortalDefault } from "./Portal";
import LinkButton from "./LinkButton";
import { UserConsumer } from "./Base.jsx";
import FormattedMessageFixed from "./tools/FormattedMessageFixed";
import { hasPortalAccess } from "./Permission";

const terms = defineMessages({
  map: {
    id: "base.map",
    defaultMessage: "Kartenansicht",
  },
  deviceList: {
    id: "base.deviceList",
    defaultMessage: "Geräteliste",
  },
  geofences: {
    id: "base.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  userList: {
    id: "base.userList",
    defaultMessage: "Nutzerliste",
  },
  dashboard: {
    id: "base.dashboard",
    defaultMessage: "Dashboard",
  },
  customerAddresses: {
    id: "base.customerAddresses",
    defaultMessage: "Kundenadressen",
  },
  rfid: {
    id: "base.rfid",
    defaultMessage: "RFID",
  },
});

class AdminPortalSelectionClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const t = this.props.intl.formatMessage;
    const portal = (this.props.match && this.props.match.params.portal) || "";
    var destination = "map";
    return (
      <UserConsumer>
        {user => {
          return (
            <div className="tracker-type-tabs">
              <div className="tabs-inner-wrapper">
                <div style={{ display: "inline-block" }}>
                  {user.availableAdminPortals.map(portalShown => {
                    {
                      portalShown === Portal.All
                        ? (destination = "map")
                        : (destination = "list");
                    }

                    if (portalShown === PortalDefault) {
                      return null;
                    }

                    if (!hasPortalAccess(user, portalShown)) {
                      return null;
                    }

                    return (
                      <div
                        className={`tab ${
                          portal === portalShown ? "active" : ""
                        }`}
                        key={portalShown}
                      >
                        <Link
                          to={`/admin-panel/${portalShown}/${destination}`}
                          className="tab-label"
                        >
                          <FormattedMessageFixed
                            id={`adminPanel.portal.${portalShown}`}
                            defaultMessage={`Portal ${portalShown}`}
                          />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }}
      </UserConsumer>
    );
  }
}

export const AdminPortalSelection = injectIntl(AdminPortalSelectionClass);
