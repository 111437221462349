import { postJson } from "./fetchService";

const devicesRoute = "/user/v2/devices";
const assignUserRoute = "/user/assigndevice";
const assignUsersRoute = "assign_devices";
const getAssignedUsersRoute = "assigned_users";
const serverUrlAdminUser = "/admin_user";

export default function fetchDevices(wrappedFetch) {
  const getDeviceList = async () => {
    return wrappedFetch(devicesRoute);
    //return dummyData;
  };

  const getDeviceListFiltered = (search_key, filter_type, offset, limit) => {
    return wrappedFetch(
      `${serverUrlAdminUser}/list_devices`,
      postJson({
        search_key: search_key,
        device_filter_type: filter_type,
        offset: offset,
        limit: limit,
      }),
    );
  };

  const assignUserToDevice = (imei, user_id) => {
    wrappedFetch(assignUserRoute, postJson({ imei, user_id }));
  };

  /**
   * Argument "users" needs to be the following format:
   * [{
      "user_id": 4,
      "assignment_type": "READ"
      }]
   * @param imei
   * @param users
   */
  const assignUsersToDevice = (imei, users) =>
    wrappedFetch(
      `${serverUrlAdminUser}/${assignUsersRoute}/${imei}`,
      postJson({ users }),
    );

  const getAssignedUsersForDevice = (
    imei,
    offset,
    limit,
    search_key,
    search_type,
    filter,
    is_autogenerated,
    is_active,
  ) =>
    wrappedFetch(
      `${serverUrlAdminUser}/${getAssignedUsersRoute}/${imei}`,
      postJson({
        offset,
        limit,
        search_key,
        search_type,
        filter,
        is_autogenerated,
        is_active,
      }),
    );

  return {
    getDeviceList,
    getDeviceListFiltered,
    assignUserToDevice,
    assignUsersToDevice,
    getAssignedUsersForDevice,
  };
}

const dummyData = {
  devices: [
    {
      imei: "353161077197062",
      name: "test device 123",
      description: "Test description.",
      color: "#F1C700",
      voltage: 4208,
      registered_on: null,
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 46,
      device_category: { description: "auto" },
      car_details: {
        car_number: "schild123",
        model: "marke123",
        driver: "fahrer123",
        current_car: true,
        device_odom_curr: 123.0,
        classify_routes: false,
      },
      users: [
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 89,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 189,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 13,
          registered_on: "2018-01-18",
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 110,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 289,
          registered_on: "2018-01-18",
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 23,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 210,
          registered_on: "2018-01-18",
        },
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 389,
          registered_on: "2018-01-18",
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 33,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 310,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 489,
          registered_on: "2018-01-18",
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 43,
          registered_on: "2018-01-18",
          latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 410,
          registered_on: "2018-01-18",
        },
      ],
      tags: [45, 47, 46],
      latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      is_online: false,
      assigned_geofences: [107, 162, 159, 166],
    },
    {
      imei: "359316075756335",
      name: "iNanny Test device",
      description: "Herr Nayak",
      color: "#E60000",
      voltage: 4200,
      registered_on: "2018-01-18",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: {
        car_number: "",
        model: "",
        driver: "",
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T14:04:11Z"),
      is_online: false,
      assigned_geofences: [166],
    },
    {
      imei: "1002",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 63000,
      registered_on: "2018-09-12",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: true,
      unread_messages: 326,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: "sa sb 34",
        model: "POINTER",
        driver: "",
        current_car: true,
        device_odom_curr: 234.0,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2020-01-14T06:03:37.788000Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1001",
      name: "Demo Tracar Device",
      description: "Mr. John Doe",
      color: "#B6A99B",
      voltage: 6000,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 121,
      device_category: { description: "auto" },
      car_details: {
        car_number: "RK 123",
        model: "Range rover",
        driver: "Ralf",
        current_car: true,
        device_odom_curr: 735.348,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [45, 46],
      latest_tracklog_time: new Date("2020-01-27T18:03:37.788000Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1003",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 0,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: null,
      users: [
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-08-28T07:57:30Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1004",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 6000,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 5, device_type: "Tracar Wired" },
      public: true,
      unread_messages: 12,
      device_category: { description: "asset" },
      car_details: {
        car_number: "sa sb 34",
        model: "POINTER",
        driver: "",
        current_car: true,
        device_odom_curr: 234.0,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2020-01-30T15:10:34Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1005",
      name: "Test  iNanny",
      description: "Herr Mayer",
      color: "#E60000",
      voltage: 2800,
      registered_on: "2019-03-14",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: null,
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T08:32:38Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1006",
      name: "Test iNanny - Battery ok",
      description: "Frau Schmidt",
      color: "#F0861B",
      voltage: 4100,
      registered_on: "2019-03-14",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.2,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T14:05:07Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1007",
      name: "demo device 2",
      description: "1007",
      color: "#B6A99B",
      voltage: 6000,
      registered_on: "2019-03-19",
      device_type: { device_type_id: 2, device_type: "iNanny Car " },
      public: false,
      unread_messages: 55,
      device_category: { description: "auto" },
      car_details: {
        car_number: "HH - A 123",
        model: "Toyota",
        driver: "xyZ",
        current_car: true,
        device_odom_curr: 5000.2,
        classify_routes: true,
      },
      users: [
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [45, 47, 46],
      latest_tracklog_time: new Date("2019-10-08T10:00:23Z"),
      is_online: false,
      assigned_geofences: [174],
    },
    {
      imei: "1011",
      name: "Tracar ABC",
      description: "1011",
      color: "#E60000",
      voltage: 6000,
      registered_on: "2019-03-21",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 7,
      device_category: { description: "auto" },
      car_details: {
        car_number: "SB SK 794",
        model: "Skoda Karoq",
        driver: "Baba Santhosh",
        current_car: true,
        device_odom_curr: 145.0,
        classify_routes: true,
      },
      tags: [47],
      latest_tracklog_time: new Date("2019-10-08T09:55:53Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1012",
      name: "Tracar OBD22",
      description: "1012",
      color: "#9B568D",
      voltage: 6000,
      registered_on: "2019-03-27",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 14,
      device_category: { description: "auto" },
      car_details: {
        car_number: "KA ER 423",
        model: "Kia Niro",
        driver: "Herr. Santhosh Nayak",
        current_car: true,
        device_odom_curr: 1001.2,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-10-08T09:52:38Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "359315071000144",
      name: "RFID Test 1",
      description: "359315071000144",
      color: "#2D862D",
      voltage: 0,
      registered_on: "2019-05-08",
      device_type: { device_type_id: 5, device_type: "Tracar Wired" },
      public: true,
      unread_messages: 2,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      tags: [],
      latest_tracklog_time: new Date("2019-09-20T14:24:08Z"),
      is_online: false,
      assigned_geofences: [159],
    },
    {
      imei: "359315071007883",
      name: "RFID Test 2",
      description: "359315071007883",
      color: "#E60000",
      voltage: 0,
      registered_on: "2019-05-08",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: true,
      unread_messages: 49,
      device_category: { description: "asset" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 1.249,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-09-13T13:09:08Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "1111111231",
      name: "Tracar Wired",
      description: "1111111231",
      color: "#E60000",
      voltage: null,
      registered_on: "2018-10-23",
      device_type: { device_type_id: 99, device_type: "iNanny DB-Test" },
      public: true,
      unread_messages: 0,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: null,
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q353161077197062",
      name: "test device 123",
      description: "Test description.",
      color: "#F1C700",
      voltage: 4208,
      registered_on: null,
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 46,
      device_category: { description: "auto" },
      car_details: {
        car_number: "schild123",
        model: "marke123",
        driver: "fahrer123",
        current_car: true,
        device_odom_curr: 123.0,
        classify_routes: false,
      },
      users: [
        {
          email: "saniwa89@gmail.com",
          first_name: "",
          last_name: "",
          id: 89,
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [45, 47, 46],
      latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      is_online: false,
      assigned_geofences: [107, 162, 159, 166],
    },
    {
      imei: "q359316075756335",
      name: "iNanny Test device",
      description: "Herr Nayak",
      color: "#E60000",
      voltage: 4200,
      registered_on: "2018-01-18",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: {
        car_number: "",
        model: "",
        driver: "",
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T14:04:11Z"),
      is_online: false,
      assigned_geofences: [166],
    },
    {
      imei: "q1002",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 63000,
      registered_on: "2018-09-12",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: true,
      unread_messages: 326,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: "sa sb 34",
        model: "POINTER",
        driver: "",
        current_car: true,
        device_odom_curr: 234.0,
        classify_routes: true,
      },
      tags: [],
      latest_tracklog_time: new Date("2020-01-14T06:03:37.788000Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1001",
      name: "Demo Tracar Device",
      description: "Mr. John Doe",
      color: "#B6A99B",
      voltage: 6000,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 121,
      device_category: { description: "auto" },
      car_details: {
        car_number: "RK 123",
        model: "Range rover",
        driver: "Ralf",
        current_car: true,
        device_odom_curr: 735.348,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [45, 46],
      latest_tracklog_time: new Date("2020-01-27T18:03:37.788000Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1003",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 0,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: null,
      users: [
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-08-28T07:57:30Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1004",
      name: "Test device SB",
      description: "Used for Company",
      color: "#777777",
      voltage: 6000,
      registered_on: "2019-03-06",
      device_type: { device_type_id: 5, device_type: "Tracar Wired" },
      public: true,
      unread_messages: 12,
      device_category: { description: "asset" },
      car_details: {
        car_number: "sa sb 34",
        model: "POINTER",
        driver: "",
        current_car: true,
        device_odom_curr: 234.0,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2020-01-30T15:10:34Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1005",
      name: "Test  iNanny",
      description: "Herr Mayer",
      color: "#E60000",
      voltage: 2800,
      registered_on: "2019-03-14",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: null,
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T08:32:38Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1006",
      name: "Test iNanny - Battery ok",
      description: "Frau Schmidt",
      color: "#F0861B",
      voltage: 4100,
      registered_on: "2019-03-14",
      device_type: { device_type_id: 1, device_type: "iNanny" },
      public: true,
      unread_messages: 0,
      device_category: { description: "person" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.2,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-03-20T14:05:07Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1007",
      name: "demo device 2",
      description: "1007",
      color: "#B6A99B",
      voltage: 6000,
      registered_on: "2019-03-19",
      device_type: { device_type_id: 2, device_type: "iNanny Car " },
      public: false,
      unread_messages: 55,
      device_category: { description: "auto" },
      car_details: {
        car_number: "HH - A 123",
        model: "Toyota",
        driver: "xyZ",
        current_car: true,
        device_odom_curr: 5000.2,
        classify_routes: true,
      },
      users: [
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [45, 47, 46],
      latest_tracklog_time: new Date("2019-10-08T10:00:23Z"),
      is_online: false,
      assigned_geofences: [174],
    },
    {
      imei: "q1011",
      name: "Tracar ABC",
      description: "1011",
      color: "#E60000",
      voltage: 6000,
      registered_on: "2019-03-21",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 7,
      device_category: { description: "auto" },
      car_details: {
        car_number: "SB SK 794",
        model: "Skoda Karoq",
        driver: "Baba Santhosh",
        current_car: true,
        device_odom_curr: 145.0,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [47],
      latest_tracklog_time: new Date("2019-10-08T09:55:53Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1012",
      name: "Tracar OBD22",
      description: "1012",
      color: "#9B568D",
      voltage: 6000,
      registered_on: "2019-03-27",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: false,
      unread_messages: 14,
      device_category: { description: "auto" },
      car_details: {
        car_number: "KA ER 423",
        model: "Kia Niro",
        driver: "Herr. Santhosh Nayak",
        current_car: true,
        device_odom_curr: 1001.2,
        classify_routes: true,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
        {
          email: "test_subuser@leoworx.com",
          first_name: "SubUser",
          last_name: "Leoworx",
          id: 10,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-10-08T09:52:38Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q359315071000144",
      name: "RFID Test 1",
      description: "359315071000144",
      color: "#2D862D",
      voltage: 0,
      registered_on: "2019-05-08",
      device_type: { device_type_id: 5, device_type: "Tracar Wired" },
      public: true,
      unread_messages: 2,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: new Date("2019-09-20T14:24:08Z"),
      is_online: false,
      assigned_geofences: [159],
    },
    {
      imei: "q359315071007883",
      name: "RFID Test 2",
      description: "359315071007883",
      color: "#E60000",
      voltage: 0,
      registered_on: "2019-05-08",
      device_type: { device_type_id: 4, device_type: "Tracar OBD2" },
      public: true,
      unread_messages: 49,
      device_category: { description: "asset" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 1.249,
        classify_routes: false,
      },
      tags: [],
      latest_tracklog_time: new Date("2019-09-13T13:09:08Z"),
      is_online: false,
      assigned_geofences: [],
    },
    {
      imei: "q1111111231",
      name: "Tracar Wired",
      description: "1111111231",
      color: "#E60000",
      voltage: null,
      registered_on: "2018-10-23",
      device_type: { device_type_id: 99, device_type: "iNanny DB-Test" },
      public: true,
      unread_messages: 0,
      device_category: { description: "vehicle" },
      car_details: {
        car_number: null,
        model: null,
        driver: null,
        current_car: true,
        device_odom_curr: 0.0,
        classify_routes: false,
      },
      users: [
        {
          email: "test_masteruser@leoworx.com",
          first_name: "Maximus",
          last_name: "Munstermann",
          id: 3,
        },
      ],
      tags: [],
      latest_tracklog_time: null,
      is_online: false,
      assigned_geofences: [],
    },
  ],
  tags: {
    tag_id_list: [45, 48, 49, 50, 47, 46],
    tag_objects: {
      "45": {
        tag_name: "ABC",
        tag_additional_info: null,
        tag_color: "#122372",
      },
      "48": {
        tag_name: "FGH",
        tag_additional_info: null,
        tag_color: "#AC4321",
      },
      "49": {
        tag_name: "JKL",
        tag_additional_info: null,
        tag_color: "#009BDC",
      },
      "50": {
        tag_name: "MNO",
        tag_additional_info: null,
        tag_color: "#2D862D",
      },
      "47": {
        tag_name: "PKW",
        tag_additional_info: null,
        tag_color: "#F1C700",
      },
      "46": {
        tag_name: "XYZ",
        tag_additional_info: null,
        tag_color: "#E60000",
      },
    },
  },
};
