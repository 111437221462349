import React from "react";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { UserLine } from "../User/UserLine";

const terms = defineMessages({
  role_name_label: {
    id: "adminPanel.roles.create.inputLabel.role_name",
    defaultMessage: "Name",
  },
  text_search_placeholder: {
    id: "adminPanel.roles.create.placeholder.searchField",
    defaultMessage: "Funktionsname",
  },
});

/**
 * Displays the form to create new roles.
 */
class RoleCreateInlineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      selectedPermissions: [],
      role: {},
      query: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getPermissionList().then(recordList => {
      this.setState({
        loading: false,
        recordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      this.props
        .createRole(
          this.state.createRoleName,
          this.state.selectedPermissions.map(
            permission => permission.permission_code,
          ),
        )
        .then(new_role_data => {
          this.props.closeFunction(new_role_data.role_id);
        });
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  getFilteredFunctions = functions => {
    return this.state.recordList.reduce((acc, permissionRecord) => {
      // show only functions matching the search query
      const stringFiltering =
        this.state.query.length === 0 ||
        permissionRecord.permission_desc
          .toLowerCase()
          .search(this.state.query.toLowerCase()) > -1 ||
        permissionRecord.printable_name
          .toLowerCase()
          .search(this.state.query.toLowerCase()) > -1;

      if (!stringFiltering) {
        return acc;
      }

      return [...acc, permissionRecord];
    }, []);
  };

  validate() {
    return !isEmpty(this.state.createRoleName);
  }

  selectRecord = record => {
    if (
      !this.state.selectedPermissions.some(
        selectedPermission =>
          record.permission_code === selectedPermission.permission_code,
      )
    ) {
      let selectedPermissions = [...this.state.selectedPermissions, record];
      this.setState({ selectedPermissions });
    }
  };

  deselectRecord = record => {
    this.setState({
      selectedPermissions: this.state.selectedPermissions.filter(
        currentRecord =>
          currentRecord.permission_code !== record.permission_code,
      ),
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div>
        {this.props.showInnerSaveButtons && (
          <h4 className="text-left">
            <T
              id="adminPanel.roles.create.headline"
              defaultMessage="Neue Rolle erstellen"
            />
          </h4>
        )}
        <div className="small-spacer" />
        <TextInput
          id="createRoleName"
          name={t(terms.role_name_label)}
          className={this.state.showErrorMessage ? "error" : ""}
          onChange={this.handleChange}
          value={this.state.role.role_name}
        />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="adminPanel.roles.create.errorMessage.name_empty"
              defaultMessage="Bitte geben Sie einen Namen an."
            />
          </div>
        )}
        <div className="small-spacer" />
        <QueryLine
          changeCallback={this.setSearchFilter}
          placeholder={t(terms.text_search_placeholder)}
        />
        <div className="small-spacer" />

        <div className="admin-list table-scroll outer-table-frame text-left grid-container">
          <div className="table-frame text-center">
            {this.state.recordList &&
              this.getFilteredFunctions().map((record, index) => (
                <div
                  className="table-line grid-x grid-padding-x align-middle"
                  key={index}
                >
                  <div className={"cell shrink auto flex-container"}>
                    <i
                      className={
                        "fa checkbox " +
                        (this.state.selectedPermissions.some(
                          selectedPermission =>
                            record.permission_code ===
                            selectedPermission.permission_code,
                        )
                          ? "fa-check-square-o"
                          : "fa-square-o")
                      }
                      onClick={
                        this.state.selectedPermissions.some(
                          selectedPermission =>
                            record.permission_code ===
                            selectedPermission.permission_code,
                        )
                          ? () => this.deselectRecord(record)
                          : () => this.selectRecord(record)
                      }
                    />
                  </div>
                  <div className="cell auto bold text-left">
                    {record.printable_name}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="small-spacer" />
        {this.props.showInnerSaveButtons === true && (
          <div className="text-right">
            <div
              className="button transparent"
              onClick={this.props.closeFunction}
            >
              <T
                id="adminPanel.roles.create.buttonLabel.cancel"
                defaultMessage="Abbrechen"
              />
            </div>
            <div className="button green" onClick={this.submit}>
              <T
                id="adminPanel.roles.create.buttonLabel.submit"
                defaultMessage="Speichern"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const RoleCreateInline = injectIntl(RoleCreateInlineClass);
