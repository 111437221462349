import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";

const terms = defineMessages({
  user_group_name_label: {
    id: "adminPanel.users.edit.inputLabel.role_name",
    defaultMessage: "Name",
  },
});

class UserGroupEditOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      showErrorMessage: false,
      editUserGroupName: this.props.editUserGroupRecord.group_name,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({
      loading: false,
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      this.props
        .editUserGroup(
          this.props.editUserGroupRecord.group_id,
          this.state.editUserGroupName,
          this.props.editUserGroupRecord.users.map(user => user.id),
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return !isEmpty(this.state.editUserGroupName);
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className={"header"}>
          <h3>
            <T
              id="adminPanel.usergroups.edit.headline"
              defaultMessage="Benutzergruppe bearbeiten"
            />{" "}
            ->{" "}
            <span className={"highlighted"}>
              {this.props.editUserGroupRecord.group_name}
            </span>
          </h3>
        </div>
        <div className={"small-spacer"} />
        <div className={"small-spacer"} />
        <div>
          <div className="small-spacer" />
          <TextInput
            id="editUserGroupName"
            name={t(terms.user_group_name_label)}
            className={this.state.showErrorMessage ? "error" : ""}
            onChange={this.handleChange}
            value={this.state.editUserGroupName}
          />
          {this.state.showErrorMessage && (
            <div className="error">
              <T
                id="adminPanel.usergroups.edit.errorMessage.name_empty"
                defaultMessage="Bitte geben Sie einen Namen an."
              />
            </div>
          )}
          <div className="small-spacer" />
        </div>
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.usergroups.edit.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.usergroups.edit.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserGroupEditOverlay = injectIntl(UserGroupEditOverlayClass);
