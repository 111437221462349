import { postJson } from "./fetchService";
import { saveAs } from "file-saver";

export const fetchReports = freeFetch => ({
  async generateReportCsv(reportType, bodyData) {
    let filename;

    console.debug(bodyData);

    return freeFetch(`/user/reports/${reportType}`, {
      ...postJson(bodyData),
      responseType: "blob",
    })
      .then(response => {
        if (!response.ok) {
          return;
        }
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => saveAs(blob, filename));
  },
});
