import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import numeral from "numeral";
import { sToh } from "../tools/functions";
import { Redirect } from "react-router";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";
import { AccClientConfig } from "../ClientSpecificContent/acc-index";

export class VehicleUsageLast30D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  setRedirect = plotlyClickEvent => {
    const { imei } = this.props.usage_per_device[
      plotlyClickEvent.points[0].pointIndex
    ];
    this.setState({ redirect: `/vehicle/statistics/${imei}` });
  };

  render() {
    if (this.state.redirect != null) {
      return <Redirect push to={this.state.redirect} />;
    }

    const {
      config,
      noAxisValues,
      usage_per_device: usage_data,
      widePlotLayoutCommons,
    } = this.props;

    /// same code is found in DeviceRangeGraph

    const x = usage_data.map(({ device_name }) => device_name);
    const yWire1 = usage_data.map(({ usage_wire1: { data } }) => {
      return sToh(parseFloat(data));
    });
    const yWire2 = usage_data.map(({ usage_wire2: { data } }) =>
      sToh(parseFloat(data)),
    );
    const yWire3 = usage_data.map(({ usage_wire3: { data } }) =>
      sToh(parseFloat(data)),
    );
    const yIgnition = usage_data.map(({ usage_ignition }) =>
      sToh(parseFloat(usage_ignition)),
    );
    const yEngine = usage_data.map(({ usage_engine }) =>
      sToh(parseFloat(usage_engine)),
    );

    const nf = (n: number) => numeral(n).format("0,0.0");

    const textLabel = x.map((device_name, index) => {
      return (
        `Zündung:  ${nf(yIgnition[index])} h<br />` +
        `Motor:  ${nf(yEngine[index])} h<br />` +
        `${usage_data[index].usage_wire1.name}:  ${nf(yWire1[index])} h<br />` +
        `${usage_data[index].usage_wire2.name}:  ${nf(yWire2[index])} h<br />` +
        `${usage_data[index].usage_wire3.name}:  ${nf(yWire3[index])} h<br />`
      );
    });

    const groupedBarChart = [
      {
        x,
        y: yIgnition,
        marker: { color: Color.Red },
        type: "bar",
        hoverinfo: "x+text",
        textformat: "x+y+text",
        text: textLabel,
      },
      {
        x,
        y: yEngine,
        marker: { color: Color.DoveGrey },
        type: "bar",
        hoverinfo: "none",
      },
      {
        x,
        y: yWire1,
        type: "bar",
        marker: { color: Color.SilverChalice1 },
        hoverinfo: "none",
      },
      {
        x,
        y: yWire2,
        marker: { color: Color.SilverChalice },
        type: "bar",
        hoverinfo: "none",
      },
      {
        x,
        y: yWire3,
        marker: { color: Color.Silver },
        type: "bar",
        hoverinfo: "none",
      },
    ];

    return (
      <div className="wide-graph-frame">
        <div className="graph-headline">
          <h5 className="headline-for-icon">
            {this.props.portal === Portal.Vehicle &&
            AccClientConfig.vehicleIsBoat ? (
              <i className="fa fa-ship" />
            ) : (
              <i className="fa fa-truck" />
            )}
            <T
              id="tracar.vehicleUsage.usage30d"
              defaultMessage="Gerätenutzung, letzte 30 Tage"
            />
          </h5>
        </div>
        <div className="tiny-spacer" />
        <Plot
          onClick={this.setRedirect}
          config={config}
          data={groupedBarChart}
          layout={{
            ...widePlotLayoutCommons,
            margin: widePlotLayoutCommons.margin,
            yaxis: { title: "h", tickformat: ",.0f," },
            xaxis: {
              ...noAxisValues,
              type: "category",
            },
          }}
        />
        <ul style={{ marginLeft: "16px", marginTop: "-28px" }}>
          <li>
            <i className="fa fa-square" style={{ color: Color.Red }} />
            &nbsp;&nbsp;
            <T id="tracar.vehicleUsage.ignition" defaultMessage="Zündung" />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i className="fa fa-square" style={{ color: Color.DoveGrey }} />
            &nbsp;&nbsp;
            <T id="tracar.vehicleUsage.engine" defaultMessage="Motor" />{" "}
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i
              className="fa fa-square"
              style={{ color: Color.SilverChalice1 }}
            />
            &nbsp;&nbsp;
            <T
              id="tracar.wires.brownCable"
              defaultMessage="Braunes Kabel; Input 1"
            />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i
              className="fa fa-square"
              style={{ color: Color.SilverChalice }}
            />
            &nbsp;&nbsp;
            <T
              id="tracar.wires.purpleCable"
              defaultMessage="Lila Kabel; Input 2"
            />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i className="fa fa-square" style={{ color: Color.Silver }} />{" "}
            <T
              id="tracar.wires.greenCable"
              defaultMessage="Grünes Kabel; Input 3"
            />
          </li>
        </ul>
      </div>
    );
  }
}
