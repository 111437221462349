import fetchUsers from "./fetchUsers";
import fetchRoles from "./fetchRoles";
import fetchUserGroups from "./fetchUserGroups";
import fetchDevices from "./fetchDevices";
import fetchServiceInterval from "./fetchServiceInterval";

export const fetchAdminPanelList = jsonFetch => ({
  ...fetchUsers(jsonFetch),
  ...fetchRoles(jsonFetch),
  ...fetchDevices(jsonFetch),
  ...fetchUserGroups(jsonFetch),
  ...fetchServiceInterval(jsonFetch),
});
