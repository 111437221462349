import React from "react";
import ReactPaginate from "react-paginate";
import Link from "react-router-dom/es/Link";
import { LoadingCircle } from "../../LoadingCircle";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { UserGroupList } from "./UserGroupList";
import { InviteOverlay } from "../../NonCompanyUsers/InviteOverlay";
import { QueryLine } from "../../tools/QueryLine";
import { UserGroupAssignUserOverlay } from "./UserGroupAssignUserOverlay";
import { UserGroupCreateOverlay } from "./UserGroupCreateOverlay";
import { UserGroupDeleteOverlay } from "./UserGroupDeleteOverlay";
import { UserGroupEditOverlay } from "./UserGroupEditOverlay";
import { RoleList } from "../Role/RoleList";
import { RoleCreateOverlay } from "../Role/RoleCreateOverlay";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";

const terms = defineMessages({
  noCustomers: {
    id: "customer.addresses.base.noCustomers",
    defaultMessage: "Keine Kundenadressen gefunden",
  },
  confirmAddressDeleteLabel: {
    id: "customer.addresses.confirmDelete.label",
    defaultMessage: "die Adresse",
  },
});

const userGroupListLimit = 10;

class AdminPanelUserGroupBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      numberOfUserGroups: 0,
      currentPage: 0,
      addressesPerPage: 25,
      addressIdToDelete: null,
      showImportOverlay: false,
      customerAddressList: null,
      showEditOverlay: false,
      showDeleteConfirmationOverlay: false,
      showUserGroupAssignUserOverlay: false,
      showUserGroupCreateOverlay: false,
      query: "",
      userGroupListOffset: 0,
      userGroupListSearchKey: "",
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getUserGroupListFiltered(
        this.state.userGroupListSearchKey,
        this.state.userGroupListOffset,
        userGroupListLimit,
      )
      .then(recordListData => {
        this.setState({
          loading: false,
          recordList: recordListData.user_group_data,
          numberOfUserGroups: recordListData.total_amount,
          numberOfPages: Math.ceil(
            recordListData.total_amount / userGroupListLimit,
          ),
        });
      });
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  render() {
    if (this.state.recordList != null) {
      /// loaded
      return (
        <UserConsumer>
          {user =>
            hasPortalAccess(user, Portal.AdminPanelUserGroup) && (
              <div>
                {this.renderUserGroupList()}
                {this.state.showEditOverlay && (
                  <UserGroupEditOverlay
                    closeFunction={this.toggleEditOverlay}
                    editUserGroupRecord={this.state.editUserGroupRecord}
                    editUserGroup={this.props.editUserGroup}
                  />
                )}
                {this.state.showDeleteOverlay && (
                  <UserGroupDeleteOverlay
                    closeFunction={this.toggleDeleteOverlay}
                    deleteUserGroupRecord={this.state.deleteUserGroupRecord}
                    deleteUserGroup={this.props.deleteUserGroup}
                  />
                )}
                {this.state.showUserGroupAssignUserOverlay && (
                  <UserGroupAssignUserOverlay
                    userGroup={this.state.selectedUserGroup}
                    assignUsersToUserGroup={this.props.assignUsersToUserGroup}
                    closeFunction={this.toggleUserGroupAssignUserOverlay}
                    getUserList={this.props.getUserList}
                    getUserListByUserGroup={this.props.getUserListByUserGroup}
                    settingsSaving={"false"}
                    showUserGroupUserCreateOverlay={
                      this.state.showUserGroupUserCreateOverlay
                    }
                    toggleUserGroupUserCreateOverlay={
                      this.toggleUserGroupUserCreateOverlay
                    }
                    createUser={this.props.signUpUser}
                  />
                )}
                {this.state.showUserGroupCreateOverlay &&
                  this.renderUserGroupCreateOverlay()}
              </div>
            )
          }
        </UserConsumer>
      );
    } else {
      /// loading
      return <LoadingCircle />;
    }
  }

  renderUserGroupList = () => {
    return (
      <UserGroupList
        toggleCreateOverlay={this.toggleCreateOverlay}
        toggleDeleteOverlay={this.toggleDeleteOverlay}
        toggleEditOverlay={this.toggleEditOverlay}
        recordList={this.state.recordList}
        numberOfUserGroups={this.state.numberOfUserGroups}
        updateListOffset={this.updateListOffset}
        updateListSearchKey={this.updateListSearchKey}
        updatePagination={this.updatePagination}
        currentPage={this.state.currentPage}
        numberOfPages={this.state.numberOfPages}
        toggleUserGroupAssignUserOverlay={this.toggleUserGroupAssignUserOverlay}
      />
    );
  };

  renderUserGroupCreateOverlay = () => {
    return (
      <UserGroupCreateOverlay
        closeFunction={this.toggleCreateOverlay}
        createUserGroup={this.props.createUserGroup}
        getUserList={this.props.getUserList}
      />
    );
  };

  updateListOffset = offset => {
    this.setState({ userGroupListOffset: offset }, this.load);
  };

  updateListSearchKey = searchKey => {
    this.setState({ userGroupListSearchKey: searchKey }, this.load);
  };

  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * userGroupListLimit);
  };

  toggleCreateOverlay = () => {
    this.setState(
      {
        showUserGroupCreateOverlay: !this.state.showUserGroupCreateOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showUserGroupCreateOverlay) {
          this.load();
        }
      },
    );
  };

  toggleEditOverlay = userGroupRecord => {
    this.setState(
      {
        editUserGroupRecord: userGroupRecord,
        showEditOverlay: !this.state.showEditOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showEditOverlay) {
          this.load();
        }
      },
    );
  };

  toggleDeleteOverlay = userGroupRecord => {
    this.setState(
      {
        deleteUserGroupRecord: userGroupRecord,
        showDeleteOverlay: !this.state.showDeleteOverlay,
      },
      () => {
        // reload list only when closing the delete overlay
        if (!this.state.showDeleteOverlay) {
          this.load();
        }
      },
    );
  };

  toggleUserGroupUserCreateOverlay = () => {
    alert("Toggle user crreate overlay");
  };

  toggleDeviceAssignmentOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle device assignment overlay");
  };

  toggleRoleAssignmentOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle role assignment overlay");
  };

  toggleUserGroupAssignUserOverlay = userGroup => {
    this.setState(
      {
        showUserGroupAssignUserOverlay: !this.state
          .showUserGroupAssignUserOverlay,
        selectedUserGroup: userGroup,
      },
      () => {
        // reload list only when closing the delete overlay
        if (!this.state.showUserGroupAssignUserOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Return the pagination if needed
   * @return JSX||null
   */
  getPagination = () => {
    const pageCount = this.getPageCount();

    if (!this.state.customerAddressList || pageCount <= 1) {
      return null;
    }

    return (
      <div className="pagination-wrapper">
        <ReactPaginate
          activeClassName={"active"}
          breakClassName={"break-me"}
          breakLabel={<a>...</a>}
          containerClassName={"pagination"}
          forcePage={this.state.currentPage}
          marginPagesDisplayed={2}
          nextLabel={<i className="fa fa-chevron-circle-right" />}
          onPageChange={this.handlePagination}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          previousLabel={<i className="fa fa-chevron-circle-left" />}
          subContainerClassName={"pages pagination"}
        />
        <ul className="pagination-limiter">
          {[25, 100, 500].map(limit => (
            <li {...this.getPaginationItemProps(limit)}>{limit}</li>
          ))}
          <T
            id="customer.addresses.base.pagination.label"
            defaultMessage="Kundenadressen"
          />
        </ul>
      </div>
    );
  };

  /**
   * Return the number of pages for the Pagination
   * @return integer
   */
  getPageCount = () => {
    if (!this.state.customerAddressList) {
      return 0;
    }

    return Math.ceil(
      this.state.customerAddressList.total_amount / this.state.addressesPerPage,
    );
  };

  /**
   * Returns the props for the pagination items
   * @param  ineteger limit
   * @return Object
   */
  getPaginationItemProps = limit => {
    const className = `link ${
      limit === this.state.addressesPerPage ? " active" : ""
    }`;

    return {
      key: limit,
      onClick: () => this.setPaginationLimit(limit),
      className,
    };
  };

  /**
   * Sets the pagination-limit and reloads the list
   * @param  integer limit
   * @return void
   */
  setPaginationLimit = limit => {
    this.setState(
      {
        addressesPerPage: limit,
      },
      this.handlePagination,
    );
  };

  /**
   * handles the click on the pagination
   * @param  Object data
   * @return void
   */
  handlePagination = data => {
    // Height of header
    const paginationYOffset = 155;
    const selected = data ? data.selected : this.state.currentPage;
    const { addressesPerPage } = this.state;
    const offset = Math.ceil(selected * addressesPerPage);

    this.setState({
      loading: true,
      currentPage: selected,
    });

    this.load({ offset, limit: addressesPerPage });

    if (window.pageYOffset > paginationYOffset) {
      window.scrollTo(0, paginationYOffset);
    }
  };

  /**
   * Returns the address-list-table
   * @return JSX
   */
  renderList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    const portal = this.props.match.params.portal || "tracar";

    return (
      <div>
        DEBUG: output of renderList()
        {/*<CustomerAddressHeadline
          customerAddressList={this.state.customerAddressList}
        />
        <CustomerAddressList
          customerAddresses={this.state.customerAddressList.cust_addr}
          deleteAddress={this.deleteAddress}
          showDeleteConfirmation={this.toggleDeleteConfirmationOverlay}
          portal={portal}
        />*/}
      </div>
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };
}

export const AdminPanelUserGroupBase = injectIntl(AdminPanelUserGroupBaseClass);
