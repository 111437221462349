import React from "react";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingSmallCircle } from "../../LoadingCircle";
import { UserGroupUserLine } from "./UserGroupUserLine";
import { PaginationBar } from "../PaginationBar";
import { removeArrayElement } from "../../utility";
import { QueryLine } from "../../tools/QueryLine";
import { UserCreateOverlay } from "../User/UserCreateOverlay";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { SelectInput } from "../../SelectInput";
import { UserLine } from "../User/UserLine";

const terms = defineMessages({
  device_name_label: {
    id: "adminPanel.devices.edit.inputLabel.device-name",
    defaultMessage: "Name",
  },
  text_search_placeholder: {
    id: "adminPanel.usergroups.assignUser.placeholder.searchField",
    defaultMessage: "Benutzerdaten",
  },
  show_more: {
    id: "adminPanel.devices.edit.show-more",
    defaultMessage: "Alle anzeigen",
    label: "Alle anzeigen",
  },
  show_less: {
    id: "adminPanel.devices.edit.show-less",
    defaultMessage: "Weniger anzeigen",
    label: "Weniger anzeigen",
  },
});

const numberOfDevicesToShow = 10;

const filterRole = ["Rolle 01", "Rolle 02", "Rolle 03", "Rolle 04"];
const filterGroup = [
  "Gruppe 01",
  "Gruppe 02",
  "Gruppe 03",
  "Gruppe 04",
  "Gruppe 05",
];

class UserGroupAssignUserOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      actionUsersShow: true,
      actionUsersEdit: false,
      selectedUserIdList: [],
      recordListByUserGroup: [],
      query: "",
      //editRoleName: this.props.editRoleRecord.role_name,
      //selectedFunctions: this.props.editRoleRecord.functionalities,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getUserList().then(recordList => {
      this.setState({
        loading: false,
        recordList,
      });
    });
    this.props
      .getUserListByUserGroup(this.props.userGroup.group_id, false)
      .then(recordListsByUserGroup => {
        this.setState({
          loading: false,
          recordListByUserGroup: recordListsByUserGroup,
        });
      });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  submit() {
    this.props
      .assignUsersToUserGroup(
        this.props.userGroup,
        this.state.recordListByUserGroup.map(user => user.user.id),
      )
      .then(this.props.closeFunction);
  }

  filterRecords = () => {
    return this.state.recordList.filter(record => {
      let stringQueryResult = true;
      if (this.state.query.length >= 1) {
        stringQueryResult =
          record.user.email.search(this.state.query) > -1 ||
          record.user.first_name.search(this.state.query) > -1 ||
          record.user.last_name.search(this.state.query) > -1 ||
          (record.user.dlc &&
            record.user.dlc.dlc_barcode &&
            record.user.dlc.dlc_barcode.search(this.state.query) > -1);
      }

      return stringQueryResult;
    });
  };

  render() {
    let show_all = false;
    let limited_class = "";
    if (
      this.state.recordListByUserGroup &&
      this.state.recordListByUserGroup.length > 10
    ) {
      show_all = true;
      limited_class = "limited";
    }
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className="role-assign-user"
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.roles.assignUser.headline"
              defaultMessage="Benutzer zuweisen"
            />{" "}
            ->
            <span className="location"> {this.props.userGroup.group_name}</span>
          </h3>
        </div>
        <div className="small-spacer" />

        <h4 className="text-left">
          <T
            id="adminPanel.roles.assignUser.label.assignedUsers"
            defaultMessage="Zugewiesene Nutzer"
          />{" "}
          <span className="item-count">
            {this.state.recordListByUserGroup.length}
          </span>
        </h4>
        <div
          className={
            "grid-x grid-padding-x grid-padding-y relative list-horizontal " +
            limited_class
          }
        >
          {show_all && <div className="radiant-overlay" />}
          {this.state.recordListByUserGroup.map((record, index) => (
            <div className="cell shrink" key={index}>
              <div className="list-item flex-container align-justify">
                <span>{record.user.email}</span>
                <i
                  className="icon-remove_circle close-icon align-self-middle"
                  style={{ marginLeft: "8px" }}
                  onClick={() => this.deselectRecord(record)}
                />
              </div>
            </div>
          ))}
        </div>
        {/* @todo auf click eine Klasse an .assigned-user-list schreiben. Dann kann mit css die höhe animiert werden und es werden alle angezeigt*/}
        {show_all && <a className="show-all">show all</a>}
        <div className="small-spacer" />
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="large-auto medium-6 cell">
            <QueryLine
              changeCallback={this.setSearchFilter}
              placeholder={t(terms.text_search_placeholder)}
            />
          </div>
        </div>
        <div className="small-spacer" />
        <div className="admin-list outer-table-frame text-left">
          {this.state.selectAllButtonState === "all" && (
            <div
              className="button transparent noPadding"
              onClick={this.selectAllRecords}
            >
              <T
                id="adminPanel.roles.assignUser.buttonLabel.selectAllUsersButton"
                defaultMessage="Alle auswählen"
              />
            </div>
          )}
          {this.state.selectAllButtonState === "none" && (
            <div
              className="button transparent noPadding"
              onClick={this.deselectAllRecords}
            >
              <T
                id="adminPanel.roles.assignUser.buttonLabel.deselectAllUsersButton"
                defaultMessage="Alle abwählen"
              />
            </div>
          )}
          <div className="table-frame table-scroll">
            {this.state.recordList &&
              this.state.recordListByUserGroup &&
              this.filterRecords().map((record, index) => {
                return (
                  <UserGroupUserLine
                    key={record.id}
                    currentUser={record}
                    is_selected={this.state.recordListByUserGroup.some(
                      recordListByUserGroupEntry =>
                        recordListByUserGroupEntry.user.id === record.user.id,
                    )}
                    selectRecord={this.selectRecord}
                    deselectRecord={this.deselectRecord}
                  />
                );
              })}
          </div>
        </div>
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.roles.assignUser.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.roles.assignUser.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }

  renderConfirmation = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderBody()}
        {//TODO: activate after merge with users admin panel
        this.props.showDeviceUserCreateOverlay && (
          <UserCreateOverlay
            closeFunction={this.props.toggleUserCreateOverlay}
            createUser={this.props.signUpUser}
            getRoleList={this.props.getRoleList}
          />
        )}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    return (
      <div className="header">
        <h3>
          <T
            id="adminPanel.users.edit.headline"
            defaultMessage="Benutzerzuweisung -> "
          />
          {/* @todo User Group Name*/}
          <span className="highlighted">
            <T
              id="adminPanel.users.assignRole.headlineMultipleSelected"
              defaultMessage="Dummy Device"
            />
          </span>
        </h3>
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    const userList = this.props.userGroup.users || [];

    const userListToShow =
      userList.length > numberOfDevicesToShow
        ? this.state.showMore
          ? userList
          : userList.slice(0, numberOfDevicesToShow)
        : userList;

    return (
      <div>
        <div className="small-spacer" />
        <h4 className="text-left">
          <T
            id="adminPanel.devices.user-assignment.labels.headline"
            defaultMessage="Zugewiesene Nutzer:"
          />
          <span className="item-count">{userList && userList.length}</span>
        </h4>

        <div className="grid-container">
          <div
            className={
              "grid-x grid-padding-x grid-padding-y large-up-5 medium-up-2 relative list-horizontal"
            }
          >
            {userListToShow.map(user => {
              return (
                <div key={user.id} className="cell">
                  <div className="list-item flex-container align-justify">
                    <span>{user.first_name || user.id}</span>
                    {
                      <i
                        className="icon-remove_circle close-icon align-self-middle"
                        onClick={event => {
                          event.stopPropagation();
                          alert("delete");
                        }}
                      />
                    }
                  </div>
                </div>
              );
            })}
            {/* @todo fade in out radient overlay*/}
            <div className="radiant-overlay" />
          </div>
        </div>
        <div className="small-spacer" />

        {userList.length > numberOfDevicesToShow && (
          <div
            className="button text-center transparent flex-container align-center"
            onClick={() => this.toggleShowMore()}
          >
            {this.state.showMore ? t(terms.show_less) : t(terms.show_more)}
          </div>
        )}

        <div>
          <div className="grid-x grid-margin-x align-middle">
            <div className="auto cell">
              <QueryLine changeCallback={this.setSearchFilter} />
              {this.state.showErrorMessage && (
                <div className="error">
                  <T
                    id="adminPanel.devices.user-assignment.errorMessage.name-empty"
                    defaultMessage="Bitte geben Sie einen Namen an."
                  />
                </div>
              )}
            </div>
            <div className="shrink cell">
              <select
                className="select"
                id="filterRole"
                value={this.state.type_filter}
                onChange={this.handleChange}
              >
                {filterRole.map(type => {
                  return (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="shrink cell">
              <select
                className="select"
                id="filterGroup"
                value={this.state.type_filter}
                onChange={this.handleChange}
              >
                {filterGroup.map(type => {
                  return (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="shrink cell">
              <div className="button-frame">
                <div
                  className="button blue primary"
                  onClick={this.props.toggleUserGroupUserCreateOverlay}
                >
                  <T
                    id="adminPanel.devices.user-assignment.labels.createUserButton"
                    defaultMessage="Neuen Nutzer anlegen"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="action-list flex-inline">
            <div
              className="button transparent"
              onClick={() => this.selectAllUsers()}
            >
              <T
                id="adminPanel.devices.user-assignment.labels.select-all"
                defaultMessage="Alle Auswählen"
              />
            </div>
            <div className="bold">
              <T
                id="adminPanel.users.list.headline.action"
                defaultMessage="Aktion"
              />{" "}
              ->
            </div>
            <div className="checkbox-container">
              <div className="checkbox action" id="checbox_01" checked={false}>
                {this.state.actionUsersShow ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                <T
                  id="adminPanel.devices.user-assignment.labels.show"
                  defaultMessage="Anzeigen"
                />
              </div>
            </div>
            <div className="checkbox-container">
              <div className="checkbox action" id="checbox_01" checked={false}>
                {this.state.actionUsersEdit ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                <T
                  id="adminPanel.devices.user-assignment.labels.edit"
                  defaultMessage="Bearbeiten"
                />
              </div>
            </div>
            {/* @todo Counter Dummy*/}
            <div className="counter">3</div>
          </div>
        </div>

        <div className="admin-list" style={{ marginTop: 0 }}>
          <React.Fragment>
            <div className="content-container">
              <div className="content">
                <div className="small-spacer" />
                {this.renderUserTable()}
              </div>
            </div>
            {this.renderFooter()}
          </React.Fragment>
        </div>
      </div>
    );
  };

  renderUserTable = () => {
    const filteredUserList = this.getFilteredUserList(this.props.userList);

    return (
      <div className="outer-table-frame table-scroll">
        {this.props.inviteMessage && (
          <div className={this.props.inviteMessage.className}>
            {this.props.inviteMessage.text}
            <div className="small-spacer" />
          </div>
        )}
        <div className="table-frame">
          {filteredUserList.map(user => (
            <UserGroupUserLine
              key={user.id}
              user={user}
              selected={this.state.selectedUserIdList.find(
                id => id === user.id,
              )}
              updateSelectedUserIdList={this.updateSelectedUserIdList}
            />
          ))}
        </div>
        {/*filteredDevices.length > 0 && (
          <PaginationBar
            numberOfPages={Math.ceil(
              filteredDevices.length / this.props.devicesPerPage,
            )}
            currentPage={this.props.currentPage}
            updateCurrentPage={this.props.updateCurrentPage}
          />
            )*/}
        <div className="small-spacer" />
      </div>
    );
  };

  renderFooter = () => {
    return (
      <div className="footer text-right">
        <div className="button transparent" onClick={this.props.closeFunction}>
          <span>
            <T
              id="adminPanel.devices.user-assignment.close"
              defaultMessage="Schließen"
            />
          </span>
        </div>

        <div
          className={`button primary green ${this.props.settingsSaving ===
            "true" && "saving"} ${this.props.settingsSaving === "done" &&
            "saved"}`}
          onClick={() => alert("save")}
        >
          {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
          <span>
            {this.props.settingsSaving === "true" && (
              <T
                id="adminPanel.devices.user-assignment.saving"
                defaultMessage="Nutzer werden aktualisiert..."
              />
            )}
            {this.props.settingsSaving === "false" && (
              <T
                id="adminPanel.devices.user-assignment.save"
                defaultMessage="Nutzer aktualisieren"
              />
            )}
            {this.props.settingsSaving === "done" && (
              <T
                id="adminPanel.devices.user-assignment.saved"
                defaultMessage="Nutzer aktualisiert"
              />
            )}
          </span>
        </div>
      </div>
    );
  };

  selectRecord = record => {
    if (
      !this.state.recordListByUserGroup.some(
        userRecord => userRecord.user.id === record.user.id,
      )
    ) {
      let recordListByUserGroup = [...this.state.recordListByUserGroup, record];
      this.setState({ recordListByUserGroup });
    }
  };

  selectAllRecords = () => {
    this.setState({
      selectAllButtonState: "none",
      recordListByUserGroup: this.state.recordList,
    });
  };

  deselectAllRecords = () => {
    this.setState({
      selectAllButtonState: "all",
      recordListByUserGroup: [],
    });
  };

  deselectRecord = record => {
    this.setState({
      recordListByUserGroup: this.state.recordListByUserGroup.filter(
        currentRecord => currentRecord.user.id !== record.user.id,
      ),
    });
  };
}

export const UserGroupAssignUserOverlay = injectIntl(
  UserGroupAssignUserOverlayClass,
);
