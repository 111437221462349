import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import Link from "react-router-dom/es/Link";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  deviceName: {
    id: "tracar.device.deviceName",
    defaultMessage: "Geräte-Name",
  },
  comment: {
    id: "tracar.device.comment",
    defaultMessage: "Anmerkung",
  },
  maxSpeed: {
    id: "tracar.device.maxSpeed",
    defaultMessage: "Geschwindigkeitsgrenze (km / h)",
  },
  email: {
    id: "tracar.device.email",
    defaultMessage: "E-Mail",
  },
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
});

class UserTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.userSettings,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.userSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]]) settings[setting[0]] = this.state[setting[0]];
    });

    /*Object.entries(settings.fahrtenbuch_current_car_details).forEach(
      setting => {
        if (this.state[setting[0]])
          settings.fahrtenbuch_current_car_details[setting[0]] = this.state[
            setting[0]
          ];
      },
    );*/

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.props.saveDeviceSettings(this.props.tabType);
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  setProfilePrivate = user_owns_car => {
    user_owns_car && this.setState({ public: false });
  };
  setProfilePublic = user_owns_car => {
    user_owns_car && this.setState({ public: true });
  };
  setClassifyRoutes = classify_routes => user_owns_car => {
    user_owns_car && this.setState({ classify_routes });
  };

  render() {
    const t = this.props.intl.formatMessage;

    const user_owns_car =
      parseInt(this.props.user.user_id, 10) ===
      parseInt(this.state.assigned_user, 10);

    return <div> deleted_settings </div>;

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            {/*<h5>
            <T
              id="tracar.device.fahrtenbuchData"
              defaultMessage="Fahrtenbuch-Angaben"
            />
          </h5>*/}
            <div className="grid-x grid-margin-x">
              {this.props.user.is_company && (
                <div className="medium-6 large-6 cell">
                  <div className="select-frame">
                    <i className="fa fa-angle-down angle" />
                    <select
                      className="select"
                      id="assigned_user"
                      value={this.state.assigned_user}
                      onChange={this.handleFormChange}
                    >
                      {this.props.userSettings.list_of_all_sub_users.map(
                        listedUser => {
                          return listedUser.email === this.props.user.email ? (
                            <option value={listedUser.id} key={listedUser.id}>
                              {t(terms.noUser)}
                            </option>
                          ) : (
                            <option value={listedUser.id} key={listedUser.id}>
                              {listedUser.email}
                              &nbsp;&nbsp;&nbsp; {listedUser.first_name}{" "}
                              {listedUser.last_name}
                            </option>
                          );
                        },
                      )}
                    </select>
                    <label htmlFor="assigned_user">
                      <T
                        id="tracar.device.assignedUser"
                        defaultMessage="Zugeordneter Nutzer"
                      />
                    </label>
                  </div>
                </div>
              )}
              {/*<TextInput
              className="medium-6 large-6 cell"
              id="max_speed"
              name={t(terms.maxSpeed)}
              onChange={this.handleFormChange}
              type="number"
              value={this.state.max_speed_limit}
            />*/}
            </div>
            <div className="grid-x grid-margin-x">
              <div className="medium-6 large-6 cell">
                <div className="weekday-input">
                  <div className="weekday-buttons">
                    <div
                      className={
                        "button" +
                        (this.state.public ? "" : " active") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setProfilePrivate(user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.privateInvisible"
                        defaultMessage="private Fahrten sind nicht sichtbar"
                      />
                    </div>
                    <div
                      className={
                        "button" +
                        (this.state.public ? " active" : "") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setProfilePublic(user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.allVisible"
                        defaultMessage="alle Fahrten sind sichtbar"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.public ? (
                    <T
                      id="tracar.device.allVisibleMessage"
                      defaultMessage="Alle Fahrten sind für den Fuhrparkleiter sichtbar."
                    />
                  ) : (
                    <T
                      id="tracar.device.privateInvisibleMessage"
                      defaultMessage="Nur Dienstfahrten sind für den Fuhrparkleiter sichtbar."
                    />
                  )}
                </div>
              </div>
              <div className="medium-6 large-6 cell">
                <div className="weekday-input">
                  <div className="weekday-buttons">
                    <div
                      className={
                        "button" +
                        (this.state.classify_routes ? " active" : "") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setClassifyRoutes(true, user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.keepFahrtenbuch"
                        defaultMessage="Fahrtenbuch führen"
                      />
                    </div>
                    <div
                      className={
                        "button" +
                        (this.state.classify_routes ? "" : " active") +
                        (user_owns_car ? "" : " disabled")
                      }
                      onClick={() => {
                        this.setClassifyRoutes(false, user_owns_car);
                      }}
                    >
                      <T
                        id="tracar.device.routList"
                        defaultMessage="Routenliste anzeigen"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.classify_routes ? (
                    <T
                      id="tracar.device.fahrtenbuchExplanation"
                      defaultMessage="Für das Fahrzeug sollen alle Fahrten im Fahrtenbuch klassifiziert werden."
                    />
                  ) : (
                    <T
                      id="tracar.device.routListExplanation"
                      defaultMessage="Sämtliche Fahrten werden unklassifiziert in der Routenliste angezeigt."
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T
                id="tracar.rfid-chip-list.settings.cancel"
                defaultMessage="Abbrechen"
              />
            </span>
          </Link>

          <div
            className={`button save-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"}`}
            onClick={() =>
              this.props.settingsSaving === "false" && this.saveDeviceSettings()
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.user.saving"
                  defaultMessage="Benutzer wird aktualisiert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.user.save"
                  defaultMessage="Benutzer aktualisieren"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.user.saved"
                  defaultMessage="Benutzer aktualisiert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const UserTab = injectIntl(UserTabClass);
