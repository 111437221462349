import React from "react";
import { FormattedMessage as T } from "react-intl";
import moment from "moment";
import { DropdownMenu, DropdownItems } from "../../tools/DropdownMenu";
export function UserGroupUserLine({
  currentUser,
  toggleInviteOverlay,
  toggleAssignDeviceOverlay,
  toggleAssignRoleOverlay,
  toggleAssignUserGroupOverlay,
  toggleEditOverlay,
  toggleDeleteOverlay,
  is_selected,
  selectRecord,
  deselectRecord,
  showButtonInvite,
  showButtonRoleAssignment,
  showButtonGroupsAssignment,
  showButtonDeviceAssignment,
  showButtonEdit,
  showButtonDelete,
}) {
  /*eslint-disable */
  const {
    activation_expires,
    date_created,
    email,
    first_name,
    is_active,
    is_company,
    last_name,
  } = currentUser.user;
  /*eslint-enable */
  const invitationDate = moment(date_created).format("DD.MM.YYYY");

  return (
    <div className="table-line grid-x grid-padding-x">
      <div
        className={
          "cell small-12 medium-6 large-6 first-cell" +
          (is_active ? "" : " inactive")
        }
      >
        <div className="grid-x">
          <div className="shrink cell">
            {is_selected ? (
              <i
                className="fa fa-check-square-o checkbox"
                onClick={() => deselectRecord(currentUser)}
              />
            ) : (
              <i
                className="fa fa-square-o checkbox"
                onClick={() => selectRecord(currentUser)}
              />
            )}

            <span className="name"> {email}</span>
          </div>
          <br />
          <div className="shrink cell status">
            <span>
              {first_name || last_name ? first_name + " " + last_name : "-"}{" "}
            </span>
            <span>
              EmployeeID:{" "}
              {currentUser.user.unique_employee_id
                ? currentUser.user.unique_employee_id
                : "-"}
            </span>
          </div>
        </div>
      </div>
      <div className="cell small-12 medium-12 large-auto">
        <strong>
          {currentUser.user.role.role_name
            ? currentUser.user.role.role_name
            : "-"}
        </strong>
        <br />
        <span className="grey-text line-pad">
          <T
            id="adminPanel.users.line.label.role_name"
            defaultMessage="Nutzerrolle"
          />
        </span>
      </div>
      <div className="cell small-12 medium-12 large-auto">
        <strong>
          {currentUser.user.user_groups.length > 0
            ? currentUser.user.user_groups[0].user_group_name
            : "-"}
        </strong>
        <br />
        <span className="grey-text line-pad">
          <T
            id="adminPanel.users.line.label.usergroup_name"
            defaultMessage="Nutzergruppe"
          />
        </span>
      </div>
      <div className="cell small-12 large-1 medium-1 table-line-right align-middle align-right">
        {(showButtonInvite ||
          showButtonRoleAssignment ||
          showButtonGroupsAssignment ||
          showButtonDeviceAssignment ||
          showButtonDelete) && (
          <div className="button-container">
            {showButtonInvite && (
              <div
                className="button"
                onClick={() => toggleInviteOverlay(currentUser)}
              >
                {is_active ? (
                  <i className="fa fa-envelope ind active" />
                ) : (
                  <i className="fa fa-envelope ind inactive" />
                )}
              </div>
            )}
            {showButtonRoleAssignment && (
              <div
                className="button"
                onClick={() => toggleAssignRoleOverlay(currentUser)}
              >
                <i className="fa icon-user-role" />
              </div>
            )}
            {showButtonGroupsAssignment && (
              <div className="button" onClick={toggleAssignUserGroupOverlay}>
                <i className="fa fa-users" />
              </div>
            )}
            {showButtonDeviceAssignment && (
              <div
                className="button relative"
                onClick={toggleAssignDeviceOverlay}
              >
                <i className="icon-device" />
                <span className="badge red">13</span>
              </div>
            )}
          </div>
        )}

        {(showButtonDelete || showButtonEdit) && (
          <div className="button-container">
            <DropdownMenu>
              <DropdownItems>
                {showButtonEdit && (
                  <li onClick={() => toggleEditOverlay(currentUser)}>
                    <i className="fa fa-pencil" />
                    <T
                      id="adminPanel.users.submenu.label.edit"
                      defaultMessage="Bearbeiten"
                    />
                  </li>
                )}
                {showButtonDelete && (
                  <li onClick={() => toggleDeleteOverlay(currentUser)}>
                    <i className="fa fa-trash" />
                    <T
                      id="adminPanel.users.submenu.label.delete"
                      defaultMessage="Löschen"
                    />
                  </li>
                )}
              </DropdownItems>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
}
