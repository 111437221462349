import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";

// noinspection JSUnresolvedVariable
import ReactTooltip from "react-tooltip";
import { DeviceType } from "../Wires/deviceTypes";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

import { Portal } from "../Portal";
import { isDev } from "../tools/Environment";
import { green } from "ansi-colors";
import { hasPermission, Permission } from "../Permission";
import { UserConsumer } from "../Base";
import { DeviceAssignmentType } from "../ApiContract";

const terms = defineMessages({
  privateCar: {
    id: "tracar.device-list.privateCar",
    defaultMessage: "privat genutztes Firmenfahrzeug",
  },
  companyCar: {
    id: "tracar.device-list.companyCar",
    defaultMessage: "Firmenfahrzeug",
  },
  tags: {
    id: "tracar.device-list.tags",
    defaultMessage: "Tags",
  },
  statistics: {
    id: "tracar.device-list.statistics",
    defaultMessage: "Statistiken",
  },
  mapView: {
    id: "tracar.device-list.mapView",
    defaultMessage: "Kartenansicht",
  },
  fahrtenbuch: {
    id: "tracar.device-list.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  geofences: {
    id: "tracar.device-list.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  settings: {
    id: "tracar.device-list.settings",
    defaultMessage: "Einstellungen",
  },
  contacts: {
    id: "tracar.device-list.contacts",
    defaultMessage: "Kontakte",
  },
  messages: {
    id: "tracar.device-list.messages",
    defaultMessage: "Nachrichten",
  },
  fence_down: {
    id: "tracar.device-list.fenceDown",
    defaultMessage: "Bauzaun ist umgestürzt!",
  },
  batteryStatus: {
    id: "tracar.device-list.batteryStatus",
    defaultMessage: "Batteriestatus",
  },
  ok: {
    id: "ok",
    defaultMessage: "O.K.",
  },
});

/**
 * The volatge ranges from 4.2V (100%) to 3.6V (0%).
 * @param  {[type]} voltage [description]
 * @return {[type]}         [description]
 */
const getPersonBatteryClass = voltage => {
  const full = 4200;
  const empty = 3600;
  const diffFullEmpty = full - empty;

  const percentage = ((voltage - empty) / diffFullEmpty) * 100;
  const classes = ["fa"];

  switch (true) {
    case percentage < 25:
      classes.push("fa-battery-empty");
      break;
    case percentage >= 25 && percentage < 50:
      classes.push("fa-battery-half");
      break;
    case percentage >= 50:
      classes.push("fa-battery-full");
      break;
  }

  return classes.join(" ");
};

export const TrackerTableLine = withRouter(
  injectIntl(props => {
    const t = props.intl.formatMessage;
    const { tagList } = props;
    const { device_type_id } = props.device.device_type;

    const {
      color,
      description,
      fence_down,
      imei,
      name,
      public: isPublic,
      tags,
      assigned_geofences,
      unread_messages,
      user,
      latest_tracklog_time,
      registered_on,
    } = props.device;
    const email = props.device.user && props.device.user.email;
    const portal = props.match.params.portal || "tracar";
    const deviceIsAssigned =
      props.user.is_company && email !== props.user.email;
    const selectedDevicesImeiList = props.selectedDevicesImeiList;

    const alertClass = fence_down
      ? "fa-times pulsing-red"
      : "fa-circle ok-green";

    // all portals have the map marker
    let icon = (
      <div className={`message-pin flex-dir-column align-self-top`}>
        <i className="fa fa-map-marker margin-bottom-1" style={{ color }} />
        {props.device.assignment_type === DeviceAssignmentType.Read && (
          <i className="fa fa-lock" />
        )}
      </div>
    );

    //needed for multiple assigning
    const updateCheckBox = imei => {
      const checkBox = document.getElementById("checkBox_" + imei);
      props.updateSelectedDevicesImeiList(imei, !checkBox.checked);
    };

    const onlineStatus = props.device.is_online
      ? { title: "online", style: { fontWeight: "bold", color: "green" } }
      : { title: "offline", style: { fontWeight: "normal" } };

    return (
      <UserConsumer>
        {user => (
          <div
            className={`table-line tracker grid-x`}
            onClick={() => updateCheckBox(imei)}
          >
            <div className="cell small-11 medium-1 large-1 table-line-left">
              <div className="checkbox-container">
                {props.device.assignment_type !== DeviceAssignmentType.Read && (
                  <div
                    className="checkbox"
                    id={"checkBox_" + imei}
                    checked={
                      selectedDevicesImeiList
                        ? typeof selectedDevicesImeiList.find(
                            imei => props.device.imei === imei,
                          ) !== "undefined"
                          ? true
                          : false
                        : false
                    }
                  >
                    {selectedDevicesImeiList ? (
                      typeof selectedDevicesImeiList.find(
                        imei => props.device.imei === imei,
                      ) !== "undefined" ? (
                        <i className="icon-check" />
                      ) : (
                        <i className="icon-check_empty" />
                      )
                    ) : (
                      <i className="icon-check_empty" />
                    )}
                  </div>
                )}
              </div>

              {icon}
              <div style={{ display: "block" }}>
                {name}
                <div style={{ fontWeight: "normal" }}>
                  <small>IMEI: {imei}</small>
                </div>
                <div style={onlineStatus.style}>
                  <small>{onlineStatus.title}</small>
                </div>
              </div>
            </div>

            <div className="cell small-12 medium-12 large-auto table-line-middle flexible">
              {description}
              {deviceIsAssigned && (
                <div>
                  <small>
                    {user.first_name} {user.last_name} {user.email} -{" "}
                    {isPublic ? t(terms.companyCar) : t(terms.privateCar)}
                  </small>
                </div>
              )}
              <div>
                <small>
                  {latest_tracklog_time && (
                    <div>
                      <T
                        id="tracar.device-list.latest_tracklog_time"
                        defaultMessage="Letzter Zugriff:"
                      />{" "}
                      {latest_tracklog_time.toLocaleDateString("de-DE")}
                    </div>
                  )}
                  {registered_on && (
                    <div>
                      <T
                        id="tracar.device-list.registered_on"
                        defaultMessage="Registriert seit:"
                      />{" "}
                      {new Date(registered_on).toLocaleDateString("de-DE")}
                    </div>
                  )}
                </small>
              </div>

              {!deviceIsAssigned && (
                <div>
                  <small>-</small>
                </div>
              )}
              {tags.map(tagId => (
                <span key={tagId} className="tag">
                  <i
                    className="fa fa-tag"
                    style={{
                      color: tagList.tag_objects[tagId.toString()].tag_color,
                    }}
                  />
                  &nbsp;&nbsp;{tagList.tag_objects[tagId.toString()].tag_name}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
              {props.selectedGeofences &&
                assigned_geofences &&
                props.selectedGeofences.map(
                  geofence =>
                    assigned_geofences.find(id => {
                      return id === geofence.id;
                    }) && (
                      <span key={geofence.id} className="geofence">
                        <i className="icon-globe" />
                        &nbsp;&nbsp;{geofence.name} &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    ),
                )}
            </div>

            <div className="cell small-12 medium-1 large-1 table-line-right fixed">
              <div className="button-container">
                <Link
                  to={"/" + portal + "/messages/" + imei}
                  className="button"
                  data-for={`tracker-table-line-${imei}`}
                  data-tip={t(terms.messages)}
                >
                  <i className="fa fa-envelope" />
                  {unread_messages > 0 && unread_messages < 100 && (
                    <div className="indicator">{unread_messages}</div>
                  )}
                  {unread_messages >= 100 && (
                    <div className="indicator">99+</div>
                  )}
                </Link>
                {portal === "person" && (
                  <div className="button cursor-default">
                    <i
                      className={getPersonBatteryClass(props.device.voltage)}
                      data-for={`tracker-table-line-${imei}`}
                      data-tip={`${t(terms.batteryStatus)}: ${props.device
                        .voltage / 1000} V`}
                    />
                  </div>
                )}
              </div>
              {/*TODO: deprecated after bulk is ready
          <div
            className="button"
            data-for={`tracker-table-line-${imei}`}
            data-tip={t(terms.tags)}
            onClick={event => {
              event.stopPropagation();
              props.toggleShowAssignTagsOverlay(props.device);
            }}
          >
            <i className="fa fa-tag" />
          </div>*/}

              <div className="button-container">
                {portal !== "person" &&
                  hasPermission(user, Permission.Dashboard) && (
                    <Link
                      to={"/" + portal + "/statistics/" + imei}
                      className="button"
                      data-for={`tracker-table-line-${imei}`}
                      data-tip={t(terms.statistics)}
                    >
                      <i className="fa fa-tachometer" />
                    </Link>
                  )}
                {hasPermission(user, Permission.Location) && (
                  <Link
                    to={"/" + portal + "/map/" + imei}
                    className="button"
                    data-for={`tracker-table-line-${imei}`}
                    data-tip={t(terms.mapView)}
                  >
                    <i className="fa fa-map-marker" />
                  </Link>
                )}
                {hasPermission(user, Permission.RouteList) &&
                  portal !== "person" && (
                    <Link
                      to={"/" + portal + "/fahrtenbuch/" + imei}
                      className="button"
                      data-for={`tracker-table-line-${imei}`}
                      data-tip={t(terms.fahrtenbuch)}
                    >
                      <i className="fa fa-book" />
                    </Link>
                  )}

                <Link
                  to={`/${portal}/device-list/settings/${imei}`}
                  className="button"
                  data-for={`tracker-table-line-${imei}`}
                  data-tip={t(terms.settings)}
                  onClick={event => {
                    event.stopPropagation();
                    props.updateSelectedDeviceImei(imei);
                  }}
                >
                  <i className="icon-cogs" />
                </Link>
              </div>
            </div>
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`tracker-table-line-${imei}`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }),
);
