import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

export class DeviceLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };

  render() {
    return (
      <div className="table-line grid-x">
        <div className="cell small-12 medium-6 large-3 first-cell">
          <div className="name"> {this.props.device.name}</div>
        </div>
        <div className="cell small-12 medium-12 large-auto">
          <div className="grey-text">IMEI: {this.props.device.imei}</div>
          <div className="button-frame tag-buttons">
            <div className="block">
              {this.props.device.tags &&
                this.props.device.tags.map(
                  tagId =>
                    this.props.tagList.tag_objects[tagId.toString()] && (
                      <span key={tagId} className="tag">
                        <i
                          className="fa fa-tag"
                          style={{
                            color: this.props.tagList.tag_objects[
                              tagId.toString()
                            ].tag_color,
                          }}
                        />
                        &nbsp;&nbsp;
                        {
                          this.props.tagList.tag_objects[tagId.toString()]
                            .tag_name
                        }{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    ),
                )}
            </div>
          </div>
        </div>
        <div className="cell small-12 medium-1 large-1 table-line-right fixed">
          <div className="button-container">
            <div
              className="button"
              onClick={() => {
                this.props.toggleDeviceAssignUserOverlay(this.props.device);
              }}
            >
              <i className="fa fa-user" />
              {this.props.device.users &&
                this.props.device.users.length != 0 && (
                  <span className="badge red">
                    {this.props.device.users.length}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
