import { postJson, putJson } from "./fetchService";

export const fetchMessages = jsonFetch => ({
  messageListQuery(imei, pagination) {
    const route = `/user/messagepagination/${imei}`;
    return jsonFetch(route, postJson(pagination));
  },
  markMessagesAsRead(imei, messageIds) {
    const route = `/user/messages/${imei}`;
    const postData = messageIds.map(message_id => ({
      message_id,
      read: true,
    }));
    return jsonFetch(route, putJson(postData));
  },
  deleteMessages(imei, messageIds) {
    const route = `/user/messages/${imei}`;
    const postData = messageIds.map(message_id => ({
      message_id,
      delete: true,
    }));
    return jsonFetch(route, putJson(postData));
  },
});
